// AboutUsPage.js

import React from "react";
import "./about.css"; // Import CSS file
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
const AboutUsPage = () => {
  return (
    <div>
      <Header />
      <div className="about-us-container">
        <div className="about-us-content">
          <h1>Welcome to Aswien Training</h1>
          <p>
            Aswien Training is more than just a platform - we're your dedicated
            partners in your journey towards success. Our team consists of
            passionate individuals committed to providing innovative solutions
            tailored to meet your needs. Our mission is simple yet powerful: to
            deliver excellence through creativity, collaboration, and unwavering
            commitment.
          </p>
          <h2>Our Vision</h2>
          <p>
            At Aswien Training, we envision ourselves as pioneers in the field
            of education and training. Our goal is to become the leading
            provider of cutting-edge technologies, offering unparalleled value
            to our clients while contributing to the advancement of society as a
            whole.
          </p>
          <h2>Our Values</h2>
          <ul>
            <li>Integrity</li>
            <li>Innovation</li>
            <li>Customer Focus</li>
            <li>Teamwork</li>
            <li>Continuous Improvement</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUsPage;
