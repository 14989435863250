export const quiz = {
  quizTitle: "Life in the UK Test",
  quizSynopsis:
    "Prepare for the official Life in the UK test by familiarizing yourself with its format. It comprises 24 questions, and to pass, you must answer at least 18 correctly.To commence the test, click the button below.",
  nrOfQuestions: "24",
  questions: [
    {
      question:
        "What TWO things do you need to apply for UK citizenship or permanent residency?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A UK bank account",
        "A driving licence",
        "A good understanding of life in the UK",
        "An ability to speak and read English",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "An ability to speak and read English, A good understanding of life in the UK",
      point: "1",
    },
    {
      question: "Which is a fundamental principle of British life?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Extremism", "Individual liberty", "Inequality", "Intolerance"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The fundamental principles of British life include:\n- Democracy\n- The rule of law\n- Individual liberty\n- Tolerance of those with different faiths and beliefs\n- Participation in community life.",
      point: "1",
    },
    {
      question: "Determine the correct statement from the given options.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The declaration of rights read at the coronation of William and Mary meant the monarch could no longer raise taxes or administer justice.",
        "The declaration of rights read at the coronation of William and Mary meant the monarch had absolute power to rule without the interference of Parliament.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "At the coronation of William and Mary, a Declaration of Rights was read. This confirmed that the king would no longer be able to raise taxes or administer justice without agreement from Parliament. The balance of power between monarch and Parliament had now permanently changed.",
      point: "1",
    },
    {
      question: "Which is the capital city of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Birmingham", " London", "Westminster", "Windsor"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The capital city of the UK is London.",
      point: "1",
    },
    {
      question: "What is the role of a jury at a court trial?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Dollar", "Euro", "Pound Sterling", " Yen"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The currency in the UK is the pound sterling (symbol GBP ).",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? John Constable (1776-1837) founded the modern police force in England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "John Constable (1776-1837) was a landscape painter most famous for his works of Dedham Vale on the Suffolk-Essex border in the east of England.",
      point: "1",
    },
    {
      question:
        "How often does Prime Minister's Questions occur during Parliament sessions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Every day", "Once a month", "Once a week", "Twice a week"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "One important opportunity to do this is at Prime Minister's Questions, which takes place every week while Parliament is sitting. ",
      point: "1",
    },
    {
      question: "Identify TWO famous British artists from the following list.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Andy Murray",
        "David Hockney",
        "Henry Moore",
        "Sir Edward Elgar",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "David Hockney (1937-) was an important contributor to the 'pop art' movement of the 1960s and continues to be influential today. ; Henry Moore (1898-1986) was an English sculptor and artist. He is best known for his large bronze abstract sculptures.",
      point: "1",
    },
    {
      question: "Gertrude Jekyll gained renown for her designs in which field?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" Fashion", "Gardening", "Pottery", "Silverware"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Gertrude Jekyll often worked with Edwin Lutyens to design colourful gardens around the houses he designed.",
      point: "1",
    },
    {
      question: "Choose the correct statement among the options provided.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You have to be aged 16 or over to buy a National Lottery ticket",
        "You have to be aged 18 or over to buy a National Lottery ticket",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is a National Lottery for which draws are made every week. You can enter by buying a ticket or a scratch card. People under 18 are not allowed to participate in the National Lottery. The minimum age to buy a lottery ticket was raised from 16 to 18 in 2021.",
      point: "1",
    },
    {
      question: "In which international organisation did the UK join in 1973?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "NATO",
        "The Commonwealth",
        "The EU",
        "The European Commission",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK joined the EU (or the EEC as it was known at the time) on 1 January 1973.",
      point: "1",
    },
    {
      question:
        "List TWO activities typically carried out by pressure and lobby groups.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Assist MP's in their constituency work",
        "Influence government policy",
        "Organise violent protests",
        "Represent the views of British businesses",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Pressure and lobby groups are organisations which try to influence government policy. They play an important role in politics. Some are representative organisations such as the CBI (Confederation of British Industry), which represents the views of British business.",
      point: "1",
    },
    {
      question: "Which statement is accurate from the following options?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Magistrates must be specially trained legal experts who have been solicitors for three years.",
        "Magistrates usually work unpaid and do not need legal qualifications",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Magistrates and Justices of the Peace (JPs) are members of the local community. In England, Wales and Scotland they usually work unpaid and do not need legal qualifications. ",
      point: "1",
    },
    {
      question: "Name TWO benefits of volunteering.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Earning additional money",
        "Making your community a better place",
        "Meeting new people",
        "You are given a courtesy car as transport",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many benefits to volunteering, such as meeting new people and helping make your community a better place. ",
      point: "1",
    },
    {
      question:
        " Is the statement  TRUE or FALSE? You can support your local community by becoming a school governor or school board member.\n",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "School governors, or members of the school board in Scotland, are people from the local community who wish to make a positive contribution to children's education. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The devolved Scottish government governs Scotland from Edinburgh.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " The Scottish Parliament was formed in 1999. It sits in Edinburgh, the capital city of Scotland. ",
      point: "1",
    },
    {
      question: "Select the accurate statement from the choices given.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Magistrates' Court deals with minor criminal offences.",
        "A Magistrates' Court deals with only the most serious criminal cases.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland, most minor criminal cases are dealt with in a Magistrates' Court.",
      point: "1",
    },
    {
      question: "Who is the designated heir to the throne?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All the above",
        "Prince William",
        "Prince Philip",
        "The Prince of Wales",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "She is married to Prince Philip, the Duke of Edinburgh. Her eldest son, Prince Charles (the Prince of Wales), is the heir to the throne. ",
      point: "1",
    },
    {
      question: "Which TWO are 20th-century British discoveries or inventions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Cash machines (ATMs)",
        "Cloning a mammal",
        "Mobile Phones",
        "Walkman's",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1996, two British scientists, Sir Ian Wilmot (1944-) and Keith Campbell (1954-2012), led a team which was the first to succeed in cloning a mammal, Dolly the sheep. In the 1960s, James Goodfellow (1937-) invented the cash-dispensing ATM (automatic teller machine) or 'cashpoint'. The first of these was put into use by Barclays Bank in Enfield, north London in 1967.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By 1400 the preferred language of English court was English",
        "By 1400 the preferred language of English court was French",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, in England, official documents were being written in English, and English had become the preferred language of the royal court and Parliament.",
      point: "1",
    },
    {
      question:
        "Which TWO countries did more than 2 million migrants come from after the abolition of slavery to replace freed slaves?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Australia", "China", "India", " Russia"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After 1833, 2 million Indian and Chinese workers were employed to replace the freed slaves.",
      point: "1",
    },
    {
      question: "Which is a British innovation?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The Internet",
        "The Turing machine",
        "The diesel engine",
        "The telephone",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A Turing machine is a theoretical mathematical device invented by Alan Turing (1912-54), a British mathematician, in the 1930s. The theory was influential in the development of computer science and the modern-day computer.",
      point: "1",
    },
    {
      question: "Dunkirk is linked to which TWO historical events?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Landings on D-Day",
        "Small boats coming to the rescue",
        "The fall of Singapore",
        "The rescue of 300,000 men",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many civilian volunteers in small pleasure and fishing boats from Britain helped the Navy to rescue more than 300,000 men from the beaches around Dunkirk. Although many lives and a lot of equipment were lost, the evacuation was a success and meant that Britain was better able to continue the fight against the Germans.",
      point: "1",
    },
    {
      question:
        "On his escape from the Battle of Worcester, Charles II famously hid inside what?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["A cellar", "A forest", "An oak tree", "None of the above"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles II escaped from Worcester, famously hiding in an oak tree on one occasion, and eventually fled to Europe. Parliament now controlled Scotland as well as England and Wales.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Thanks to its position as the world's leading industrial nation during the 1800s, Britain was responsible for producing half of the world's coal, iron and cotton cloth.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British industry led the world in the 19th century. The UK produced more than half of the world's iron, coal and cotton cloth.",
      point: "1",
    },
    {
      question: "Where did Mary Stuart spend most of her childhood?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "France", "Germany", "Spain"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Much of her childhood was spent in France. When she returned to Scotland, she was the centre of a power struggle between different groups.",
      point: "1",
    },
    {
      question:
        "What were the names of the TWO main groups in parliament in the early 18th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Spanish princess",
        "A fleet of Spanish ships",
        " A treaty with Spain",
        "The Spanish Army",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Elizabeth became one of the most popular monarchs in English history, particularly after 1588, when the English defeated the Spanish Armada (a large fleet of ships), which had been sent by Spain to conquer England and restore Catholicism.",
      point: "1",
    },
    {
      question: "Where can HMS Victory be visited?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Falmouth", "Poole", "Portsmouth", "Southampton"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Nelson's Column in Trafalgar Square, London, is a monument to him. His ship, HMS Victory, can be visited in Portsmouth.",
      point: "1",
    },
    {
      question:
        "Which TWO famous London buildings were constructed in the 19th-century 'gothic' style?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Buckingham Palace",
        "St Pancras Station",
        "St Paul's Cathedral",
        "The Houses of Parliament",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, the medieval 'gothic' style became popular again. As cities expanded, many great public buildings were built in this style. The Houses of Parliament and St Pancras Station were built at this time, as were the town halls in cities such as Manchester and Sheffield.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Mary Peters was an author who was later made a Dame in recognition of her work promoting literacy.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Born in Manchester, Mary Peters moved to Northern Ireland as a child. She was a talented athlete who won an Olympic gold medal in the pentathlon in 1972. After this, she raised money for local athletics and became the team manager for the women's British Olympic team. She continues to promote sport and tourism in Northern Ireland and was made a Dame of the British Empire in 2000 in recognition of her work.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following prestigious horse-racing events occur in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Royal Ascot",
        "Scottish Grand National",
        "Six Nations Championship",
        "The Open Championship",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Famous horse-racing events include: Royal Ascot, a five-day race meeting in Berkshire attended by members of the Royal Family; the Grand National at Aintree near Liverpool; and the Scottish Grand National at Ayr.",
      point: "1",
    },
    {
      question: "What celebration takes place annually on February 14th?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Brit Awards",
        "The Laurence Olivier Awards",
        "The Man Booker Prize",
        "The Tuner Prize",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Laurence Olivier Awards take place annually at different venues in London. There are a variety of categories, including best director, best actor and best actress.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?William Blake, Lord Byron and Robert Browning were all famous golfers",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Poetry was very popular in the 19th century, with poets such as William Blake, John Keats, Lord Byron, Percy Shelley, Alfred Lord Tennyson, and Robert and Elizabeth Browning.",
      point: "1",
    },
    {
      question: "TRUE or FALSE? The daffodil is the national flower of Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The countries that make up the UK all have flowers which are particularly associated with them and which are sometimes worn on national saints days: -England - the rose ;Scotland - the thistle ;Wales - the daffodil ;Northern Ireland - the shamrock",
      point: "1",
    },
    {
      question:
        "Is discrimination based on sexual orientation acceptable in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No, it is never acceptable to treat people worse for their sexual orientation",
        "Yes, if their sexual orientation is forbidden by religion",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Although Britain is one of the world's most diverse societies, there is a set of shared values and responsibilities that everyone can agree with. These values and responsibilities include:- to treat everyone equally, regardless of sex, race, religion, age, disability, class or sexual orientation",
      point: "1",
    },
    {
      question:
        "In 1999, what occurred to hereditary peers in the House of Lords?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Their numbers were greatly increased.",
        "Their salaries were stopped",
        "They lost their automatic right to attend the House of Lords",
        "Women were allowed to inherit their titles",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Since 1999, hereditary peers have lost the automatic right to attend the House of Lords. ",
      point: "1",
    },
    {
      question:
        "What happens when a Member of Parliament (MP) dies or resigns?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A by-election is held to replace the MP",
        "A neighbouring MP looks after the constituency",
        "The post remains vacant until the next General Election.",
        "Their party chooses someone to fill the post until the next General Election.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " If an MP dies or resigns, there will be a fresh election, called a by-election, in his or her constituency. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In Northern Ireland a member of your family must complete a voting registration form on your behalf.",
        "In Northern Ireland all those entitled to vote must complete their own registration form.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Northern Ireland a different system operates. This is called 'individual registration' and all those entitled to vote must complete their own registration form. ",
      point: "1",
    },
    {
      question:
        "Which TWO among the following are shared goals of the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Democracy", "Dependency", "Development", "Discrimination"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development.",
      point: "1",
    },
    {
      question: "Are police obligated to adhere to the law?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Yes, always",
        "Yes, except when investigating a difficult case",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The police force is a public service that helps and protects everyone, no matter what their background or where they live. Police officers must themselves obey the law. They must not misuse their authority, make a FALSE? statement, be rude or abusive, or commit racial discrimination. If police officers are corrupt or misuse their authority they are severely punished.",
      point: "1",
    },
    {
      question: "Which is not the job of the police?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To keep the peace",
        "To prevent and detect crime",
        "To prosecute someone for debt",
        " To protect life and property",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The job of the police in the UK is to:-protect life and property ;-prevent disturbances (also known as keeping the peace) ;-prevent and detect crime.",
      point: "1",
    },
    {
      question: "What were women campaigning for the right to vote known as?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Disagreement over Ireland becoming one country",
        "Independence for Scotland",
        "Independence for Wales",
        " Setting up an English parliament",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The conflict between those wishing for full Irish independence and those wishing to remain loyal to the British government is often referred to as 'the Troubles'.",
      point: "1",
    },
    {
      question:
        "Which TWO political parties formed the coalition government in 2010?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Communists", "Conservatives", "Labour", "Liberal Democrats"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Conservative and Liberal Democrat parties formed a coalition and the leader of the Conservative Party, David Cameron, became Prime Minister.",
      point: "1",
    },
    {
      question:
        "In 1348, a third of the populations of England, Wales, and Scotland perished due to which plague?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Black Death",
        "The Blue Death",
        "The Green Death",
        "The White Death",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1348, a disease, probably a form of plague, came to Britain. This was known as the Black Death. One third of the population of England died and a similar proportion in Scotland and Wales.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The jet engine and radar were developed in Britain in the 1830s.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer. Radar was developed by Scotsman Sir Robert Watson-Watt (1892-1973), who proposed that enemy aircraft could be detected by radio waves. The first successful radar test took place in 1935.",
      point: "1",
    },
    {
      question:
        "Which TWO changes were implemented by the Education Act of 1944?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A clear division between primary and secondary education",
        "Free secondary education for all",
        "New public examinations",
        "Primary education for all",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In this role, he oversaw the introduction of the Education Act 1944(often called 'The Butler Act'), which introduced free secondary education in England and Wales. The education system has changed significantly since the Act was introduced, but the division between primary and secondary schools that it enforced still remains in most areas of Britain.",
      point: "1",
    },
    {
      question: "What was the last successful foreign invasion of England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Normans led by William",
        "The Romans led by Emperor Claudius",
        "The Romans led by Julius Caesar",
        "The Vikings",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Norman Conquest was the last successful foreign invasion of England and led to many changes in government and social structures in England. Norman French, the language of the new ruling class, influenced the development of the English language as we know it today.",
      point: "1",
    },
    {
      question: "Which TWO are associated with Sir Francis Drake?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        " Early flight",
        "Sailing around the world",
        "The Spanish Armada",
        "The Titanic",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Francis Drake, one of the commanders in the defeat of the Spanish Armada, was one of the founders of England's naval tradition. His ship, the Golden Hind, was one of the first to sail right around ('circumnavigate') the world.",
      point: "1",
    },
    {
      question: "Who invaded England in 1066?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Canute",
        " Harold of Wessex",
        "Richard the Lionheart",
        "William of Normandy",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings.",
      point: "1",
    },
    {
      question:
        "Which colonies of the British Empire declared independence in 1776?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["American", "Australian", "Canadian", "South African"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments.",
      point: "1",
    },
    {
      question: "Which TWO are 20th-century British discoveries or inventions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Diesel engine",
        "Mobile phone",
        "Television",
        "World Wide Web",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The television was developed by Scotsman John Logie Baird (1888-1946) in the 1920s.; The inventor of the World Wide Web, Sir Tim Berners-Lee (1955-), is British.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? St. Columba was appointed the first Archbishop of Canterbury.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "St Augustine became the first Archbishop of Canterbury",
      point: "1",
    },
    {
      question:
        "Who attempted to govern without Parliament due to disagreements over religious and foreign policies?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bloody Mary", "Charles I", "Elizabeth I", "Henry VIII"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When Charles I inherited the thrones of England, Wales, Ireland and Scotland, he tried to rule in line with this principle. When he could not get Parliament to agree with his religious and foreign policies, he tried to rule without Parliament at all.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Charles II was crowned king of England, Wales, Scotland, and Ireland during the Restoration following the English Civil War.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland.",
      point: "1",
    },
    {
      question:
        "Which Northern Irish portrait artist, who died in 1941, painted portraits of the Royal Family?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Basil Blackshaw",
        "Markey Robinson",
        "Paul Henry",
        "Sir John Lavery",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir John Lavery (1856-1941) was a very successful Northern Irish portrait painter. His work included painting the Royal Family.",
      point: "1",
    },
    {
      question: "Who are the names of the TWO famous British film actors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Colin Firth",
        "Jayne Torville",
        "Robert Louis Stevenson",
        "Tilda Swinton",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Recent British actors to have won Oscars include Colin Firth, Sir Antony Hopkins, Dame Judi Dench, Kate Winslet and Tilde Swinton.",
      point: "1",
    },
    {
      question: "Which UK landmark is in Northern Ireland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Big Ben",
        "Snowdonia",
        "The Eden Project",
        "The Giants Causeway",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Located on the north-east coast of Northern Ireland, the Giant's Causeway is a land formation of columns made from volcanic lava. It was formed about 50 million years ago. There are many legends about the Causeway and how it was formed.",
      point: "1",
    },
    {
      question: "When is Boxing Day observed?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "1st May",
        "The day after Christmas Day",
        " The day after Easter",
        "The last Monday in August",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Boxing Day is the day after Christmas Day and is a public holiday.",
      point: "1",
    },
    {
      question: "What is the capital city of Wales?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Birmingham", "Liverpool", "London", "Sheffield"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The capital city of the UK is London",
      point: "1",
    },
    {
      question:
        "What important change to our voting rights took place in 1969?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Compulsory voting was introduced",
        "Prisoners were given the vote",
        "The voting age was reduced to 18 for men and women",
        "Women over 35 were given the vote",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1969, the voting age was reduced to 18 for men and women.",
      point: "1",
    },
    {
      question: "Which TWO among the following are examples of criminal law?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Discrimination in the workplace",
        "Disputes about faulty goods",
        "Racial crime",
        "Selling tobacco to anyone under the age of 18",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Examples of criminal laws are:-Racial crime: it is a criminal offence to cause harassment, alarm or distress to someone because of their religion or ethnic origin. ;-Selling tobacco: it is illegal to sell tobacco products (for example, cigarettes, cigars, roll-up tobacco) to anyone under the age of 18.",
      point: "1",
    },
    {
      question: "Where is the National Assembly for Wales based?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cardiff", "Glasgow", "London", "Newport"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Welsh government and National Assembly for Wales are based in Cardiff, the capital city of Wales. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Police and Crime Commissioners (PCCs) are appointed by the local council.",
        "Police and Crime Commissioners (PCCs) are appointed through a public election.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In November 2012, the public elected Police and Crime Commissioners (PCCs) in England and Wales. ",
      point: "1",
    },
    {
      question:
        "What information must always be entered on an electoral registration form?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Only the names of adult men residents at the address",
        "Only the names of adult women residents at the address",
        "The names of all eligible voters who live at the address",
        "The names of everyone at the address",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The electoral register is updated every year in September or October. An electoral registration form is sent to every household and this has to be completed and returned with the names of everyone who is resident in the household and eligible to vote",
      point: "1",
    },
    {
      question:
        " As well as giving legal advice, solicitors are able to do which TWO of the following?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Arrest suspects",
        "Change the law",
        "Represent clients in court",
        "Take action for a client",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Solicitors are trained lawyers who give advice on legal matters, take action for their clients and represent their clients in court.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Commonwealth is a group of countries that support each other and work together.",
        "The Commonwealth is a group of religions that together make up a federal republic.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development. ",
      point: "1",
    },
    {
      question: "Which TWO are plays penned by William Shakespeare?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A Midsummer Night's Dream",
        "Oliver Twist",
        "Pride and Prejudice",
        "Romeo and Juliet",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "His most famous plays include A Midsummer Night's Dream, Hamlet, Macbeth and Romeo and Juliet.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sake Dean Mahomet introduced curry houses and shampooing to Britain from India.",
        "Sake Dean Mahomet is famous for introducing tea-drinking and bungalows to Britain from India.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1810 he opened the Hindoostanee Coffee House in George Street, London. It was the first curry house to open in Britain. Mahomet and his wife also introduced 'shampooing', the Indian art of head massage, to Britain.",
      point: "1",
    },
    {
      question:
        'TRUE or FALSE? "We shall fight them on the beaches" is a renowned quote from Queen Elizabeth I regarding the Spanish Armada.',
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "W e shall fight on the beaches, we shall fight on the landing grounds, we shall fight in the fields and in the streets, we shall fight in the hills; we shall never surrender' Speech to the House of Commons after Dunkirk 1940",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Civil War between Charles I and Parliament in the mid-17th century led to Oliver Cromwell becoming king of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England declared itself a republic, called the Commonwealth. It no longer had a monarch. For a time, it was not totally clear how the country would be governed. For now, the army was in control.",
      point: "1",
    },
    {
      question:
        "Which part of the UK is closely associated with Robert Burns (1759-1796)?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Known in Scotland as 'The Bard', Robert Burns was a Scottish poet.",
      point: "1",
    },
    {
      question: " Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The jet engine and radar were developed in Britain in the 1930's",
        "The jet engine and radar were developed in Britain in the 1950's.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Radar was developed by Scotsman Sir Robert Watson-Watt (1892-1973), who proposed that enemy aircraft could be detected by radio waves. The first successful radar test took place in 1935. The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "11 November commemorates soldiers who died in World War One as well as those who have died in all conflicts involving the UK since then.",
        "11 November only commemorates soldiers who died in World War One.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Remembrance Day, 11 November, commemorates those who died fighting for the UK and its allies. Originally it commemorated the dead of the First World War, which ended on 11 November 1918.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Big Ben is the nickname of the great bell in the clock tower of the Houses of Parliament.",
        "Big ben is a popular children's television character.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the great bell of the clock at the Houses of Parliament in London. Many people call the clock Big Ben as well.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The BBC is funded through advertisements and subscriptions",
        "The BBC is the only wholly state-funded media organisation, independent of government",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The BBC is the largest broadcaster in the world. It is the only wholly state-funded media organisation that is independent of government.",
      point: "1",
    },
    {
      question:
        "William Shakespeare authored several sonnets. What is a sonnet?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A mystery play",
        "A novel",
        "A poem of fourteen lines with a particular rhyme scheme",
        "A story aimed at young children",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Shakespeare wrote many sonnets (poems which must be 14 lines long) and some longer poems.",
      point: "1",
    },
    {
      question:
        "What is the common name for Yeoman Warders at the Tower of London?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cardiff", "Newport", "Plymouth", "Swansea"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Wales: The capital city of Wales is Cardiff",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Dame Judi Dench, Colin Firth, and Sir Anthony Hopkins have all earned Oscars.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Recent British actors to have won Oscars include Colin Firth, Sir Antony Hopkins, Dame Judi Dench, Kate Winslet and Tilde Swinton.",
      point: "1",
    },
    {
      question:
        " Near which of these cities is Europe's longest dry ski slope?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Aberdeen", "Edinburgh", "Inverness", "Pitlochrie"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Skiing is increasingly popular in the UK. Many people go abroad to ski and there are also dry ski slopes throughout the UK. Skiing on snow may also be possible during the winter. There are five ski centres in Scotland, as well as Europe's longest dry ski slope near Edinburgh.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Council of Europe possesses legislative powers binding in member states.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Council of Europe is separate from the EU. It has 47 member countries, including the UK, and is responsible for the protection and promotion of human rights in those countries. It has no power to make laws but draws up conventions and charters, the most well-known of which is the European Convention on Human Rights and Fundamental Freedoms, usually called the European Convention on Human Rights.",
      point: "1",
    },
    {
      question: "Which is an aim of the United Nations?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " To create a single free trade market",
        "To examine decisions made by the European Union",
        "To prevent war and promote international peace and security",
        "To promote dictatorship",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UN was set up after the Second World War and aims to prevent war and promote international peace and security. ",
      point: "1",
    },
    {
      question: "Which TWO are political parties in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Conservatives party",
        "Labour party",
        "Modern party",
        "Office party",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Anyone aged 18 or over can stand for election as an MP but they are unlikely to win unless they have been nominated to represent one of the major political parties. These are the Conservative Party, the Labour Party, the Liberal Democrats, or one of the parties representing Scottish, Welsh or Northern Irish interests. ",
      point: "1",
    },
    {
      question: "Which TWO new national bodies were established in 1999?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "English Parliament",
        "Houses of Lords",
        "Scottish Parliament",
        "Welsh Assembly",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There has been a Welsh Assembly and a Scottish Parliament since 1999. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Members of the armed forces are ineligible to run for public office.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most citizens of the UK, the Irish Republic or the Commonwealth aged 18 or over can stand for public office. There are some exceptions, including:-civil servants ;-people found guilty of certain criminal offences.",
      point: "1",
    },
    {
      question:
        "What is the minimum age you can drive a car or motorcycle in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "22", "30"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "As well as getting the right to vote, people on the electoral register are randomly selected to serve on a jury. Anyone who is on the electoral register and is aged 18 to 70 can be asked to do this. ",
      point: "1",
    },
    {
      question: "Who serves as the ceremonial head of the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The President of the USA",
        "The Prime Minister",
        "The Prince of Wales",
        "The King",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen is the ceremonial head of the Commonwealth, which currently has 54 member states.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The small claims procedure is an informal way of helping people settle minor disputes without needing a lawyer.",
        "The small claims procedure is an informal way of helping people who have been victims of identity theft or fraud.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In Scotland, serious offences are tried in a Crown Court",
        "In Scotland, serious offences are tried in a Sheriff Court",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, serious cases are heard in a Sheriff Court with either a sheriff or a sheriff with a jury. ",
      point: "1",
    },
    {
      question: "What is New Year's Eve called in Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The rule of law",
        "The rule of the monarch",
        "The rule of the upper classes",
        "The rule of your local member of the Parliament (MP)",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The fundamental principles of British life include:\n- Democracy\n- The rule of law\n- Individual liberty\n- Tolerance of those with different faiths and beliefs\n- Participation in community life.",
      point: "1",
    },
    {
      question:
        "What was Isambard Kingdom Brunel famous for designing and building?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["All-Party", "Coalition", "National", "One-Party"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 2010, and for the first time in the UK since February 1974, no political party won an overall majority in the General Election. The Conservative and Liberal Democrat parties formed a coalition and the leader of the Conservative Party, David Cameron, became Prime Minister. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "American colonists were eventually defeated by the British.",
        "In 1776 some American colonies declared their independence from Britain.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments.",
      point: "1",
    },
    {
      question:
        " Is the statement TRUE or FALSE?Britain has never been at war with France.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the 18th century, Britain fought a number of wars with France.",
      point: "1",
    },
    {
      question:
        "What was the main source of employment in the UK before the Industrial Revolution?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Aeroplanes", "Bridges", "Motor cars", "Skyscrapers"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He was an engineer who built tunnels, bridges, railway lines and ships.",
      point: "1",
    },
    {
      question:
        "Who did Henry VIII marry subsequent to Anne Boleyn's execution?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Anne of Cleves",
        "Catherine Howard",
        " Catherine Parr",
        "Jane Seymour",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Seymour - Henry married Jane after Anne's execution. She gave Henry the son he wanted, Edward, but she died shortly after the birth.",
      point: "1",
    },
    {
      question:
        "Which TWO events transpired concerning the Church in England following Charles II's restoration?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The Church of England was restored as the official Church",
        "The Church of England was suppressed",
        "The Puritans and Roman Catholics were kept out of power",
        "The Puritans grew in strength and number",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles II made it clear that he had 'no wish to go on his travels again'. He understood that he could not always do as he wished but would sometimes need to reach agreement with Parliament. Generally, Parliament supported his policies. The Church of England again became the established official Church. Both Roman Catholics and Puritans were kept out of power.",
      point: "1",
    },
    {
      question:
        "Which king annexed Wales to the Crown of England with the Statute of Rhuddlan?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Charles I", "Edward I", "Harold", "Henry VIII"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1284 King Edward I of England introduced the Statute of Rhuddlan, which annexed Wales to the Crown of England.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? ISAF is aiding in building up the Afghan National Security Forces and fostering a secure environment for governance and development.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "ISAF is building up the Afghan National Security Forces and is helping to create a secure environment in which governance and development can be extended.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Briton Sir Tim Berners-Lee invented the World Wide Web, utilized for the first time on December 25, 1990.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The inventor of the World Wide Web, Sir Tin Berners-Lee (1955-), is British. Information was successfully transferred via the web for the first time on 25 December 1990.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Isaac Newton was a renowned 18th-century musician.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "His most famous published work was Philosophiae Naturalis Principia Mathematica (''Mathematical Principles of Natural Philosophy'), which showed how gravity applied to the whole universe.",
      point: "1",
    },
    {
      question:
        "Which artist is credited with raising the profile of landscape painting?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "David Allan",
        "John Constable",
        "Joseph Turner",
        "Thomas Gainsborough",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Joseph Turner (1775-1851) was an influential landscape painter in a modern style. He is considered the artist who raised the profile of landscape painting.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A dog's owner is not responsible for keeping the animal under control and cleaning up after it in public places.",
        "A dog's owner is responsible for keeping the animal under control and cleaning up after it in public places.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A lot of people in the UK have pets such as cats or dogs. They might have them for company or because they enjoy looking after them. It is against the law to treat a pet cruelly or to neglect it. All dogs in public places must wear a collar showing the name and address of the owner. The owner is responsible for keeping the dog under control and for cleaning up after the animal in a public place.",
      point: "1",
    },
    {
      question: " What is it traditional to do on 1 April in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Hunt for eggs",
        "Make pancakes",
        "Play jokes on each other",
        "Play musical instruments in the street",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "April Fool's Day, 1 April, is a day when people play jokes on each other until midday. The television and newspapers often have stories that are April Fool jokes.",
      point: "1",
    },
    {
      question: "Which is a famous British film?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Passport to Paddington",
        "Passport to Panama",
        "Passport to Pimlico",
        "Passport to Portsmouth",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The 1950s and 1960s were a high point for British comedies, including Passport to Pimlico, The Ladykillers and, later, the Carry On films.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Some people rent land called 'an allotment' where they grow fruit and vegetables.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Some people rent additional land called 'an allotment', where they grow fruit and vegetables.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first professional UK football clubs were formed in 1066",
        "The first professional UK football clubs were formed in the late 19th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first professional UK football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Adult citizens of other EU states may vote in General Elections.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Adult citizens of the UK, and citizens of the Commonwealth and the Irish Republic who are resident in the UK, can vote in all public elections. Adult citizens of other EU states who are resident in the UK can vote in all elections except General Elections",
      point: "1",
    },
    {
      question:
        "Which TWO of the following are safeguarded by law against discrimination?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Choice of car", "Disability", "Eye colour", "Marital status"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "UK laws ensure that people are not treated unfairly in any area of life or work because of their age, disability, sex, pregnancy and maternity, race, religion or belief, sexuality or marital status. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Life peers in the House of Lords can transmit their title to their eldest child.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They have usually had an important career in politics, business, law or another profession. Life peers are appointed by the monarch on the advice of the Prime Minister. They also include people nominated by the leaders of the other main political parties or by an independent Appointments Commission for non-party peers.",
      point: "1",
    },
    {
      question:
        "What event in 1851 took place at the Crystal Palace in Hyde Park and showed goods and exhibits from Britain and across the world?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Catholic beliefs and values",
        "Protestant beliefs and values",
        " The right to be tried for crimes in your country of origin",
        "Traditions of the UK",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British society is founded on fundamental values and principles which all those living in the UK should respect and support. These values are reflected in the responsibilities, rights and privileges of being a British citizen or permanent resident of the UK. They are based on history and traditions and are protected by law, customs and expectations.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? In the UK, respecting others' rights to hold differing opinions is expected.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you wish to be a permanent resident or citizen of the UK, you should:respect and obey the law,respect the rights of others, including their right to their own opinions,treat others with fairness,look after yourself and your family,look after the area in which you live and the environment.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Wales united with England during Henry VIII's reign.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the reign of Henry VIII, Wales became formally united with England by the Act for the Government of Wales. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Admiral Nelson triumphed over the German fleet at the Battle of Trafalgar in 1805.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain's navy fought against combined French and Spanish fleets, winning the Battle of Trafalgar in 1805.",
      point: "1",
    },
    {
      question: "Which TWO nations fought against Napoleon?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Admiral Nelson",
        "Margaret Thatcher",
        "The Duke of Wellington",
        "Winston Churchill",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1815, the French Wars ended with the defeat of the Emperor Napoleon by the Duke of Wellington at the Battle of Waterloo. Wellington was known as the Iron Duke and later became Prime Minister. Napoleon, who became Emperor of France, continued the war. Britain's navy fought against combined French and Spanish fleets, winning the Battle of Trafalgar in 1805. Admiral Nelson was in charge of the British fleet at Trafalgar and was killed in the battle.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the 18th century two political groups emerged, the Conservatives and the Liberals.",
        "In the 18th century two political groups emerged, the Whigs and the Tories.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 18th century two political groups emerged, the Whigs and the Tories.",
      point: "1",
    },
    {
      question:
        "Which form of religion developed as a result of the Reformation?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Catholicism", "Hinduism", "Methodism", "Protestantism"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Protestant ideas gradually gained strength in England, Wales and Scotland during the 16th century.",
      point: "1",
    },
    {
      question: "Who were the Huguenots?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Dutch Protestants",
        "French Catholics",
        "French Protestants",
        "Roman Catholics",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This was a time when many people left Britain and Ireland to settle in new colonies in America and elsewhere, but others came to live in Britain. The first Jews to come to Britain since the Middle Ages settled in London in 1656. Between 1680 and 1720 many refugees called Huguenots came from France. They were Protestants and had been persecuted for their religion.",
      point: "1",
    },
    {
      question: "When did Queen Victoria's reign end?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["55 BC", "65 BC", "AD 55", "AD 75"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Julius Caesar led a Roman invasion of Britain in 55 BC.",
      point: "1",
    },
    {
      question:
        "In the 19th century, Britain and the East India Company gained control over significant parts of which TWO countries?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Canada", "India", "Saudi Arabia", "The United States"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain gained control over Canada, and the East India Company, originally set up to trade, gained control of large parts of India. Colonies began to be established in southern Africa.",
      point: "1",
    },
    {
      question:
        "People usually spend Christmas Day at home and enjoy a special meal that normally includes Christmas pudding, mince pies and which meat?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Duck", "Guinea fowl", "Pork", "Turkey"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Christmas is celebrated in a traditional way. People usually spend the day at home and eat a special meal, which often includes roast turkey, Christmas pudding and mince pies.",
      point: "1",
    },
    {
      question: "Which TWO events are religious festivals?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Bonfire Night", "Boxing Day", "Christmas Day", "Easter"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Christmas Day, 25 December, celebrates the birth of Jesus Christ. It is a public holiday. Many Christians go to church on Christmas Eve (24 December) or on Christmas Day itself. ; Easter takes place in March or April. It marks the death of Jesus Christ on Good Friday and his rising from the dead on Easter Sunday. Both Good Friday and the following Monday, called Easter Monday, are public holidays.",
      point: "1",
    },
    {
      question: "Which of the following are major outdoor music festivals?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Glastonbury",
        "Hogmanay",
        "Isle of Wight Festival",
        "Royal Ascot",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Famous festivals include Glastonbury, the Isle of Wight Festival and the V Festival. Many bands and solo artists, both well-known and up-and-coming, perform at these events.",
      point: "1",
    },
    {
      question: "Which TWO family members have dedicated special days?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Aunts", "Fathers", "Mother", "Uncles"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mothering Sunday (or Mother's Day) is the Sunday three weeks before Easter. Children send cards or buy gifts for their mothers. ; Father's Day is the third Sunday in June. Children send cards or buy gifts for their fathers.",
      point: "1",
    },
    {
      question: "What is the capital city of Northern Ireland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Conducting orchestras",
        "Directing films",
        "Singing as an operatic",
        "Theatre production",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British studios flourished in the 1930s. Eminent directors included Sir Alexander Korda and Sir Alfred Hitchcock, who later left for Hollywood and remained an important film director until his death in 1980. During the Second World War, British movies (for example, In Which We Serve) played an important part in boosting morale. Later, British directors including Sir David Lean and Ridley Scott found great success both in the UK and internationally.",
      point: "1",
    },
    {
      question: "The Elizabeth Tower is part of which building in London?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Buckingham Palace",
        "Houses of parliament",
        "Mansion House",
        "St Pauls Cathedral",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the great bell of the clock at the Houses of Parliament in London. Many people call the clock Big Ben as well. The clock is over 150 years old and is a popular tourist attraction. The clock tower is named 'Elizabeth Tower' in honour of Queen Elizabeth II's Diamond Jubilee in 2012.",
      point: "1",
    },
    {
      question:
        "Can parents or other community groups establish a free school?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" Yes, but only in England", "Yes, in any part of the UK"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, parents and other community groups can apply to open a free school in their local area. More information about this can be found on the Department for Education website at www.dfe.gov.uk",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In a Crown Court case the judge decides the sentence when someone is found guilty.",
        "In a Crown Court case the jury decides the sentence when someone is found guilty.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If the jury finds a defendant guilty, the judge decides on the penalty. ",
      point: "1",
    },
    {
      question: "Which court would you use to reclaim owed money?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Coroner's Court",
        "Country Court",
        "Magistrates' Court",
        "Youth court",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "County Courts deal with a wide range of civil disputes. These include people trying to get back money that is owed to them, cases involving personal injury, family matters, breaches of contract, and divorce. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Refuges and shelters offer a place to stay for victims of domestic violence.",
        " The Citizens Advice Bureau offers a safe place to stay for victims' domestic violence.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It is important for anyone facing domestic violence to get help as soon as possible. A solicitor or the Citizens Advice Bureau can explain the available options. In some areas there are safe places to go and stay in, called refuges or shelters. There are emergency telephone numbers in the helpline section at the front of Yellow Pages, including, for women, the number of the nearest women's centre. You can also phone the 24-hour National Domestic Violence Freephone Helpline on 0808 2000 247 at any time, or the police can help you find a safe place to stay.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The cabinet's decisions must always be debated or approved by Parliament.",
        "The cabinet's decisions often have to be debated or approved by Parliament.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "These ministers form the cabinet, a committee which usually meets weekly and makes important decisions about government policy. Many of these decisions have to be debated or approved by Parliament. ",
      point: "1",
    },
    {
      question: "What countries does 'Great Britain' refer to?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "England and Scotland",
        " England, Scotland and Northern Ireland",
        "England, Scotland and Wales",
        "Just England",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Great Britain' refers only to England, Scotland and Wales, not to Northern Ireland.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? British scientists achieved the first successful mammal cloning. The subject was a rabbit.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " In 1996, two British scientists, Sir Ian Wilmot (1944-) and Keith Campbell (1954-2012), led a team which was the first to succeed in cloning a mammal, Dolly the sheep.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Richard Arkwright developed efficient and profitable factories.",
        "Richard Arkwright developed new farming methods in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question:
        "Emmeline Pankhurst is celebrated for leading the campaign for women's suffrage in UK parliamentary elections.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "She set up the Women's Franchise League in 1889, which fought to get the vote in local elections for married women.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Battle of Britain in 1940 was fought at sea.",
        "The Battle of Britain in 1940 was fought in the skies.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Germans waged an air campaign against Britain, but the British resisted with their fighter planes and eventually won the crucial aerial battle against the Germans, called 'the Battle of Britain', in the summer of 1940.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? In 1921, a treaty granted independence to the south of Ireland.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1921 a peace treaty was signed and in 1922 Ireland became two countries.",
      point: "1",
    },
    {
      question:
        " In which part of the British Empire did Boer War of 1899-1902 take place?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Australia", "Canada", "India", "South Africa"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Boer War of 1899 to 1902 made the discussions about the future of the Empire more urgent. The British went to war in South Africa with settlers from the Netherlands called the Boers.",
      point: "1",
    },
    {
      question:
        "Which is an accurate estimate of the number of casualties suffered by Britain in World War I?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["10 million", "2 million", " 500,000", "8 million"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Men from the West Indies, Africa, Australia, New Zealand and Canada also fought with the British. The Allies fought against the Central Powers - mainly Germany, the Austro-Hungarian Empire, the Ottoman Empire and later Bulgaria. Millions of people were killed or wounded, with more than 2 million British casualties.",
      point: "1",
    },
    {
      question: "Which of these people was a great British playwright?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Geoffrey Chaucer",
        "Sir Francis Drake",
        "William Caxton",
        "William Shakespeare",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Shakespeare was born in Stratford-upon-Avon, England. He was a playwright and actor and wrote many poems and plays. His most famous plays include A Midsummer Night's Dream, Hamlet, Macbeth and Romeo and Juliet.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The House of Lancaster symbolized by a red rose, and the House of York by a white rose.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This war was called the Wars of the Roses, because the symbol of Lancaster was a red rose and the symbol of York was a white rose.",
      point: "1",
    },
    {
      question: "Who was James II's eldest daughter, Mary, married to?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "She never married",
        "The Duke of York",
        "The French Dauphin",
        "William of Orange",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James II's elder daughter, Mary, was married to her cousin William of Orange, the Protestant ruler of the Netherlands.",
      point: "1",
    },
    {
      question:
        "When did the development of Parliament as we know it today begin?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Bronze Age",
        "The Iron Age",
        "The Middle Ages",
        "The Stone Age",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the Middle Ages, Parliament began to develop into the institution it is today.",
      point: "1",
    },
    {
      question:
        "Which of these countries was part of the British Empire during Victoria's reign?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["France", "India", "Switzerland", "USA"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the Victorian period, the British Empire grew to cover all of India, Australia and large parts of Africa. It became the largest empire the world has ever seen, with an estimated population of more than 400 million people.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The threat of Viking raids led to the unification of the people in the north, and the term Scotland was used to denote the country.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the north, the threat of attack by Vikings had encouraged the people to unite under one king, Kenneth MacAlpin. The term Scotland began to be used to describe that country.",
      point: "1",
    },
    {
      question: "What were 'the Troubles' about?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Royal Charter",
        "A Series of poems",
        "A book of Christian doctrine",
        "A story about Canterbury Cathedral",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The people decided to tell each other stories on the journey, and the poems describe the travellers and some of the stories they told. This collection of poems is called The Canterbury Tales.",
      point: "1",
    },
    {
      question: "Which TWO are influential British bands?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The Beatles",
        "The Rolling Stones",
        "The Royal Family",
        "The national Trust",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Two well-known pop music groups at the time were The Beatles and The Rolling Stones.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Variations in language exist across different regions of the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many variations in language in the different parts of the UK.",
      point: "1",
    },
    {
      question: "What age must you be to enter a betting shop casino?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["15", "16", "17", "18"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You have to be 18 to go into betting shops or gambling clubs.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The small claims procedure offers an informal means to resolve minor disputes.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer.",
      point: "1",
    },
    {
      question: " How often are elections for the European Parliament held?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Annually",
        "Every 10 years",
        "Every five years",
        "Every two years",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elections for the European Parliament are also held every five years. ",
      point: "1",
    },
    {
      question:
        "How frequently is a Ministry of Transport (MOT) test required for a car older than three years?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Every 10 years",
        "Every six months",
        "Every three years",
        "Every year",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If your vehicle is over three years old, you must take it for a Ministry of Transport (MOT) test every year. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Members of the House of Lords are elected by a constituency.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members of the House of Lords, known as peers, are not elected by the people and do not represent a constituency. The role and membership of the House of Lords has changed over the last 50 years.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? National Citizen Service offers military training to young individuals.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many opportunities for younger people to volunteer and receive accreditation which will help them to develop their skills. These include the National Citizen Service programme, which gives 16- and 17-year-olds the opportunity to enjoy outdoor activities, develop their skills and take part in a community project.",
      point: "1",
    },
    {
      question: "How is the electoral register kept updated?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Every household must request a registration form",
        "Every household receives a phone call to enquire about eligible voters",
        "Every household receives a registration form, which must be completed and returned",
        "How is the electoral register updated?",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "An electoral registration form is sent to every household and this has to be completed and returned with the names of everyone who is resident in the household and eligible to vote. ",
      point: "1",
    },
    {
      question:
        "Which TWO of the following freedoms are extended to citizens and permanent residents of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A right to take part in the election of a government",
        "Free heating for all",
        "Freedom of speech",
        "Half day off work on Friday",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In return, the UK offers: freedom of belief and religion,freedom of speech,freedom from unfair discrimination,a right to a fair trial,a right to join in the election of a government.",
      point: "1",
    },
    {
      question: "What is a fundamental principle of British life?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A relaxed work ethic",
        " Democracy",
        "Extremism",
        "Religious faith",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The fundamental principles of British life include:Democracy,The rule of law, Individual liberty,Tolerance of those with different faiths and beliefs,Participation in community life.",
      point: "1",
    },
    {
      question: "What were TWO important aspects of the Reform Act of 1832?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Labour", "Nationalists", "Tories", "Whigs"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were two main groups in Parliament, known as the Whigs and the Tories.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? George of Hanover, a German, ascended to the English throne following Queen Anne's demise.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When Queen Anne died in 1714, Parliament chose a German, George I, to be the next king, because he was Anne's nearest Protestant relative.",
      point: "1",
    },
    {
      question: "Which TWO wars involved England during the Middle Ages?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Crimean", "Crusades", "Hundred Years War", "Peninsular"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many knights took part in the Crusades, in which European Christians fought for control of the Holy Land. English kings also fought a long war with France, called the Hundred Years War (even though it actually lasted 116 years).",
      point: "1",
    },
    {
      question: "Where did the people of the Bronze Age bury their dead?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Burial mounds",
        "Earth barrows",
        "Round barrows",
        "Roundhouses",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Around 4,000 years ago, people learned to make bronze. We call this period the Bronze Age. People lived in roundhouses and buried their dead in tombs called round barrows.",
      point: "1",
    },
    {
      question:
        "During Henry VIII's reign, which country was formally united with England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Ireland", " Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the reign of Henry VIII, Wales became formally united with England by the Act for the Government of Wales. The Welsh sent representatives to the House of Commons and the Welsh legal system was reformed.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The MacDonald clan of Glencoe were massacred.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "All Scottish clans were required formally to accept William as king by taking an oath. The MacDonalds of Glencoe were late in taking the oath and were all killed.",
      point: "1",
    },
    {
      question:
        "Which sport is associated with Lewis Hamilton, Jensen Button, and Damon Hill?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Athletics", "Football", "Formula 1", "Skiing"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A Formula 1 Grand Prix event is held in the UK each year and a number of British Grand Prix drivers have won the Formula 1 World Championship. Recent British winners include Damon Hill, Lewis Hamilton and Jensen Button.",
      point: "1",
    },
    {
      question: "What is the legal age to purchase alcohol in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" 16", "18", "20", "21"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To buy alcohol in a pub or night club you must be 18 or over, but people under that age may be allowed in some pubs with an adult.",
      point: "1",
    },
    {
      question:
        "Diwali is a religious celebration observed by which TWO religious groups?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Hindus", "Jews", "Muslims", "Sikh"],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Diwali normally falls in October or November and lasts for five days. It is often called the Festival of Lights. It is celebrated by Hindus and Sikhs. It celebrates the victory of good over evil and the gaining of knowledge. There are different stories about how the festival came about. There is a famous celebration of Diwali in Leicester.",
      point: "1",
    },
    {
      question:
        "At the beginning of the 19th century, which group had the right to vote?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All adults over the age of 18",
        "All women who owned property",
        " Property-owning men over the age of 18",
        "Property-owning men over the age of 21",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They were men who were over 21 years of age and who owned a certain amount of property.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The police do not need to protect and help people who are not UK citizens.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The police force is a public service that helps and protects everyone, no matter what their background or where they live.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? A husband coercing his wife into sexual activity can be charged with rape.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Any man who forces a woman to have sex, including a woman's husband, can be charged with rape. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Main political parties actively recruit members.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The main political parties actively look for members of the public to join their debates, contribute to their costs, and help at elections for Parliament or for local government. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? All young people receive a National Insurance Number before turning 16.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "All young people in the UK are sent a National Insurance number just before their 16th birthday. ",
      point: "1",
    },
    {
      question: "Which TWO countries are part of the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Australia", "Canada", "Ethiopia", "US"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Australia and Canada are members of the Commonwealth. The Queen is the ceremonial head of the Commonwealth, which currently has 54 member states (see table).",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Wales, Scotland, and Northern Ireland each possess devolved administrations with complete authority over policies and legislation.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Policy and laws governing defence, foreign affairs, immigration, taxation and social security all remain under central UK government control.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The King is ceremonial head of the Commonwealth",
        "The Queen is ceremonial head of the North Atlantic Treaty Organization (NATO).",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The King is ceremonial head of the Commonwealth.",
      point: "1",
    },
    {
      question: "Who appoints life peers in the House of Lords?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Chief Whip",
        "The Prime Minister",
        "The Speaker of the House of Commons",
        "The monarch",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Life peers are appointed by the monarch on the advice of the Prime Minister. ",
      point: "1",
    },
    {
      question: "TRUE or FALSE? A General Election occurs every eight years.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected at a General Election, which is held at least every five years.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The Chancellor of the Exchequer is responsible for crime, policing and immigration.",
        "The Chancellor of the Exchequer is responsible for the economy.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister appoints about 20 senior MPs to become ministers in charge of departments. These include:Chancellor of the Exchequer - responsible for the economy ;Home Secretary - responsible for crime, policing and immigration ;Foreign Secretary - responsible for managing relationships with foreignother ministers (called 'Secretaries of State') responsible for subjects such as education, health and defence. countries ;",
      point: "1",
    },
    {
      question: "The judiciary is responsible for which TWO of the following?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Arresting people",
        "Interpreting the law",
        "Making sure that trials are fair",
        "Making the law",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Judges (who are together called 'the judiciary') are responsible for interpreting the law and ensuring that trials are conducted fairly. The government cannot interfere with this.",
      point: "1",
    },
    {
      question: "Which is a British overseas territory?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["France", "Northern Ireland", "The Falkland Islands", "USA"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several British overseas territories in other parts of the world, such as St Helena and the Falkland Islands. They are also linked to the UK but are not a part of it.",
      point: "1",
    },
    {
      question: "How old was Edward VI at the time of his death?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["15", "18", "36", "35"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry VIII was succeeded by his son Edward VI, who was strongly Protestant. During his reign, the Book of Common Prayer was written to be used in the Church of England. A version of this book is still used in some churches today. Edward died at the age of 15 after ruling for just over six years, and his half-sister Mary became queen.",
      point: "1",
    },
    {
      question: "Which queen is renowned for her rebellion against the Romans?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Anne", " Boudicca", "Elizabeth", "Victoria"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "One of the tribal leaders who fought against the Romans was Boudicca, the queen of the Iceni in what is now eastern England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Roman army left England after 150 years to defend other parts of their Empire.",
        "The Roman army left England after 400 years to defend other parts of their Empire.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Roman army left Britain in AD 410 to defend other parts of the Roman Empire and never returned.",
      point: "1",
    },
    {
      question:
        "During much of the Stone Age, how was Britain connected to the continent?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["A bridge", " A glacier", "A land bridge", "A tunnel"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first people to live in Britain were hunter-gatherers, in what we call the Stone Age. For much of the Stone Age, Britain was connected to the continent by a land bridge. People came and went, following the herds of deer and horses which they hunted. Britain only became permanently separated from the continent by the Channel about 10,000 years ago.",
      point: "1",
    },
    {
      question:
        'Which Scottish poet penned "The Bruce" about the Battle of Bannockburn?',
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Geoffrey Chaucer",
        "John Barbour",
        "John Milton",
        "Robert Burns",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, many people continued to speak Gaelic and the Scots language also developed. A number of poets began to write in the Scots language. One example is John Barbour, who wrote The Bruce about the Battle of Bannockburn.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Royal Society is the oldest surviving scientific society in the world.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During his reign, the Royal Society was formed to promote 'natural knowledge'. This is the oldest surviving scientific society in the world.",
      point: "1",
    },
    {
      question: "Which is a famous Stone Age site in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Globe Theatre",
        " Nelson's Column",
        "Stonehenge",
        "Windsor Castle",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "One of these monuments, Stonehenge, still stands in what is now the English county of Wiltshire.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Halloween has its roots in an ancient pagan festival marking the beginning of winter.",
        "Halloween is a modern American festival that has recently become popular in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Halloween, 31 October, is an ancient festival and has roots in the pagan festival to mark the beginning of winter.",
      point: "1",
    },
    {
      question: "Which TWO are renowned gardens in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Bodnant Garden", "London eye", "Sissinghurst", "Snowdonia"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are famous gardens to visit throughout the UK, including Kew Gardens, Sissinghurst and Hidcote in England,Crathes Castle and Inveraray Castle in Scotland, Bodnant Garden in Wales, and Mount Stewart in Northern Ireland.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No British writer has won the Nobel prize in Literature.",
        "Several British writers have won the Nobel prize in Literature.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has a prestigious literary history and tradition. Several British writers, including the novelist Sir William Golding, the poet Seamus Heaney, and the playwright Harold Pinter, have won the Nobel Prize in Literature.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?People over 75 years of age do not have to pay for a television licence.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People over 75 can apply for a free TV licence and blind people can get a 50% discount.",
      point: "1",
    },
    {
      question: "Which of the following statements are correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The National Trust is a charity that works to preserve important building in the UK.",
        "The National Trust is a government-run organisation that provides funding for charities.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many parts of the countryside and places of interest are kept open by the National Trust in England, Wales and Northern Ireland and the National Trust for Scotland. Both are charities that work to preserve important buildings, coastline and countryside in the UK.",
      point: "1",
    },
    {
      question:
        "Eid al-Fitr marks the end of Ramadan. How long do Muslims fast before this celebration?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Five days", "One month", "Twenty-one days", "Two weeks"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Eid al-Fitr celebrates the end of Ramadan, when Muslims have fasted for a month. They thank Allah for giving them the strength to complete the fast. The date when it takes place changes every year. Muslims attend special services and meals.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All dog owners must have licence to keep their pets.",
        "Dogs in public places must wear a collar showing the owner's name and address.",
        "Dogs in public places must wear a collar.",
        "Dogs in public places must wear a muzzle.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "All dogs in public places must wear a collar showing the name and address of the owner.",
      point: "1",
    },
    {
      question:
        "What sorts of cases do Crown Court and Sheriff Courts deal with?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Paid income tax in the previous year",
        "Passed an electoral test",
        "Put your name on the electoral register",
        "Registered your identity with the police",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To be able to vote in a parliamentary, local or European election, you must have your name on the electoral register. ",
      point: "1",
    },
    {
      question: "Which TWO chambers constitute the UK Parliament?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "House of Commons",
        "House of Fraser",
        "House of Lords",
        "House of Representatives",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the UK, there are several different parts of government. The main ones are:the monarchy ;Parliament (the House of Commons and the House of Lords)",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Self-employed people can ask a friend to pay their National Insurance Contribution on their behalf.",
        "Self-employed people need to pay National Insurance Contributions themselves.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People who are self-employed need to pay National Insurance Contributions themselves.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Countries are obliged to belong to the Commonwealth.",
        "Countries join the Commonwealth voluntarily.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development. Most member states were once part of the British Empire, although a few countries which were not have also joined. ",
      point: "1",
    },
    {
      question:
        "Which TWO matters can the devolved administrations legislate on?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Education", "Foreign affairs", "Health", "Immigration"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Policy and laws governing defence, foreign affairs, immigration, taxation and social security all remain under central UK government control. However, many other public services, such as education, are controlled by the devolved administrations.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Youth Court handles cases involving individuals under 21 in the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland, if an accused person is aged 10 to 17, the case is normally heard in a Youth Court in front of up to three specially trained magistrates or a District Judge. ",
      point: "1",
    },
    {
      question: "Which TWO of the following handle civil disputes?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["County Court", "Crown Court", "Sheriff Court", "Youth Court"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "County Courts deal with a wide range of civil disputes. These include people trying to get back money that is owed to them, cases involving personal injury, family matters, breaches of contract, and divorce. In Scotland, most of these matters are dealt with in the Sheriff Court. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Chequers is the Prime Minister's country house.",
        "Chequers is the Prime Minister's house in London.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister (PM) is the leader of the political party in power. He or she appoints the members of the cabinet (see below) and has control over many important public appointments. The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. He or she also has a country house outside London called Chequers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'Divine Rights of Kings' meant that the English king should rule France.",
        "The 'Divine Rights of Kings' meant that the king was appointed by God.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Before 1215, there were no constraints on the authority of the English king.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were few formal limits to the king's power until 1215. In that year, King John was forced by his noblemen to agree to a number of demands. The result was a charter of rights called the Magna Carta (which means the Great Charter). The Magna Carta established the idea that even the king was subject to the law.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Britain and Germany jointly developed Concorde, a supersonic passenger aircraft.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain and France developed Concorde, the world's only supersonic passenger aircraft.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " Elizabeth I came to the throne when, following a long reign, her cousin Mary died.",
        "Elizabeth I came to the throne when, following a short reign, her half-sister Mary died.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was a devout Catholic and persecuted Protestants (for this reason, she became known as 'Bloody Mary'). Mary also died after a short reign and the next monarch was her half-sister, Elizabeth, the daughter of Henry VIII and Anne Boleyn.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following individuals were members of the Royal Society?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Samuel Pepys",
        " Sir Christopher Wren",
        "Sir Edmond Halley",
        " Sir Isaac Newton",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles II was interested in science. During his reign, the Royal Society was formed to promote 'natural knowledge'. This is the oldest surviving scientific society in the world. Among its early members were Sir Edmund Halley, who successfully predicted the return of the comet now called Halley's Comet, and Sir Isaac Newton.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Bill of Rights affirmed the rights of Parliament and the constraints on the king's authority.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Bill of Rights, 1689, confirmed the rights of Parliament and the limits of the king's power.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Houses of Parliament were constructed in the medieval Gothic architectural style.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, the medieval 'gothic' style became popular again. As cities expanded, many great public buildings were built in this style. The Houses of Parliament and St Pancras Station were built at this time, as were the town halls in cities such as Manchester and Sheffield.",
      point: "1",
    },
    {
      question: "Which of these statements is true?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Most People live in the countryside but much of Britain is covered by towns and cities.",
        "Most people live in towns and cities but much of Britain is still countryside",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is located in the north west of Europe. The longest distance on the mainland is from John O'Groats on the north coast of Scotland to Land's End in the south-west corner of England. It is about 870 miles (approximately 1,400 kilometres). Most people live in towns and cities but much of Britain is still countryside.",
      point: "1",
    },
    {
      question:
        "When walking with your dog in a public place, what must you ensure?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "That your dog does not come into contact with other dogs",
        "That your dog never strays more than 3 metres away from you",
        "That your dog wears a collar showing the name and address of the owner",
        "That your dog wears a special dog coat",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Dundee and Aberdeen are cities in Northern Ireland.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Dundee and Aberdeen are cities of Scotland.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? William Blake, Lord Byron, and Robert Browning were all esteemed golfers.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Poetry was very popular in the 19th century, with poets such as William Blake, John Keats, Lord Byron, Percy Shelley, Alfred Lord Tennyson, and Robert and Elizabeth Browning.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The British Broadcasting Corporation (BBC) is funded through television program advertisements.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The money from TV licences is used to pay for the British Broadcasting Corporation (BBC).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "There is no established Church in Wales or Northern Ireland.",
        "Wales and Northern Ireland each have their own Church of state.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is no established Church in Wales or Northern Ireland.",
      point: "1",
    },
    {
      question: "Who authored the novel Lucky Jim?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "James Callaghan",
        "James Joyce",
        "Jimmy Tarbuck",
        "Sir Kingsley Arnis",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Kingsley Amis (1922-95) was an English novelist and poet. He wrote more than 20 novels. The most well known is Lucky Jim.",
      point: "1",
    },
    {
      question:
        "What TWO actions can a judge take if a public body is not respecting someone's legal rights?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Communist government",
        "Dictatorship",
        "Federal government",
        "Parliament democracy",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Democracy is a system of government where the whole adult population gets a say. This might be by direct voting or by choosing representatives to make decisions on their behalf. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The official home of the Prime Minister is 10 Downing Street.",
        "The official home of the Prime Minister is Buckingham Palace",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In Northern Ireland a member of your family must complete a voting registration form on your behalf.",
        " In Northern Ireland all those entitled to vote must complete their own registration form.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Northern Ireland a different system operates. This is called 'individual registration' and all those entitled to vote must complete their own registration form. ",
      point: "1",
    },
    {
      question: "What are members of the Northern Ireland Parliament known as?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["MEPs", "MLAs", "MNIPs", "MPs"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is a power-sharing agreement which distributes ministerial offices amongst the main parties. The Assembly has 108 elected members, known as MLAs (members of the Legislative Assembly). They are elected with a form of proportional representation.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You may introduce yourself to your neighbours when you move into a new house or flat, if circumstances permit",
        "You should ignore your new neighbours if they want to introduce themselves to you",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When you move into a new house or apartment, try to introduce yourself to the people who live near you. Getting to know your neighbours can help you to become part of the community and make friends. ",
      point: "1",
    },
    {
      question: "How many nations are members of the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["12", "25", "29", "54"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The King is the ceremonial head of the Commonwealth, which currently has 54 member states (see table below). Membership is voluntary. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? All Members of Parliament (MPs) represent one of the main political parties.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Each MP represents a parliamentary constituency, which is a small area of the country. ",
      point: "1",
    },
    {
      question:
        "Approximately for how many years did the Romans occupy Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["100 years", "400 years", " 50 years", "600 years"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Romans remained in Britain for 400 years.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the First World War Winston Churchill was the British Prime Minister.",
        "During the Second World War Winston Churchill was the British Prime Minister.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "At this time of national crisis, Winston Churchill became Prime Minister and Britain's war leader.",
      point: "1",
    },
    {
      question: "Who emerged victorious in the English Civil War of 1646?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["None of the above", "Parliament", "The Cavaliers", "The King"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The king's army was defeated at the Battles of Marston Moore and Naseby. By 1646, it was clear that Parliament had won the war.",
      point: "1",
    },
    {
      question: "Which TWO developments are linked to the 'Swinging Sixties'?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Abortion law reform",
        "Children's rights law reform",
        "Decimal currency",
        "Divorce law reform",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It was also a time when social laws were liberalised, for example in relation to divorce and to abortion in England, Wales and Scotland.",
      point: "1",
    },
    {
      question:
        "Catherine Parr became the final spouse of Henry VIII. Who preceded her in death?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "He died first and she was a widow for the rest of her life",
        "He died first and she went on to remarry",
        "She died first and he never remarried",
        "She died in childbirth and Henry remarried",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine Parr - Catherine was a widow who married Henry late in his life. She survived him and married again but died soon after.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? France was the first nation to undergo large-scale industrialization.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries. Britain was the first country to industrialise on a large scale.",
      point: "1",
    },
    {
      question:
        "Mary Stuart was forced to flee to England after being accused of murder. Who was she accused of murdering?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Her brother", "Her father", "Her husband", "Her sister"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When her husband was murdered, Mary was suspected of involvement and fled to England.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Domesday Book no longer exists, having been destroyed following the Norman Conquest.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The people who lived there, who owned the land and what animals they owned were also listed. This was called Domesday Book. It still exists today and gives a picture of society in England just after the Norman Conquest.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Proms are a series of tennis matches held every June in London.",
        "The Proms is an eight-week summer season of orchestral music.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Proms is an eight-week summer season of orchestral classical music that takes place in various venues, including the Royal Albert Hall in London.",
      point: "1",
    },
    {
      question:
        "Which is a famous tennis tournament played in the UK every year?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Six Nations", "The Grand National", "The Open", "Wimbledon"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The most famous tournament hosted in Britain is The Wimbledon Championships, which takes place each year at the All England Lawn Tennis and Croquet Club.",
      point: "1",
    },
    {
      question:
        "On which day do people send cards to someone they admire, anonymously?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "April Fool's Day",
        "Christmas Day",
        "New Year's Day",
        "Valentine's Day",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Valentine's Day, 14 February, is when lovers exchange cards and gifts. Sometimes people send anonymous cards to someone they secretly admire",
      point: "1",
    },
    {
      question:
        "Which annual flower show in London showcases garden designs from around the world?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Chelsea", "Covent Garden", "Kensington", "South Bank"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The annual Chelsea Flower Show showcases garden design from Britain and around the world.",
      point: "1",
    },
    {
      question: "What is the name of the famous horse race held in Liverpool?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Far from the Madding Crowd",
        "Oliver Twist",
        "Our Man in Havana",
        "Sense and Sensibility",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Austen (1775-1817) was an English novelist. Her books include Pride and Prejudice and Sense and Sensibility. Her novels are concerned with marriage and family relationships. Many have been made into television programmes or films.",
      point: "1",
    },
    {
      question:
        "In which of these years did Britain not host the Olympic Games?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1908", "1928", "1948", " 2012"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has hosted the Olympic Games on three occasions: 1908, 1948 and 2012.",
      point: "1",
    },
    {
      question: "Which TWO foods are commonly associated with England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Fish and chips", "Haggis", "Roast beef", "Ulster fry"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are a variety of foods that are traditionally associated with different parts of the UK:England: Roast beef, which is served with potatoes, vegetables, Yorkshire puddings (batter that is baked in the oven) and other accompaniments. Fish and chips are also popular.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The National Citizen Service provides military training to young individuals.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "These include the National Citizen Service programme, which gives 16- and 17-year-olds the opportunity to enjoy outdoor activities, develop their skills and take part in a community project. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? A civil law case involves lodging a legal complaint for a defective purchase.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of civil laws are:Consumer rights: an example of this is a dispute about faulty goods or services.",
      point: "1",
    },
    {
      question: "Which is a good way of helping your community?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Complaining",
        "Spending lots of time online",
        "Travelling",
        "Volunteering",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Volunteering is working for good causes without payment. There are many benefits to volunteering, such as meeting new people and helping make your community a better place. Some volunteer activities will give you a chance to practice your English or develop work skills that will help you find a job or improve your curriculum vitae (CV). Many people volunteer simply because they want to help other people.",
      point: "1",
    },
    {
      question: "Which provide legal advice, normally for a fee?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Judges", "Magistrates", "Solicitors", "The police"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Solicitors are trained lawyers who give advice on legal matters, take action for their clients and represent their clients in court. Solicitors' charges are usually based on how much time they spend on a case. It is very important to find out at the start how much a case is likely to cost.",
      point: "1",
    },
    {
      question: "Which of the following is not an aim of the United Nations?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To prevent war",
        "To promote free trade",
        "To promote international security",
        "To promote peace",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UN was set up after the Second World War and aims to prevent war and promote international peace and security. There are 15 members on the UN Security Council, which recommends action when there are international crises and threats to peace. The UK is one of five permanent members of the Security Council.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "More than 190 countries belong to NATO",
        "More than 190 countries belong to the United Nations.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is part of the United Nations (UN), an international organisation with more than 190 countries as members. ",
      point: "1",
    },
    {
      question:
        " Is the statement  TRUE or FALSE?There are no differences between the court systems of England, Northern Ireland, Scotland and Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are some differences between the court systems in England and Wales, Scotland and Northern Ireland.",
      point: "1",
    },
    {
      question: "Which TWO are 20th-century British discoveries or inventions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Hovercraft", "Penicillin", "Printing press", "Radium"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Christopher Cockerell (1910-99), a British inventor, invented the hovercraft in the 1950s. (Britain since 1945 - Some great British inventions of the 20th century). Born in Scotland, Fleming moved to London as a teenager and later qualified as a doctor. He was researching influenza (the 'flu') in 1928 when he discovered penicillin.",
      point: "1",
    },
    {
      question:
        " Which TWO of the following were important 20th-century inventors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Alan Turing",
        "George Stephenson",
        " Isambard Kingdom Brunel",
        "Tim Berners-Lee",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A Turing machine is a theoretical mathematical device invented by Alan Turing (1912-54), a British mathematician, in the 1930s; The inventor of the World Wide Web, Sir Tin Berners-Lee (1955-), is British",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Commercial expansion and prosperity in the 19th century were partly sustained by the thriving slave trade.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This commercial expansion and prosperity was sustained in part by the booming slave trade. While slavery was illegal within Britain itself, by the 18th century it was a fully established overseas industry, dominated by Britain and the American colonies.",
      point: "1",
    },
    {
      question:
        "Which of these reasons best explains why town planning laws were tightened in the early 20th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To allow for the building of new churches",
        "To allow for the building of new roads",
        "To prevent the further development of slums",
        "To stop skyscrapers being built",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Various laws were passed to improve safety in the workplace; town planning rules were tightened to prevent the further development of slums; and better support was given to mothers and their children after divorce or separation.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Margaret Thatcher was the UK's first female Prime Minister.",
        "Margaret Thatcher was the UK's second female Prime Minister.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Margaret Thatcher, Britain's first woman Prime Minister, led the Conservative government from 1979 to 1990. The government made structural changes to the economy through the privatisation of nationalised industries and imposed legal controls on trade union powers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The leaders of Easter uprising were transported to Australia.",
        "The leaders of the Easter uprising were executed under military law.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Irish Nationalists were not willing to wait and in 1916 there was an uprising (the Easter Rising) against the British in Dublin. The leaders of the uprising were executed under military law",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Britain had colonies in southern Africa in the 19th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain gained control over Canada, and the East India Company, originally set up to trade, gained control of large parts of India. Colonies began to be established in southern Africa.",
      point: "1",
    },
    {
      question:
        "During the English Civil War, in which TWO battles was Charles I's army defeated?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Battle of Bosworth field",
        "Battle of Hastings",
        "Battle of Marston Moor",
        "Battle of Naseby",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The king's army was defeated at the Battles of Marston Moore and Naseby. By 1646, it was clear that Parliament had won the war.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The BBC initiated the world's first regular television service in 1936.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The BBC started radio broadcasts in 1922 and began the world's first regular television service in 1936.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?In the UK, 1 April is a day when people play jokes on each.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "April Fool's Day, 1 April, is a day when people play jokes on each other until midday. The television and newspapers often have stories that are April Fool jokes.",
      point: "1",
    },
    {
      question: "Which is a traditional food associated with Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Fish and chips", "Haggis", "Roast beef", "Ulster fry"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Scotland: Haggis - a sheep's stomach stuffed with offal, suet, onions and oatmeal.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sir Terrence Conran was an 18th-century designer of furniture.",
        "Thomas Chippendale was an 18th- century designer of furniture.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain has produced many great designers, from Thomas Chippendale (who designed furniture in the 18th century) to Clarice cliff (who designed Art Deco ceramics) to Sir Terence Conran (a 20th-century interior designer). Leading fashion designers of recent years include Mary Quant, Alexander McQueen and Vivienne Westwood.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The House of Commons may never overrule the House of Lords",
        "The House of Commons may overrule the Houser of Lords",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The House of Commons has powers to overrule the House of Lords, but these are not used often.",
      point: "1",
    },
    {
      question:
        "Along with West Germany, France, Luxembourg, the Netherlands, and Italy, which other country formed the European Economic Community (EEC) in 1957?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Albania", " Belgium", "Ireland", "Sweden"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The European Union (EU), originally called the European Economic Community (EEC), was set up by six western European countries (Belgium, France, Germany, Italy, Luxembourg and the Netherlands) who signed the Treaty of Rome on 25 March 1957.",
      point: "1",
    },
    {
      question:
        "In which TWO of the following areas does the Northern Ireland Assembly make decisions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Agriculture",
        "Foreign policy",
        " Nuclear energy",
        "The environment",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Northern Ireland Assembly can make decisions on issues such as:education ;agriculture ;the environment ;health ;social services.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Forcing another person to marry is a criminal offence in the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Forcing another person to marry is a criminal offence. ",
      point: "1",
    },
    {
      question:
        "What TWO values are upheld by the Commonwealth association of countries?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Close down the public body",
        "Order them to change their practices",
        "Order them to pay compensation",
        "Place its members in prison",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If judges find that a public body is not respecting someone's legal rights, they can order that body to change its practices and/or pay compensation. ",
      point: "1",
    },
    {
      question: "In which city is the Scottish Parliament headquartered?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cardiff", "Edinburgh", " Glasgow", " Sheffield"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Scottish Parliament was formed in 1999. It sits in Edinburgh, the capital city of Scotland.",
      point: "1",
    },
    {
      question:
        "In which year were the Welsh Assembly and Scottish Parliament formed?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1997", "1999", "2003", "2008"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Since 1997, some powers have been devolved from the central government to give people in Wales, Scotland and Northern Ireland more control over matters that directly affect them. There has been a Welsh Assembly and a Scottish Parliament since 1999.",
      point: "1",
    },
    {
      question:
        "Which is a document that sets out fundamental rights or freedoms?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Act of Union",
        "The Habeas Corpus Act",
        "The Statute of Rhuddlan",
        "The UK Constitution",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain has a long history of respecting an individual's rights and ensuring essential freedoms. These rights have their roots in Magna Carta, the Habeas Corpus Act and the Bill of Rights of 1689 ",
      point: "1",
    },
    {
      question: "TRUE or FALSE? Mayors are invariably elected.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected through a system called 'first past the post'. In each constituency, the candidate who gets the most votes is elected. The government is usually formed by the party that wins the majority of constituencies. If no party wins a majority, two parties may join together to form a coalition.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Union Flag comprises of four crosses, one for each part of the United Kingdom.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The flag combined crosses associated with England, Scotland and Ireland. It is still used today as the official flag of the UK.",
      point: "1",
    },
    {
      question:
        "Which English king led his army to victory at the Battle of Agincourt?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Henry II", "Henry V", "Henry VII", "Henry VIII"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "One of the most famous battles of the Hundred Years War was the Battle of Agincourt in 1415, where King Henry V's vastly outnumbered English army defeated the French. The English left France in the 1450s.",
      point: "1",
    },
    {
      question:
        "Where did Florence Nightingale establish the Nightingale School for Nurses in 1860?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Addenbrooke's Hospital, Cambridge",
        "Prince Philip Hospital, Llanelli",
        "St James's University Hospital, Leeds",
        "St Thomas' Hospital, London",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1860 she established the Nightingale Training School for nurses at St Thomas' Hospital in London. The school was the first of its kind and still exists today, as do many of the practices that Florence used. She is often regarded as the founder of modern nursing.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Colonists in North America were poorly educated and uninterested in politics.",
        "Colonists in North America were well educated and interested in ideas of liberty.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By the 1760s, there were substantial British colonies in North America. The colonies were wealthy and largely in control of their own affairs. Many of the colonist families had originally gone to North America in order to have religious freedom. They were well educated and interested in ideas of liberty.",
      point: "1",
    },
    {
      question: "What did Charles II lack legitimacy in?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Children", "Government", "Right to the throne", " Wife"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Charles II had no legitimate children.",
      point: "1",
    },
    {
      question: "Which of these statements about Roald Dahl is not true?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "He began to publish books and short stories in the 1940s",
        "He had Norwegian parents",
        "He served in the Royal Air Force during the Second World War",
        "He served in the Royal Navy during the Second World War",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Roald Dahl was born in Wales to Norwegian parents. He served in the Royal Air Force during the Second World War. It was during the 1940s that he began to publish books and short stories. He is most well known for his children's books, although he also wrote for adults.",
      point: "1",
    },
    {
      question:
        "Which TWO of these professions did Winston Churchill engage in before becoming a Conservative MP in 1900?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Journalist", "Soldier", "Teacher", "Vicar"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Churchill was the son of a politician and, before becoming a Conservative MP in 1900, was a soldier and journalist.",
      point: "1",
    },
    {
      question: "Under which king's rule was the Domesday Book compiled?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Alfred the Great",
        "Harold",
        "Kenneth MacAlpin",
        "William the Conqueror",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "William sent people all over England to draw up lists of all the towns and villages. The people who lived there, who owned the land and what animals they owned were also listed. This was called Domesday Book.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Hundred Years War between England and France lasted 99 years.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "English kings also fought a long war with France, called the Hundred Years War (even though it actually lasted 116 years).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By 1400, a mixture of Norman French and Anglo-Saxon was being spoken and no unified language was emerging.",
        "By 1400, in England, official documents were being written in English and English was the preferred language of Parliament and the royal court.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, in England, official documents were being written in English, and English had become the preferred language of the royal court and Parliament.",
      point: "1",
    },
    {
      question: "Which TWO of these books did Rudyard Kipling write?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Animal Farm",
        "Just So Stories",
        "The Jungle Book",
        "The Time Machine",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Kipling was awarded the Nobel Prize in Literature in 1907. His books include the Just So Stories and The Jungle Book, which continue to be popular today.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following were very popular sixties British pop groups?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Abba", "The Beach Boys", "The Beatles", "The Rolling Stones"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Two well-known pop music groups at the time were The Beatles and The Rolling Stones.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Thomas Hardy is a famous author who wrote Far from the Madding Crowd.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Thomas Hardy (1840-1928) was an author and poet. His best-known novels focus on rural society and include Far from the Madding Crowd and Jude the Obscure.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The 40 days before Easter are known as Lent.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The 40 days before Easter are known as Lent.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? There are now more women in high-level positions than ever before.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Employment opportunities for women are much greater than they were in the past. Women work in all sectors of the economy, and there are now more women in high-level positions than ever before, including senior managers in traditionally male-dominated occupations. Alongside this, men now work in more varied jobs than they did in the past.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "If your driving licence is from a country in the European Union you can drive in the UK for as long as your license is valid.",
        "If your driving licence is from a country in the European Union you have to apply for a UK licence in order to drive.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If your driving licence is from a country in the European Union (EU), Iceland, Liechtenstein or Norway, you can drive in the UK for as long as your licence is valid. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Scottish Parliament can pass legislation for Scotland on all matters.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Scottish Parliament can pass laws for Scotland on all matters which are not specifically reserved to the UK Parliament.",
      point: "1",
    },
    {
      question: "What is the title of the UK National Anthem?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To decide the sentence that the accused should be given",
        "To decide whether evidence should be allowed to be heard",
        "To decide whether the accused is 'guilty' or 'not guilty'",
        "To decide who the judge should be",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The jury has to listen to the evidence presented at the trial and then decide a verdict of 'guilty' or 'not guilty' based on what they have heard.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "MPs were elected through a system called 'the winner takes it all'.",
        "Members of Parliament (MPs) are elected through a system called 'first past the post'.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected through a system called 'first past the post'. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? It is possible to find information about visiting Parliament on the UK Parliament website.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You can find further information on the UK Parliament website at www.parliament.uk ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Completed ballots must be handed to an election official.",
        "Completed ballots must be placed in a ballot box.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You should follow the instructions on the ballot paper. Once you have completed it, put it in the ballot box. ",
      point: "1",
    },
    {
      question: "Which is a Crown dependency?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "England",
        "Northern Ireland",
        "Scotland",
        "The Channel Islands",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several islands which are closely linked with the UK but are not part of it: the Channel Islands and the Isle of Man. These have their own governments and are called 'Crown dependencies'.",
      point: "1",
    },
    {
      question: "Which is a country of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Channel Islands",
        "Isle of Man",
        "Republic of Ireland",
        "Scotland",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is made up of England, Scotland, Wales and Northern Ireland.",
      point: "1",
    },
    {
      question:
        "Which TWO developments were characteristic of the Industrial Revolution?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Changes in the law",
        "Machinery",
        "Medical advances",
        "Steam power",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain was the first country to industrialise on a large scale. It happened because of the development of machinery and the use of steam power.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Black Death brought about major changes in English society due to the number of people who died.",
        "The Black Death was a plague that only had an impact in Ireland, where many people died.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1348, a disease, probably a form of plague, came to Britain. This was known as the Black Death. One third of the population of England died and a similar proportion in Scotland and Wales. This was one of the worst disasters ever to strike Britain.",
      point: "1",
    },
    {
      question:
        "What event in 1851 took place at the Crystal Palace in Hyde Park and showed goods and exhibits from Britain and across the world?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Great Show",
        "The Great Event",
        "The Great Exhibition",
        "The Great Occasion",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK also became a centre for financial services, including insurance and banking. In 1851, the Great Exhibition opened in Hyde Park in the Crystal Palace, a huge building made of iron and glass. Exhibits ranged from huge machines to handmade goods. Countries from all over the world showed their goods but most of the objects were made in Britain.",
      point: "1",
    },
    {
      question:
        "Which of these countries did not fight on the side of the Allied Powers during World War I?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bulgaria", "Italy", "Serbia", "US"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Allies fought against the Central Powers - mainly Germany, the Austro-Hungarian Empire, the Ottoman Empire and later Bulgaria.",
      point: "1",
    },
    {
      question:
        "Which Acts of Parliament, passed in 1832 and 1867, abolished rotten boroughs and greatly increased the right to vote?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Change Acts",
        "The Election Acts",
        "The Reform Acts",
        "The Voting Acts",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote. The Act also abolished the old pocket and rotten boroughs and more parliamentary seats were given to the towns and cities.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Charles II marched into England with a Scottish army to reclaim his throne.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He was crowned king of Scotland and led a Scottish army into England.",
      point: "1",
    },
    {
      question:
        "In which TWO countries did poet and author Rudyard Kipling spend time living?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Fiji", "India", "Japan", "USA"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Rudyard Kipling was born in India in 1865 and later lived in India, the UK and the USA. He wrote books and poems set in both India and the UK.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "There is a yearly race on the River Thames between Oxford and Cambridge Universities.",
        "There is a yearly sailing race on the River Thames between Oxford and Cambridge Universities.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Rowing is also popular, both as a leisure activity and as a competitive sport. There is a popular yearly race on the Thames between Oxford and Cambridge Universities.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Northern Ireland and Scotland have their own banknotes.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Northern Ireland and Scotland have their own banknotes, which are valid everywhere in the UK.",
      point: "1",
    },
    {
      question:
        "What literary works are Beowulf, The Tyger, and She Walks in Beauty?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Films", "Novels", " Plays", "Poems"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: " Poems.",
      point: "1",
    },
    {
      question: "Which TWO patron saints' days are observed in March?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["St Andrew", "St David", "St George", "St Patrick"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " England, Scotland, Wales and Northern Ireland each have a national saint, called a patron saint. Each saint has a special day:1 March: St David's Day, Wales ;17 March: St Patrick's Day, Northern Ireland ;23 April. St George's Day, England ;30 November: St Andrew's Day, Scotland",
      point: "1",
    },
    {
      question:
        "Which sport has its origins traced back to 15th-century Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Formula 1", "Golf", "Motorbike racing", "Surfing"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The modern game of golf can be traced back to 15th century Scotland.",
      point: "1",
    },
    {
      question:
        "Who composed musicals like Cats, The Phantom of the Opera, and Evita?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Andrew Lloyd Webber",
        "Gilbert and Sullivan",
        "Julian Lloyd Webber",
        "Sir Edward Elgar",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "More recently, Andrew Lloyd Webber has written the music for shows which have been popular throughout the world, including, in collaboration with Tim Rice, Jesus Christ Superstar and Evita, and also Cats and The Phantom of the Opera.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Wimbledon Championships are associated with athletics.",
        "The Wimbledon Championships are associated with tennis.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Modern tennis evolved in England in the late 19th century. The first tennis club was founded in Leamington Spa in 1872. The most famous tournament hosted in Britain is The Wimbledon Championships, which takes place each year at the All England Lawn Tennis and Croquet Club. It is the oldest tennis tournament in the world and the only 'Grand Slam' event played on grass.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Sir Ian Botham captained the England rugby team.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Ian Botham (1955- ) captained the English cricket team and holds a number of English Test cricket records, both for batting and for bowling.",
      point: "1",
    },
    {
      question:
        "Which country is Loch Lomond and the Trossachs National Park located in?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This national park covers 720 square miles (1,865 square kilometres) in the west of Scotland. Loch Lomond is the largest expanse of fresh water in mainland Britain and probably the best-known part of the park.",
      point: "1",
    },
    {
      question:
        "The game of golf is traditionally thought to have originated in which country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Scotland", "Spain", "USA"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The modern game of golf can be traced back to 15th century Scotland.",
      point: "1",
    },
    {
      question: "When is a by-election for a parliamentary seat held?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Every two years",
        "Half-way through a parliamentary term",
        "When a member of Parliament (MP) dies or resigns",
        "When the Prime Minister decides to call one",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If an MP dies or resigns, there will be a fresh election, called a by-election, in his or her constituency. ",
      point: "1",
    },
    {
      question: "How many individuals constitute a Scottish jury?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["10", "12", "15", "20"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A jury is made up of members of the public chosen at random from the local electoral register (see page 134). In England, Wales and Northern Ireland a jury has 12 members, and in Scotland a jury has 15 members. ",
      point: "1",
    },
    {
      question: "Which TWO are British overseas territories?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Falkland Islands", "Hawaii", "St Helena"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several British overseas territories in other parts of the world, such as St Helena and the Falkland Islands. They are also linked to the UK but are not a part of it.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following individuals were notable Victorians?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Dylan Thomas",
        "Florence Nightingale",
        "Isambard Kingdom Brunel",
        "Margaret Thatcher",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Brunel was originally from Portsmouth, England. He was an engineer who built tunnels, bridges, railway lines and ships; In 1854, she went to Turkey and worked in military hospitals, treating soldiers who were fighting in the Crimean War. She and her fellow nurses improved the conditions in the hospital and reduced the mortality rate.",
      point: "1",
    },
    {
      question: "Who were the 'suffragettes'?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Women who campaigned for women's votes",
        "Women who chose to be single",
        "Women who left the UK to live in India",
        "Women who stayed at home to raise a family",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the late 19th and early 20th centuries, an increasing number of women campaigned and demonstrated for greater rights and, in particular, the right to vote. They formed the women's suffrage movement and became known as 'suffragettes'.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Swinging Sixties is associated with the 1860s.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The decade of the 1960's was a period of significant social change. It was known as 'the Swinging Sixties'.",
      point: "1",
    },
    {
      question: "Which group of refugees settled in England before 1720?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Brelons", "Germans", "Huguenots", "Welsh"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first Jews to come to Britain since the Middle Ages settled in London in 1656. Between 1680 and 1720 many refugees called Huguenots came from France.",
      point: "1",
    },
    {
      question:
        "In the 1960s, Parliament enacted laws prohibiting employers from discriminating against women based on gender and granting them which right?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Childcare at work",
        "Equal pay",
        "Free lunches",
        "Two year's maternity leave",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It was quite common at the time for employers to ask women to leave their jobs when they got married, but Parliament passed new laws giving women the right to equal pay and made it illegal for employers to discriminate against women because of their gender.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Robert Burns wrote in Scots language.",
        "Robert Burns wrote in Scots, English and a combination of both.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Known in Scotland as 'The Bard', Robert Burns was a Scottish poet. He wrote in the Scots language, English with some Scottish words, and standard English. He also revised a lot of traditional folk songs by changing or adding lyrics. Burns' best-known work is probably the song Auld Lang Syne, which is sung by people in the UK and other countries when they are celebrating the New Year (or Hogmanay as it is called in Scotland).",
      point: "1",
    },
    {
      question: "Where did the Boer War take place?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "France", " South Africa", "Turkey"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Boer War of 1899 to 1902 made the discussions about the future of the Empire more urgent. The British went to war in South Africa with settlers from the Netherlands called the Boers.",
      point: "1",
    },
    {
      question: "Rudyard Kipling was awarded which major prize in 1907?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Man Booker Prize",
        "The Nobel Prize in Literature",
        "The Pulitzer Prize",
        "The Somerset Maugham Award",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Kipling was awarded the Nobel Prize in Literature in 1907.",
      point: "1",
    },
    {
      question: "Which TWO of the following statements are accurate?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The Roman army faced no resistance during the invasions.",
        "The Romans built roads and public buildings, and created a structure of law.",
        "The Romans introduced new plants and animals to Britain.",
        "The Romans unified the whole of the British Isles.",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Romans remained in Britain for 400 years. They built roads and public buildings, created a structure of law, and introduced new plants and animals.",
      point: "1",
    },
    {
      question:
        "In England, when were parliaments convened besides for consulting nobles?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To call elections",
        "To create new lords",
        "To lower taxes",
        "To raise money",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, parliaments were called for the king to consult his nobles, particularly when the king needed to raise money.",
      point: "1",
    },
    {
      question:
        "What principles did Charles I believe in and attempt to govern by?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Communism",
        "Democracy",
        "Religious virtue",
        "The Divine Rights of Kings",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I were less skilled politically. Both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The American colonies rebelled against the British Parliament in the 18th century because they did not want to pay taxes without representation in Parliament.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments. The colonists eventually defeated the British army and Britain recognised the colonies' independence in 1783.",
      point: "1",
    },
    {
      question:
        "Textile and engineering firms recruited workers from which TWO countries after the Second World War?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Canada", "India", "Pakistan", "South Africa"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Textile and engineering firms from the north of England and the Midlands sent agents to India and Pakistan to find workers. For about 25 years, people from the West Indies, India, Pakistan and (later) Bangladesh travelled to work and settle in Britain.",
      point: "1",
    },
    {
      question: "Where in London is the White Tower located?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Buckingham Palace",
        "Palace of Westminster",
        "St Paul's Cathedral",
        "Tower of London",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The White Tower in the Tower of London is an example of a Norman castle keep, built on the orders of William the Conqueror",
      point: "1",
    },
    {
      question: "What kind of monarchy does the UK have?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Absolute", "Constitutional", "Democratic", "Provisional"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The laws passed after the Glorious Revolution are the beginning of what is called 'constitutional monarchy'.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Mo Farah and Jessica Ennis are well-known athletes who won gold medals at the 2012 London Olympics.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jessica Ennis (1980-) is an athlete. She won the 2012 Olympic gold medal in the heptathlon, which includes seven different track and field events. She also holds a number of British athletics records. Mo Farah (1983-) is a British distance runner, born in Somalia. He won gold medals in the 2012 Olympics for the 5,000 and 10,000 metres and is the first Briton to win the Olympic gold medal in the 10,000 metres.",
      point: "1",
    },
    {
      question: "Which UK city hosted the 2012 Paralympic Games?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Belfast", "Cardiff", "Edinburgh", "London"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Paralympic Games for 2012 were also hosted in London.",
      point: "1",
    },
    {
      question: "Which takes place on 14 February every year?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All Saints Day",
        "St Christopher's Day",
        "St George's Day",
        "St Valentine's Day",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Valentine's Day, 14 February, is when lovers exchange cards and gifts. Sometimes people send anonymous cards to someone they secretly admire.",
      point: "1",
    },
    {
      question: "Who wrote the poem The Tyger?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "William Blake",
        "William Shakespeare",
        "William Wallace",
        "William Wordsworth",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Tyger! Tyger! Burning bright, In the forests of the night, What immortal hand or eye, Could frame thy fearful symmetry?' (William Blake, 1757-1827 - The Tyger)",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Members of the public are allowed to attend Youth Court hearings.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members of the public are not allowed In Youth Courts, and the name or photographs of the accused young person cannot be published in newspapers or used by the media",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "James VI of Scotland was NOT related to Queen Elizabeth I of England.",
        "James VI of Scotland was related to Queen Elizabeth I of England.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth I never married and so had no children of her own to inherit her throne. When she died in 1603 her heir was her cousin James VI of Scotland.",
      point: "1",
    },
    {
      question:
        "Following his defeat in the Civil War, what was the fate of Charles I?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "He was Excommunicated",
        "He was executed",
        "He was exiled",
        "he was exonerated",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The king's army was defeated at the Battles of Marston Moore and Naseby. By 1646, it was clear that Parliament had won the war. Charles was held prisoner by the parliamentary army. He was still unwilling to reach any agreement with Parliament and in 1649 he was executed.",
      point: "1",
    },
    {
      question: "How was Elizabeth I skilled at managing Parliament?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "She established a balance between Catholics and protestants",
        "She reduced the power of parliament so her wishes had more significance",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth did not ask about people's real beliefs. She succeeded in finding a balance between the views of Catholics and the more extreme Protestants. In this way, she avoided any serious religious conflict within England.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Domesday Book no longer exists. It was destroyed at the end of the Norman Conquest.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The people who lived there, who owned the land and what animals they owned were also listed. This was called Domesday Book. It still exists today and gives a picture of society in England just after the Norman Conquest.",
      point: "1",
    },
    {
      question: "What was the name of Henry VIII's first wife?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Anne Boleyn",
        "Anne of Cleves",
        "Catherine Howards",
        "Catherine of Aragon",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine of Aragon- Catherine was a Spanish princess. She and Henry had a number of children but only one, Mary, survived. When Catherine was too old to give him another child, Henry decided to divorce her, hoping that another wife would give him a son to be his heir.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? England ruled Ireland at the commencement of the 'Middle Ages'.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "At the beginning of the Middle Ages, Ireland was an independent country.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? King Henry I was on the throne at the time of Magna Carta.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were few formal limits to the king's power until 1215. In that year, King John was forced by his noblemen to agree to a number of demands. The result was a charter of rights called the Magna Carta (which means the Great Charter). The Magna Carta established the idea that even the king was subject to the law.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "James I and Charles I believed in the 'Divine Right of Kings' and that they could rule without the approval of Parliament",
        "James I and Charles I were very skilled at negotiating with Parliament",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I were less skilled politically. Both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule. They thought that the king should be able to act without having to seek approval from Parliament.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1789 there was a revolution in France and the new French government declared war on Britain.",
        "In 1789 there was an unpopular new government in France and Britain declared war.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1789, there was a revolution in France and the new French government soon declared war on Britain.",
      point: "1",
    },
    {
      question:
        "In 1913, the British government pledged Home Rule for Ireland. Which group within Ireland threatened to resist this through force?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Buddhists in the west",
        "The Muslims in the east",
        "The Protestants in the south",
        "The protestants in the north",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1913, the British government promised 'Home Rule' for Ireland. The proposal was to have a self-governing Ireland with its own parliament but still part of the UK. A Home Rule Bill was introduced in Parliament. It was opposed by the Protestants in the north of Ireland, who threatened to resist Home Rule by force.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following terms may refer to the day preceding Lent?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Ash Wednesday",
        "Good Friday",
        "Pancake Day",
        "Shrove Tuesday",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The day before Lent starts is called Shrove Tuesday, or Pancake Day.",
      point: "1",
    },
    {
      question: "Which TWO religions celebrate Diwali?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Buddhists", "Christians", "Hindus", " Sikhs"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Diwali normally falls in October or November and lasts for five days. It is often called the Festival of Lights. It is celebrated by Hindus and Sikhs. It celebrates the victory of good over evil and the gaining of knowledge. There are different stories about how the festival came about. There is a famous celebration of Diwali in Leicester.",
      point: "1",
    },
    {
      question:
        "During which season of the year are pantomime productions typically staged in theaters?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Christmas", "Easter", "Summer", "Valentine's day"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Many theatres produce a pantomime at Christmas time.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Most people live in the countryside in the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most people live in towns and cities but much of Britain is still countryside. Many people continue to visit the countryside for holidays and for leisure activities such as walking, camping and fishing.",
      point: "1",
    },
    {
      question: "Who are TWO renowned British film directors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Evelyn Waugh",
        "Ridley Scott",
        "Sir Alfred Hitchcock",
        "Thomas Gainsborough",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British studios flourished in the 1930s. Eminent directors included Sir Alexander Korda and Sir Alfred Hitchcock, who later left for Hollywood and remained an important film director until his death in 1980. During the Second World War, British movies (for example, In Which We Serve) played an important part in boosting morale. Later, British directors including Sir David Lean and Ridley Scott found great success both in the UK and internationally.",
      point: "1",
    },
    {
      question:
        "For which TWO literary genres is William Shakespeare renowned?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Biographies", "Novels", "Plays", "Sonnets"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "As well as plays, Shakespeare wrote many sonnets (poems which must be 14 lines long) and some longer poems. As Protestant ideas spread, a number of poets wrote poems inspired by their religious views.",
      point: "1",
    },
    {
      question:
        "What if a Member of Parliament (MP) dies or resigns, what is the election called held to replace them?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["By-election", "Hustings", "Re-selection", "Selection"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If an MP dies or resigns, there will be a fresh election, called a by-election, in his or her constituency. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No member of the public is allowed to attend debates in the House of Commons.",
        "The public can attend debates in the House of Commons.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The public can listen to debates in the Palace of Westminster from public galleries in both the House of Commons and the House of Lords. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK has never been a member of NATO.",
        "The UK is a member of NATO",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The UK is also a member of NATO. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?You can serve on a jury up to the age of 80.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Anyone who is on the electoral register and is aged 18 to 70 can be asked to do this. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All acts of Parliament are made in the Prime Minister's name.",
        "All acts of Parliament are made in the monarch's name.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "All Acts of Parliament are made in her name. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Proceeding in Parliament cannot be reported in the press",
        "Proceedings in Parliament are broadcast on television",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Proceedings in Parliament are broadcast on television and published in official reports called Hansard.",
      point: "1",
    },
    {
      question:
        "What consequences from the police can result from causing trouble while drinking in public?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["A fine", "An award", "Arrest", "Praise"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of criminal laws are:Drinking in public: some places have alcohol-free zones where you cannot drink in public. The police can also confiscate alcohol or move young people on from public places. You can be fined or arrested.",
      point: "1",
    },
    {
      question: "Which TWO responsibilities should you honor as a UK resident?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Respect and obey the law",
        "Take in and look after stray animals",
        "Treat others with fairness",
        "Vote for the government in power",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you wish to be a permanent resident or citizen of the UK, you should:respect and obey the law,respect the rights of others, including their right to their own opinions,treat others with fairness,look after yourself and your family,look after the area in which you live and the environment.",
      point: "1",
    },
    {
      question:
        "In which TWO locations are the arrangements different for taking the Life in the UK Test?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Channel Island", " Ireland", "Isle of Man", "Scotland"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you live on the Isle of Man or in the Channel Islands, there are different arrangements for taking the Life in the UK test.",
      point: "1",
    },
    {
      question: "In British history, what does D-Day signify?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Battle of Trafalgar",
        "British invasion of Europe in 1994",
        "End of the war in Europe in 1945",
        "On 6 June 1944, allied forces landed in Normandy",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "On 6 June 1944, allied forces landed in Normandy (this event is often referred to as 'D-Day').",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Battle of Agincourt is commemorated in the Bayeux Tapestry",
        "The Battle of Hastings is commemorated in the Bayeux Tapestry",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The battle is commemorated in a great piece of embroidery, known as the Bayeux Tapestry, which can still be seen in France today.",
      point: "1",
    },
    {
      question:
        "Why were more castles constructed in Britain and Ireland during the Middle Ages?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "They created work for tradesmen",
        "They were cheap to build",
        "They were defensive strongholds",
        "They were status symbols for the nobility",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Middle Ages also saw a change in the type of buildings in Britain. Castles were built in many places in Britain and Ireland, partly for defence.",
      point: "1",
    },
    {
      question:
        "Some people in Britain were opposed to the slave trade. Who set up the first formal anti-slavery groups in the late 1700s?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Methodists", "Mormons", "Puritans", "Quakers"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were, however, people in Britain who opposed the stave trade. The first formal anti-slavery groups were set up by the Quakers in the late 1700s, and they petitioned Parliament to ban the practice.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?A public vote in 2002 decided that Winston Churchill was the Greatest Briton of all time.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He remains a much-admired figure to this day, and in 2002 was voted the greatest Briton of all time by the public.",
      point: "1",
    },
    {
      question: "Who is the patron saint of Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["St Andrews", "St David", "St George", "St Patrick"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England, Scotland, Wales and Northern Ireland each have a national saint, called a patron saint. Each saint has a special day:1 March: St David's Day, Wales ;17 March: St Patrick's Day, Northern Ireland ;23 April. St George's Day, England ;30 November: St Andrew's Day, Scotland.",
      point: "1",
    },
    {
      question: "Which TWO are Christian groups?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Baptists", "Buddhists", "Roman Catholics", "Sikhs"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Other Protestant Christian groups in the UK are Baptists, Methodists, Presbyterians and Quakers. There are also other denominations of Christianity, the biggest of which is Roman Catholic.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?In the UK a citizen may only follow an approved religion.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, everyone has the legal right to choose their religion, or to choose not to practice a religion.",
      point: "1",
    },
    {
      question: "Which TWO plants are commonly associated with the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Cactus", "Olive tree", "Rose", "Shamrock"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "England - the rose ; Northern Ireland - the shamrock",
      point: "1",
    },
    {
      question:
        "Ian McEwan, Hilary Mantel, and Julian Barnes have all been recipients of which literary prize?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Man Booker Prize",
        "The Mercury Prize",
        "The Nobel Prize in Literature",
        "The Turner Prize",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Man Booker Prize for Fiction is awarded annually for the best fiction novel written by an author from the Commonwealth, Ireland or Zimbabwe. It has been awarded since 1968. Past winners include Ian McEwan, Hilary Mantel and Julian Barnes.",
      point: "1",
    },
    {
      question:
        "Where would the Children's Hearings System handle cases of children or young people accused of an offense?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland a system called the Children's Hearings System is used to deal with children and young people who have committed an offence. ",
      point: "1",
    },
    {
      question: "Which TWO of the following individuals have voting rights?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Adults who are naturalised citizens of the UK",
        "Adults who have been given a visa to study in the UK",
        "Adults who have been granted an indefinite right to remain in the UK.",
        "UK-born adults citizens",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has had a fully democratic voting system since 1928 (see pages 119-20). The present voting age of 18 was set in 1969 and (with a few exceptions) all UK-born and naturalised adult citizens have the right to vote.",
      point: "1",
    },
    {
      question:
        "Which TWO services are sustained by National Insurance Contributions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Local taxi services",
        "National Health Service (NHS)",
        "State retirement pension",
        "Supermarket home deliveries",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The money raised from National Insurance Contributions is used to pay for state benefits and services such as the state retirement pension and the National Health Service (NHS). ",
      point: "1",
    },
    {
      question:
        "Which TWO are responsibilities of Members of Parliament (MPs)?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Representing everyone in their constituency",
        "Representing only those who voted for them",
        "Scruitanising and commenting on what the government is doing",
        "Supporting the government on all decisions and laws",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs have a number of different responsibilities. Theyrepresent everyone in their constituency ;help to create new laws;scrutinise and comment on what the government is doing ;debate important national issues.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "County Courts deal with civil disputes.",
        "County Courts deal with criminal cases.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "County Courts deal with a wide range of civil disputes. ",
      point: "1",
    },
    {
      question: "To which TWO international bodies does the UK belong?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The Arab League",
        "The Commonwealth",
        "The North American Free Trade Agreement (NAFTA)",
        "The North Atlantic Treaty Organization (NATO)",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development. Most member states were once part of the British Empire, although a few countries which were not have also joined. ; The UK is also a member of NATO. NATO is a group of European and North American countries that have agreed to help each other if they come under attack.",
      point: "1",
    },
    {
      question: "What must you have done in order to vote in elections?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Be politically neutral",
        "Be rude and abusive",
        "Make a FALSE? statement",
        "Obey the law",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Police officers must themselves obey the law. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Decisions on government policies are made by the Prime Minister and cabinet.",
        "Decisions on government policies are made by the monarch.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister appoints about 20 senior MPs to become ministers in charge of departments. These include:Chancellor of the Exchequer - responsible for the economy ;Home Secretary - responsible for crime, policing and immigration ;Foreign Secretary - responsible for managing relationships with foreign countries ;",
      point: "1",
    },
    {
      question: "Which TWO of the following are taxable?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Income from property, savings and dividends",
        "Profits from self-employment",
        "Shopping vouchers given to you by family or friends",
        "Small amounts of money given to you as a gift",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People in the UK have to pay tax on their income, which includes:wages from paid employment ; profits from self-employment ;taxable benefits ;pensions ;income from property, savings and dividends.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?In 1588 the English fleet defeated a large French fleet of ships that intended to land an army in England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth became one of the most popular monarchs in English history, particularly after 1588, when the English defeated the Spanish Armada (a large fleet of ships), which had been sent by Spain to conquer England and restore Catholicism.",
      point: "1",
    },
    {
      question:
        "During the 'Great Depression' of the 1930s, which TWO major industries emerged?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Automobile", "Aviation", "Coal mining", "Shipbuilding"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The traditional heavy industries such as shipbuilding were badly affected but new industries - including the automobile and aviation industries - developed.",
      point: "1",
    },
    {
      question:
        "Dylan Thomas, the renowned writer and poet, hailed from which country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", " Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: " Dylan Thomas was a Welsh poet and writer.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?During the Great Depression of the 1930s the UK had high levels of employment.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, in 1929, the world entered the 'Great Depression' and some parts of the UK suffered mass unemployment.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Catherine Howard was the sixth wife of Henry VIII",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine Howard - Catherine was a cousin of Anne Boleyn. She was also accused of taking lovers and executed.",
      point: "1",
    },
    {
      question: "Which of the following statements is NOT correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " D-Day was allied operation that attacked German forces in France by advancing through Spain.",
        "The attack on Normandy by Allied forces is often called D-Day.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "On 6 June 1944, allied forces landed in Normandy (this event is often referred to as 'D-Day'). Following victory on the beaches of Normandy, the allied forces pressed on through France and eventually into Germany. The Allies comprehensively defeated Germany in May 1945.",
      point: "1",
    },
    {
      question: "When was the first Union Flag created?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1506", "1606", "1609", "1706"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Welsh dragon does not appear on the Union Flag because, when the first Union Flag was created in 1606 from the flags of Scotland and England, the Principality of Wales was already united with England.",
      point: "1",
    },
    {
      question:
        "The Industrial Revolution saw the rapid growth of which of the following in the 18th and 19th centuries?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Agriculture", "Democracy", "Industry", " Population"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question: "Sir Chris Hoy is a famous sportsman in which event or sport?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Athletics", "Cycling", "Ice Skating", "Swimming"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Chris Hoy (1976-) is a Scottish cyclist who has won six gold and one silver Olympic medals. He has also won 11 world championship titles.",
      point: "1",
    },
    {
      question: "Which TWO are famous British composers?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Claude Debussy",
        "Henry Purcell",
        "Johann Sebastian Bach",
        "Ralph Vaughan Williams",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Ralph Vaughan Williams (1872-1958) wrote music for orchestras and choirs. He was strongly influenced by traditional English folk music. Classical music has been popular in the UK for many centuries. ; Henry Purcell (1659-95)was the organist at Westminster Abbey.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The UK has a declining elderly population.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People in the UK are living longer than ever before. This is due to improved living standards and better health care. There are now a record number of people aged 85 and over. This has an impact on the cost of pensions and health care.",
      point: "1",
    },
    {
      question:
        "In which areas does the National Assembly of Wales not legislate?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Economic policy",
        "Education and training",
        "Health and social services",
        "Housing",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Assembly has the power to make laws for Wales in 20 areas, including:education and training ;health and social services ;economic development ;housing",
      point: "1",
    },
    {
      question: "What is a jury made up of?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "People randomly chosen from the electoral register",
        "People who are members of political parties",
        "People who have submitted an application form and been accepted.",
        "People working in high-power jobs",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "As well as getting the right to vote, people on the electoral register are randomly selected to serve on a jury. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The civil service is politically neutral.",
        "The civil service largely consists of political appointees.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are chosen on merit and are politically neutral - they are not political appointees. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? You must always tell a canvasser how you intend to vote.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Political parties are particularly busy at election times. Members work hard to persuade people to vote for their candidates - for instance, by handing out leaflets in the street or by knocking on people's doors and asking for their support. This is called 'canvassing'. You don't have to tell a canvasser how you intend to vote if you don't want to.",
      point: "1",
    },
    {
      question: "What did hereditary peers lose in 1999?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The automatic right to sit in the House of Lords",
        "The right to elect other peers to the House of Lords",
        " The right to own land",
        "The right to speak in Parliament",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Since 1999, hereditary peers have lost the automatic right to attend the House of Lords. They now elect a few of their number to represent them in the House of Lords. ",
      point: "1",
    },
    {
      question: "Income tax is not levied on which form of income?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All of the above",
        " Benefits",
        "Money you win on the lottery",
        " Pension",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People in the UK have to pay tax on their income, which includes:wages from paid employment ;profits from self-employment ;taxable benefits ;pensions ;income from property, savings and dividends.",
      point: "1",
    },
    {
      question:
        "Someone wishing to stand as a candidate for election as an MP would need to be how old?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "21", "There is no minimum age limit"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Anyone aged 18 or over can stand for election as an MP but they are unlikely to win unless they have been nominated to represent one of the major political parties",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Council of Europe holds legislative authority binding in member states.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Council of Europe is separate from the EU. It has 47 member countries, including the UK, and is responsible for the protection and promotion of human rights in those countries. It has no power to make laws but draws up conventions and charters, the most well-known of which is the European Convention on Human Rights and Fundamental Freedoms, usually called the European Convention on Human Rights.",
      point: "1",
    },
    {
      question:
        "In 1776, what did the 13 colonies declare amid fighting with British forces in North America?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["An alliance", "Defeat", "Independence", "Surrender"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Florence Nightingale is associated with policing.",
        " Florence Nightingale is associated with the development of nursing.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1860 she established the Nightingale Training School for nurses at St Thomas' Hospital in London.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? During the 18th century, radical new ideas about politics, philosophy and science were developed, called 'the Enlightenment'.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the 18th century, new ideas about politics, philosophy and science were developed. This is often called 'the Enlightenment'.",
      point: "1",
    },
    {
      question:
        "Which TWO factions contended in the Wars of the Roses during the 15th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["House of Lancaster", "House of York", "Irish", "Scottish"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1455, a civil war was begun to decide who should be king of England. It was fought between the supporters of two families: the House of Lancaster and the House of York.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Lands taken from Catholics in 17th- century Ireland and given to Protestant settlers were known as 'plantations'.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the reigns of Elizabeth I and James I, many people in Ireland opposed rule by the Protestant government in England. There were a number of rebellions. The English government encouraged Scottish and English Protestants to settle in Ulster, the northern province of Ireland, taking over the land from Catholic landholders. These settlements were known as plantations.",
      point: "1",
    },
    {
      question: "In 1649, England was declared a republic. What was its name?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "European wealth",
        "Great Britain",
        "The Commonwealth",
        "The People's Republic",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The king's army was defeated at the Battles of Marston Moore and Naseby. By 1646, it was clear that Parliament had won the war. Charles was held prisoner by the parliamentary army. He was still unwilling to reach any agreement with Parliament and in 1649 he was executed. England declared itself a republic, called the Commonwealth. It no longer had a monarch.",
      point: "1",
    },
    {
      question:
        "Which Catherine was executed on accusations of taking lovers by Henry VIII?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Boleyn", "Howard", "Of Aragon", "Parr"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine Howard - Catherine was a cousin of Anne Boleyn. She was also accused of taking lovers and executed.",
      point: "1",
    },
    {
      question: "Richard Arkwright used steam engines to do what?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All of the above",
        "Create electricity",
        "Power machinery",
        "Pull carriages",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Later, he used the steam engine to power machinery. Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question: "Which of these statements is NOT true?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Victoria reign, the middle classes became increasingly significant",
        "During Queen Victoria's reign, the size and influence of the British middle class shrank",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "n 1837, Queen Victoria became queen of the UK at the age of 18. She reigned until 1901, almost 64 years. At the date of writing (2013) this is the longest reign of any British monarch. Her reign is known as the Victorian Age. It was a time when Britain increased in power and influence abroad. Within the UK, the middle classes became increasingly significant and a number of reformers led moves to improve conditions of life for the poor.",
      point: "1",
    },
    {
      question: "What was the Beveridge Report of 1942 about?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Dates",
        "Names of Iron Age kings",
        "Names of Iron Age settlements",
        "Value",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The people of the Iron Age had a sophisticated culture and economy. They made the first coins to be minted in Britain, some inscribed with the names of Iron Age kings.",
      point: "1",
    },
    {
      question:
        "When England became a republic after Charles I lost the English Civil War, what did it no longer have?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Monarch", "National anthem", "Parliament", "Prime Minister"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England declared itself a republic, called the Commonwealth. It no longer had a monarch.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The whole British Empire was involved in the First World War, with troops from India, the West Indies, Africa, and Australia all fighting on behalf of the British.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The whole of the British Empire was involved in the conflict - for example, more than a million Indians fought on behalf of Britain in lots of different countries, and around 40,000 were killed. Men from the West Indies, Africa, Australia, New Zealand and Canada also fought with the British.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Henry VIII married four times.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry VIII was most famous for breaking away from the Church of Rome and marrying six times.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In Wales and Northern Ireland, the established Churches are respectively the Church of Wales and the Church of Ulster.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is no established Church in Wales or Northern Ireland.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Brit Awards is an event where sports people are given awards.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Brit Awards is an annual event that gives awards in a range of categories, such as best British group and best British solo artist.",
      point: "1",
    },
    {
      question: "TRUE or FALSE? An Ulster fry is a traditional Welsh dish.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Northern Ireland: Ulster fry - a fried meal with bacon, eggs, sausage, black pudding, white pudding, tomatoes, mushrooms, soda bread and potato bread.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK's constitution is a single document that includes laws and conventions",
        "The UK's constitution is unwritten but includes laws and conventions",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A constitution is a set of principles by which a country is governed. It includes all of the institutions that are responsible for running the country and how their power is kept in check. The constitution also includes laws and conventions.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Local election are normally held in March",
        " Local elections are normally held in May",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "For most local authorities, local elections for councillors are held in May every year. ",
      point: "1",
    },
    {
      question: "How many permanent members does the UN Security Council have?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["5", "15", "27", "90"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are 15 members on the UN Security Council, which recommends action when there are international crises and threats to peace. The UK is one of five permanent members of the Security Council.",
      point: "1",
    },
    {
      question: "What do MEPs stand for?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Members of the English Parliament",
        "Members of the European Parliament",
        "Modern English parliamentarians",
        "Modern European parliamentarians",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elections for the European Parliament are also held every five years. Elected members are called members of the European Parliament (MEPs).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The National Assembly for Wales and the Welsh government are based in Cardiff.",
        "The National Assembly for Wales and the Welsh government are based in Edinburgh.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Welsh government and National Assembly for Wales are based in Cardiff, the capital city of Wales. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The jet engine and radar were developed in Britain in the 1830s.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer. Radar was developed by Scotsman Sir Robert Watson-Watt (1892-1973), who proposed that enemy aircraft could be detected by radio waves. The first successful radar test took place in 1935.",
      point: "1",
    },
    {
      question:
        "In the 17th century, where did many people from Britain and Ireland migrate to settle?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Africa", "America", "France", "London"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This was a time when many people left Britain and Ireland to settle in new colonies in America and elsewhere, but others came to live in Britain.",
      point: "1",
    },
    {
      question:
        "King Edward I of England annexed Wales to the crown of England by which statute?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Statute of Caernarfon",
        "The Statute of Carmarthen",
        "The Statute of Gwynedd",
        " The Statute of Rhuddlan",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1284 King Edward I of England introduced the Statute of Rhuddlan, which annexed Wales to the Crown of England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Anglo-Saxon kingdoms in England were united under King Alfred the Great.",
        "The Anglo-Saxon kingdoms were united under King Kenneth MacAlpin.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Anglo-Saxon kingdoms in England united under King Alfred the Great, who defeated the Vikings.",
      point: "1",
    },
    {
      question:
        "Which group's attempt to put James II's son on the throne instead of George I was swiftly defeated?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "English Puritans",
        "French Huguenots",
        " Irish Catholics",
        "Scottish Jacobites",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When Queen Anne died in 1714, Parliament chose a German, George I, to be the next king, because he was Anne's nearest Protestant relative. An attempt by Scottish Jacobites to put James II's son on the throne instead was quickly defeated.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the 16th century, the Scottish Parliament Abolished the authority of the Pope and Roman Catholic religious services became illegal.",
        "During the 16th century, the Scottish Parliament welcomed the authority of the Pope and Roman Catholics religious services became commonplace.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Scotland had also been strongly influenced by Protestant ideas. In 1560, the predominantly Protestant Scottish Parliament abolished the authority of the Pope in Scotland and Roman Catholic religious services became illegal.",
      point: "1",
    },
    {
      question: "Which was a co-discoverer of insulin?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "John Logie Baird",
        "John MacLeod",
        "Sir Frank Whittle",
        "Sir Robert Watson-Watt",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Scottish physician and researcher John Macleod (1876¬-1935) was the co-discoverer of insulin, used to treat diabetes.",
      point: "1",
    },
    {
      question:
        "Normandy, from where William of Normandy invaded England in 1066, is located where?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Denmark", "Northern France", "Norway", "Southern Scotland"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "National parks are areas of protected countryside that everyone can visit.",
        "National parks are national sports grounds for people to hold sporting events.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are areas of protected countryside that everyone can visit, and where people live, work and look after the landscape.",
      point: "1",
    },
    {
      question: "Which TWO individuals are renowned UK sports figures?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Dame Kelly Holmes",
        "Jane Austen",
        "Lucien Freud",
        "Sir Chris Hoy",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Chris Hoy (1976-) is a Scottish cyclist who has won six gold and one silver Olympic medals. He has also won 11 world championship titles. ; Dame Kelly Holmes (1970-) won two gold medals for running in the 2004 Olympic Games. She has held a number of British and European records.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Cricket matches can last up to five days.",
        "Cricket matches can last up to two weeks.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Cricket originated in England and is now played in many countries. Games can last up to five days but still result in a draw!",
      point: "1",
    },
    {
      question:
        "George Frederick Handel, a German-born composer who naturalized as a British citizen, composed which works?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Balthazar's Feast",
        "Music for the Royal Fireworks",
        "Peter Grimes",
        "The Planets",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The German-born composer George Frederick Handel (1685-1759) spent many years in the UK and became a British citizen in 1727. He wrote the Water Music for King George I and Music for the Royal Fireworks for his son, George II. Both these pieces continue to be very popular.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Some people rent land away from home called an allotment, where they can grow fruit and vegetables.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A lot of people have gardens at home and will spend their free time looking after them. Some people rent additional land called 'an allotment', where they grow fruit and vegetables.",
      point: "1",
    },
    {
      question: "Which film was directed by Alfred Hitchcock?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 1,001 Steps",
        "The 100 Steps",
        "The 21 Steps",
        "The 39 Steps",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The 39 Steps 119351, directed by Alfred Hitchcock",
      point: "1",
    },
    {
      question: "What event is commemorated on 5 November every year?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A plot to blow up the Houses of Parliament",
        "England's Worlds Cup victory of 1966",
        "The Queen's birthday",
        "The end of the First World War",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Bonfire Night, 5 November, is an occasion when people in Great Britain set off fireworks at home or in special displays. The origin of this celebration was an event in 1605, when a group of Catholics led by Guy Fawkes failed in their plan to kill the Protestant king with a bomb in the Houses of Parliament.",
      point: "1",
    },
    {
      question: "Who or what were jesters?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Combatants on horseback at medieval feasts",
        "Men who organised hunts in the Middle Ages",
        "People who told jokes at medieval royal courts",
        "Silver coins of the 18th century",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Medieval kings and rich nobles had jesters who told jokes and made fun of people in the Court.",
      point: "1",
    },
    {
      question:
        "The heptathlon comprises how many different track and field events?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["5", "6", "7", "8"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jessica Ennis (1980-)is an athlete. She won the 2012 Olympic gold medal in the heptathlon, which includes seven different track and field events. She also holds a number of British athletics records.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Chequers is the Prime Minister's country house.",
        "Chequers is the Prime Minister's house in London.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister (PM) is the leader of the political party in power. He or she appoints the members of the cabinet (see below) and has control over many important public appointments. The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. He or she also has a country house outside London called Chequers.",
      point: "1",
    },
    {
      question: "How frequently are Members of Parliament (MPs) elected?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "At least every five years",
        "At least every three years",
        "Every six months",
        "Every year",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected at a General Election, which is held at least every five years. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the UK, employers can discriminate against different groups of people.",
        "UK laws ensure people are not treated unfairly in any area of work or life.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "UK laws ensure that people are not treated unfairly in any area of life or work because of their age, disability, sex, pregnancy and maternity, race, religion or belief, sexuality or marital status. ",
      point: "1",
    },
    {
      question: "What is the minimum legal age you can buy alcohol in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["17", "18", "21", "25"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " In the UK, you must be at least 17 years old to drive a car or motor cycle and you must have a driving licence to drive on public roads. ",
      point: "1",
    },
    {
      question:
        "Which constituency does every Member of Parliament (MP) represent?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["A city", "A constituency", "A country", "None of the above"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Each MP represents a parliamentary constituency, which is a small area of the country.",
      point: "1",
    },
    {
      question:
        "What TWO things do you need to apply to become a permanent resident or citizen of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A UK bank account",
        "A driving licence",
        "A good understanding of life in the UK",
        "An ability to speak and read English",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To apply to become a permanent resident or citizen of the UK, you will need to:speak and read English, have a good understanding of life in the UK.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? During the Victorian period the British Empire became the largest empire the world has ever seen.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the Victorian period, the British Empire grew to cover all of India, Australia and large parts of Africa. It became the largest empire the world has ever seen, with an estimated population of more than 400 million people.",
      point: "1",
    },
    {
      question: "Which TWO genres is William Shakespeare renowned for writing?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Plays", "Poems", "Radio scripts", "TV dramas"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Shakespeare was born in Stratford-upon-Avon, England. He was a playwright and actor and wrote many poems and plays.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Industrial Revolution, canals were built to link the factories to cities and ports.",
        " During the industrial Revolution, canals were built to link the mines in the mountains with factories in the cities.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Better transport links were needed to transport raw materials and manufactured goods. Canals were built to link the factories to towns and cities and to the ports, particularly in the new industrial areas in the middle and north of England.",
      point: "1",
    },
    {
      question: "What was the Spanish Armada?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Agriculture",
        " Canal building",
        " Financial services",
        "The wool trade",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Before the 18th century, agriculture was the biggest source of employment in Britain. There were many cottage industries, where people worked from home to produce goods such as cloth and lace.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Canterbury Tales was one of the first books to be printed by William Caxton",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This collection of poems is called The Canterbury Tales. It was one of the first books to be printed by William Caxton, the first person in England to print books using a printing press.",
      point: "1",
    },
    {
      question: "Who bequeathed the throne to her Protestant son, James VI?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Bloody Mary",
        "Elizabeth I",
        "Mary Tudor",
        "Mary, Queen of Scots",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was suspected of involvement and fled to England. She gave her throne to her Protestant son, James VI of Scotland.",
      point: "1",
    },
    {
      question:
        "In the First World War, the British fought alongside countries such as Germany, the Ottoman Empire, and the Austro-Hungarian Empire in what alliance?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Autocratic Power",
        " The Central Powers",
        "The Germanic Powers",
        "The Middle Powers",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Allies fought against the Central Powers - mainly Germany, the Austro-Hungarian Empire, the Ottoman Empire and later Bulgaria.",
      point: "1",
    },
    {
      question:
        "Pumpkins, lit with candles, are used to celebrate which tradition?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bonfire Night", "Halloween", "Mayday", "Midsummer"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Halloween, 31 October, is an ancient festival and has roots in the pagan festival to mark the beginning of winter. Young people will often dress up in frightening costumes to play 'trick or treat'. People give them treats to stop them playing tricks on them. A lot of people carve lanterns out of pumpkins and put a candle inside.",
      point: "1",
    },
    {
      question: "Which TWO are renowned British fashion designers?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Capability Brown",
        "Edwin Lutyens",
        "Mary Quant",
        "Vivienne Westwood",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Leading fashion designers of recent years include Mary Quant, Alexander McQueen and Vivienne Westwood.",
      point: "1",
    },
    {
      question: "What are the titles of TWO novels by Charles Dickens?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Great Expectations",
        "Harry Potter",
        "Oliver Twist",
        "Pride and Prejudice",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles Dickens (1812-70) wrote a number of very famous novels, including Oliver Twist and Great Expectations. You will hear references in everyday talk to some of the characters in his books, such as Scrooge (a mean person) or Mr Micawber (always hopeful).",
      point: "1",
    },
    {
      question:
        'Which sport is associated with the phrase "rain stopped play"?',
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cricket", "Football", "Horse racing", "Rugby league"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You may come across expressions such as 'rain stopped play', 'batting on a sticky wicket', 'playing a straight bat', 'bowled a googly' or 'it's just not cricket', which have passed into everyday usage.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Violating a Forced Marriage Protection Order may lead to imprisonment.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "On average, girls leave school with better qualifications than boys.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Population growth in the UK has been faster in recent years, thanks in part to migration and longer life expectancy.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Population growth has been faster in more recent years. Migration into the UK and longer life expectancy have played a part in population growth.",
      point: "1",
    },
    {
      question:
        "Which organization, founded in 1895, now boasts over 61,000 volunteers dedicated to preserving important buildings, countryside, and coastline?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Country Landowners Association",
        "The Countryside Alliance",
        "The National Gardens Scheme",
        "The National Trust",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The National Trust was founded in 1895 by three volunteers. There are now more than 61,000 volunteers helping to keep the organisation running.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Big Ben is a novel written by Sir Kingsley Amis.",
        "Big Ben refers to the great bell of the clock at the Houses of Parliament.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the great bell of the clock at the Houses of Parliament in London. Many people call the clock Big Ben as well. The clock is over 150 years old and is a popular tourist attraction.",
      point: "1",
    },
    {
      question: "Who composed the piece of music, Balthazar's Feast?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Gustav Holst",
        "Henry Purcell",
        "Sir Edward Elgar",
        "Sir William Walton",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir William Walton (1902-83) wrote a wide range of music, from film scores to opera. He wrote marches for the coronations of King George VI and Queen Elizabeth II but his best-known works are probably Façade, which became a ballet, and Balthazar's Feast, which is intended to be sung by a large choir.",
      point: "1",
    },
    {
      question:
        "Following Protestants, what is the largest denomination of Christianity in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Christian Science",
        "Ethiopian Orthodox",
        "Jehovah's Witnesses",
        "Roman Catholics",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also other denominations of Christianity, the biggest of which is Roman Catholic.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following statements describe the purpose of the UN Security Council?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "To make international law",
        "To promote human rights",
        "To recommend action during an international crisis",
        "To recommend action where is a threat to peace",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are 15 members on the UN Security Council, which recommends action when there are international crises and threats to peace. ",
      point: "1",
    },
    {
      question: "How are local authorities funded?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By central government funding and by taxation",
        " By funding from central government only",
        "By taxation only",
        "Local authorities are unfunded",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Towns, cities and rural areas in the UK are governed by democratically elected councils, often called 'local authorities'. Some areas have both district and county councils, which have different functions. Most large towns and cities have a single local authority. Local authorities provide a range of services in their areas. They are funded by money from central government and by local taxes.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? All Acts of Parliament are enacted in the name of the Prime Minister.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The King has important ceremonial roles, such as the opening of the new parliamentary session each year. On this occasion the Queen makes a speech which summarises the government's policies for the year ahead. All Acts of Parliament are made in her name.",
      point: "1",
    },
    {
      question: "How many jurors serve on a jury in England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["10", "12", "15", "20"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland a jury has 12 members, and in Scotland a jury has 15 members.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Participating in your community is a fundamental principle of British life.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The fundamental principles of British life include:Democracy,The rule of law,Individual liberty,Tolerance of those with different faiths and beliefs,Participation in community life.\n",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Channel Islands and the Isle of Man are part of the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several islands which are closely linked with the UK but are not part of it: the Channel Islands and the Isle of Man.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? There is a dragon on the official flag of Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is also an official Welsh flag, which shows a Welsh dragon. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The 'Restoration' refers to the re-establishment of Catholicism as the official Church in the 17th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland. Charles II made it clear that he had 'no wish to go on his travels again'. He understood that he could not always do as he wished but would sometimes need to reach agreement with Parliament. Generally, Parliament supported his policies. The Church of England again became the established official Church. Both Roman Catholics and Puritans were kept out of power.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following were inventions originating from Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Diesel engine",
        "Jet engine",
        "Personal computer",
        "Television",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The television was developed by Scotsman John Logie Baird (1888-1946) in the 1920s. In 1932 he made the first television broadcast between London and Glasgow. The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer.",
      point: "1",
    },
    {
      question: "When did Julius Caesar lead the Roman invasion of Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "It abolished rotten boroughs",
        "It decreased the power of the monarch.",
        "It gave women the vote",
        "It increased the number of people who could vote.",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote. The Act also abolished the old pocket and rotten boroughs.",
      point: "1",
    },
    {
      question:
        'Which famous leader delivered the speech containing the line: "We shall fight on the beaches..."?',
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Admiral Nelson",
        "Clement Attlee",
        "Oliver Cromwell",
        "Winston Churchill",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Churchill was the son of a politician and, before becoming a Conservative MP in 1900, was a soldier and journalist. In May 1940 he became Prime Minister. During the War, he made many famous speeches including lines which you may still hear: I have nothing to offer but blood, toil, tears and sweat' Churchill's first speech to the House of Commons after he became Prime Minister, 1940; 'We shall fight on the beaches, we shall fight on the landing grounds, we shall fight in the fields and in the streets, we shall fight in the hills; we shall never surrender' Speech to the House of Commons after Dunkirk (see below), 1940",
      point: "1",
    },
    {
      question: "The first farmers probably came to Britain from where?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nor-west Europe",
        "North America",
        "Norway",
        " South-east Europe",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first farmers arrived in Britain 6,000 years ago. The ancestors of these first farmers probably came from south-east Europe.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? A Formula 1 Grand Prix race takes place in Britain annually.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " A Formula 1 Grand Prix event is held in the UK each year and a number of British Grand Prix drivers have won the Formula 1 World Championship.",
      point: "1",
    },
    {
      question: "What festival is celebrated on the 31st of October?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bonfire Night", "Halloween", "Hogmanay", "Valentine's Day"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Halloween, 31 October, is an ancient festival and has roots in the pagan festival to mark the beginning of winter. Young people will often dress up in frightening costumes to play 'trick or treat'. People give them treats to stop them playing tricks on them. A lot of people carve lanterns out of pumpkins and put a candle inside.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Some people rent land called 'an allotment' where they grow fruit and vegetables.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Some people rent additional land called 'an allotment', where they grow fruit and vegetables.",
      point: "1",
    },
    {
      question:
        "What TWO freedoms are offered by the UK to citizens and permanent residents?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Conversing deep water fish",
        "Conversing native bird species",
        "Preserving important buildings and places",
        "Preserving old aircraft",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Both are charities that work to preserve important buildings, coastline and countryside in the UK.",
      point: "1",
    },
    {
      question:
        "In which modern-day country was composer George Frederick Handel born?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Germany", "Iceland", "Japan", "Russia"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The German-born composer George Frederick Handel (1685-1759) spent many years in the UK and became a British citizen in 1727.",
      point: "1",
    },
    {
      question: "Which TWO of the following celebrated Paralympians?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Baroness Tanni Grey-Thompson",
        "Dame Elle MacArthur",
        "Ellie Simmonds",
        "Jessica Ennis",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Ellie Simmonds (1994-) is a Paralympian who won gold medals for swimming at the 2008 and 2012 Paralympic Games and holds a number of world records. ; Baroness Tanni Grey-Thompson (1969-) is an athlete who uses a wheelchair and won 16 Paralympic medals, including 11 gold medals, in races over five Paralympic Games.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital city of Scotland is Edinburgh",
        "The capital city of Scotland is Glasgow",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Scotland ; The capital city of Scotland is Edinburgh",
      point: "1",
    },
    {
      question:
        "Which famous religious building has been the coronation church since 1066?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "St Paul's Cathedral",
        "Westminster Abbey",
        "Westminster Cathedral",
        "York Minster",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Westminster Abbey has been the coronation church since 1066 and is the final resting place of 17 monarchs.",
      point: "1",
    },
    {
      question: "Which of these is not a Christmas tradition in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Decorating houses and trees",
        "Giving gifts",
        "Having a barbeque",
        "Sending cards",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Christmas is celebrated in a traditional way. People usually spend the day at home and eat a special meal, which often includes roast turkey, Christmas pudding and mince pies. They give gifts, send cards and decorate their houses. Christmas is a special time for children. Very young children believe that Father Christmas (also known as Santa Claus) brings them presents during the night before Christmas Day. Many people decorate a tree in their home.",
      point: "1",
    },
    {
      question: "When were the first professional football clubs established?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["10th Century", "17th Century", "18th Century", "19th Century"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Football is the UK's most popular sport. It has a long history in the UK and the first professional football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question: "In which country does the national Eisteddfod take place?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The National Eisteddfod of Wales is an annual cultural festival which includes music, dance, art and original performances largely in Welsh. It includes a number of important competitions for Welsh poetry.",
      point: "1",
    },
    {
      question: "Sir Anthony Van Dyck was famous as which of the following?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Actor", "Composer", "Painter", "Sculptor"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many of the painters working in Britain in the 18th and 17th centuries were from abroad - for example, Hans Holbein and Sir Anthony Van Dyck. British artists, particularly those painting portraits and landscapes, became well known from the 18th century onwards.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Civil servants are politically neutral",
        "Civil servants have to be politically aligned to the elected government",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are chosen on merit and are politically neutral - they are not political appointees. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Bonnie Prince Charlie became King Charles II of England in 1660.",
        "Charles, king of Scotland, was restored as King Charles II of England in 1660.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland.",
      point: "1",
    },
    {
      question: "Why was the Habeas Corpus Act of 1679 significant?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It allowed people to bury the dead where they wished.",
        "It ended capital punishment in England.",
        "It ensured no person could be held unlawfully.",
        "It ensured that those who died could only be buried by a relative.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Habeas corpus is Latin for 'you must present the person in court'. The Act guaranteed that no one could be held prisoner unlawfully.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Swinging Sixties was a period of social change.",
        "The Swinging sixties was a period of religious change.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The decade of the 1960s was a period of significant social change. It was known as 'the Swinging Sixties'.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? King Henry Vlll created the Church of England when the pope refused to grant him a divorce.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To divorce his first wife, Henry needed the approval of the Pope. When the Pope refused, Henry established the Church of England.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1833 a law abolished slavery throughout the British Empire.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1807, it became illegal to trade slaves in British ships or from British ports, and in 1833 the Emancipation Act abolished slavery throughout the British Empire.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Great Depression of the 1930s, parts of the UK experienced mass unemployment.",
        "The UK experienced high levels of employment during the Great Depression of the 1930s.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1929, the world entered the 'Great Depression' and some parts of the UK suffered mass unemployment.",
      point: "1",
    },
    {
      question: "Who implemented the feudalism system in Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Anglo-Saxons", "Jutes", "Normans", "Vikings"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Normans used a system of land ownership known as feudalism. The king gave land to his lords in return for help in war.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution is the name given to the rapid development of industry in Britain in the 20th century.",
        "The Industrial Revolution is the name given to the rapid development of industry that began in the 18th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question:
        "Which TWO among the following are Christian religious festivals observed in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Christmas", "Easter", "Halloween", "New Year"],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Christmas Day, 25 December, celebrates the birth of Jesus Christ. It is a public holiday. Many Christians go to church on Christmas Eve (24 December) or on Christmas Day itself. Easter takes place in March or April. It marks the death of Jesus Christ on Good Friday and his rising from the dead on Easter Sunday. Both Good Friday and the following Monday, called Easter Monday, are public holidays.",
      point: "1",
    },
    {
      question: "Which TWO are iconic UK landmarks?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Grand Canyon", "Loch Lomond", "Notre Dame", "Snowdonia"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Snowdonia is a national park in North Wales. It covers an area of 838 square miles (2,170 square kilometres). Its most well-known landmark is Snowdon, which is the highest mountain in Wales. ; Loch Lomond and the Trossachs National Park - This national park covers 720 square miles (1,865 square kilometres) in the west of Scotland. Loch Lomond is the largest expanse of fresh water in mainland Britain and probably the best-known part of the park.",
      point: "1",
    },
    {
      question: "Which two are renowned British authors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Graham Greene", "Henry Moore", "Mary Quant", "Thomas Hardy"],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Thomas Hardy (1840-1928) was an author and poet. His best-known novels focus on rural society and include Far from the Madding Crowd and Jude the Obscure. ; Graham Greene (1904-91) wrote novels often influenced by his religious beliefs, including The Heart of the Matter, The Honorary Consul, Brighton Rock and Our Man in Havana.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Lake District is England's largest national park",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Lake District is England's largest national park.",
      point: "1",
    },
    {
      question: "Who authored Paradise Lost?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "John Milton",
        "Rider Haggard",
        "Salman Rushdie",
        "William Shakespeare",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "As Protestant ideas spread, a number of poets wrote poems inspired by their religious views. One of these was John Milton, who wrote Paradise Lost.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Volunteering is a good way to earn additional money.",
        "Volunteering is a way of helping others without receiving payment.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Volunteering is working for good causes without payment.",
      point: "1",
    },
    {
      question: "Which TWO are examples of Civil law?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Carrying a weapon",
        "Discrimination in the Workplace",
        "Disputes between landlords and tenants",
        "Selling tobacco",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of civil laws are:Housing law: this includes disputes between landlords and tenants over issues such as repairs and eviction. ; Consumer rights: an example of this is a dispute about faulty goods or services. ; Employment law: these cases include disputes over wages and cases of unfair dismissal or discrimination in the workplace. ; Debt: people might be taken to court if they owe money to someone.",
      point: "1",
    },
    {
      question: "How many individuals serve on a jury in Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["8", "11", "15", "20"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland a jury has 12 members, and in Scotland a jury has 15 members. ",
      point: "1",
    },
    {
      question: "Which areas does civil law cover?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Debt",
        "Drunk and disorderly behaviour",
        "Violent crime",
        "burglary",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of civil laws are:Debt: people might be taken to court if they owe money to someone.",
      point: "1",
    },
    {
      question:
        "Who arbitrates legal disputes concerning contracts, property rights, or employment rights?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["The Police", "The judiciary", "The media", "The peers"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Judges also make decisions in disputes between members of the public or organisations. These might be about contracts, property or employment rights or after an accident. ",
      point: "1",
    },
    {
      question: "In which areas can the Scottish Parliament enact laws?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["All of above", "Civil and criminal law", "Health", "Taxation"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The matters on which the Scottish Parliament can legislate include:civil and criminal law ; health ; education ; planning ; additional tax-raising powers",
      point: "1",
    },
    {
      question: "How often is Prime Minister's Questions held?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Every day",
        "Every day while parliament is in session",
        "Every month",
        "Every week while Parliament is in session",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The leader of the opposition leads his or her party in pointing out what they see as the government's failures and weaknesses. One important opportunity to do this is at Prime Minister's Questions, which takes place every week while Parliament is sitting.",
      point: "1",
    },
    {
      question:
        "In Northern Ireland, how are children who have committed an offense handled?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Youth Court",
        "A system called the Children's Hearings System",
        "A system of youth conferencing",
        "Crown Court",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Northern Ireland has a system of youth conferencing to consider how a child should be dealt with when they have committed an offence. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You do not need the help of a lawyer to issue a small claim",
        "You will need the help of a lawyer to issue a small claim.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question:
        "Which TWO actions can political parties take to garner support for their candidate?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Handling out leaflets",
        "Knocking on doors and talking",
        "Paying people to vote a certain way",
        "Threatening people to make them vote a certain way",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members work hard to persuade people to vote for their candidates - for instance, by handing out leaflets in the street or by knocking on people's doors and asking for their support. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1588 the English defeated a German invasion fleet of bomber planes.",
        "In 1588 the English defeated a Spanish invasion fleet of ships.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth became one of the most popular monarchs in English history, particularly after 1588, when the English defeated the Spanish Armada (a large fleet of ships), which had been sent by Spain to conquer England and restore Catholicism.",
      point: "1",
    },
    {
      question:
        "What was the name given to supporters of King Charles I during the Civil War?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Establishing a welfare state",
        "How to end the war in Europe.",
        "How to treat the Germans and Japanese after the war",
        "Overseas aid",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It recommended that the government should find ways of fighting the five 'Giant Evils' of Want, Disease, Ignorance, Squalor and Idleness and provided the basis of the modern welfare state.",
      point: "1",
    },
    {
      question:
        "In which year was the Northern Ireland Assembly reinstated after being suspended in 2002?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["2003", "2005", "2007", "2010"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Northern Ireland Assembly was elected in 1999 but suspended in 2002. It was not reinstated until 2007.",
      point: "1",
    },
    {
      question:
        "In the 20th century, what sector or industry did the UK become a global center for?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Agriculture", "Finance", "Mining", "Shipbuilding"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Deregulation saw a great increase in the role of the City of London as an international centre for investments, insurance and other financial services.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?During the Industrial Revolution, canals were built to link factories to cities, towns and ports, particularly in the middle and north of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Canals were built to link the factories to towns and cities and to the ports, particularly in the new industrial areas in the middle and north of England.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The language of the Iron Age was part of the Celtic language family.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The language they spoke was part of the Celtic language family.",
      point: "1",
    },
    {
      question:
        "What happened to the power of the nobility in England during Henry VII's reign?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It increased",
        "It was abolished",
        "It was absolute",
        "It was reduced",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After his victory in the Wars of the Roses, Henry VII wanted to make sure that England remained peaceful and that his position as king was secure. He deliberately strengthened the central administration of England and reduced the power of the nobles.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?In 1688, Protestant leaders in England asked William of Orange to invade England and proclaim himself king.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James II's elder daughter, Mary, was married to her cousin William of Orange, the Protestant ruler of the Netherlands. In 1688, important Protestants in England asked William to invade England and proclaim himself king.",
      point: "1",
    },
    {
      question: "Which TWO of the following are associated with football?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Premier League", "The Ashes", "The Open", "UEFA"],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many of the best players in the world play in the Premier League. Many UK teams also compete in competitions such as the UEFA (Union of European Football Associations) Champions League, against other teams from Europe.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Andy Murray is the first British man to sail around the world.",
        "Andy Murray is the first British man to win a singles tennis title in a Grand Slam tournament since 1936.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He is the first British man to win a singles title in a Grand Slam tournament since 1936. In the same year, he won Olympic gold and silver medals and was runner-up in the men's singles at Wimbledon.",
      point: "1",
    },
    {
      question: "What is the minimum age for jury service?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["£100", "£20", "£50", "£70"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The currency in the UK is the pound sterling (symbol GBP ). There are 100 pence in a pound. The denominations (values) of currency are:coins: 1p, 2p, 5p, 10p, 20p, 50p, £1 and £2 ; notes: £5, £10, £20, £50.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The United Nations was set up after the Second World War.",
        "The United Nations was set up between the First and Second World Wars.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UN was set up after the Second World War and aims to prevent war and promote international peace and security.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The small claims procedure helps people to make small home insurance claims.",
        "The small claims procedure is an informal way of helping people to settle minor disputes.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question:
        "What is the minimum age requirement to stand for public office?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "20", "21"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most citizens of the UK, the Irish Republic or the Commonwealth aged 18 or over can stand for public office. ",
      point: "1",
    },
    {
      question: "What is the UK's most popular sport?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Almighty is the King",
        "God Save The King",
        "Long live the Monarch",
        "Long live the King",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "God save our gracious King!, Long live our noble King!, God save the King!",
      point: "1",
    },
    {
      question: "How can individuals reduce their carbon footprint?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Buy duty-free products when your abroad",
        "Do all your shopping online",
        "Drive to the supermarket",
        "Shop locally for products",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A good way to support your local community is to shop for products locally where you can. This will help businesses and farmers in your area and in Britain. It will also reduce your carbon footprint, because the products you buy will not have had to travel as far. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All members of Parliament have to belong to a political party.",
        "There are few members of Parliament who do not represent any of the main political parties.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are a few MPs who do not represent any of the main political parties. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The United Kingdom consists of England, Ireland, Scotland and Wales.",
        "The United Kingdom consists of England, Scotland, Wales, and Northern Ireland",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is made up of England, Scotland, Wales and Northern Ireland. The rest of Ireland is an independent county.",
      point: "1",
    },
    {
      question: "What important event happened in England in 1066?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Battle of Bannockburn",
        "The Norman invasion",
        "The Romans left England",
        "The building of the Offa Dyke",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings. Harold was killed in the battle. William became king of England and is known as William the Conqueror.",
      point: "1",
    },
    {
      question:
        "Who was given the title of Lord Protector in the 17th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Isaac Newton",
        "King Charles II",
        "Oliver Cromwell",
        "Samuel Pepys",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After his campaign in Ireland and victory over Charles II at Worcester, Cromwell was recognised as the leader of the new republic. He was given the title of Lord Protector and ruled until his death in 1658.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By 1400 the preferred language of English court was English",
        "By 1400 the preferred language of English court was French",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, in England, official documents were being written in English, and English had become the preferred language of the royal court and Parliament.",
      point: "1",
    },
    {
      question: "Which country of the UK is not represented on the Union Flag?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Union Flag consists of three crosses:The cross of St George, patron saint of England, is a red cross on a white ground. ; The cross of St Andrew, patron saint of Scotland, is a diagonal white cross on a blue ground. ; The cross of St Patrick, patron saint of Ireland, is a diagonal red cross on a white ground.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?By around AD 600, Anglo-Saxon kingdoms were established in Britain.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Roman army left Britain in AD 410 to defend other parts of the Roman Empire and never returned. Britain was again invaded by tribes from northern Europe: the Jute's, the Angles and the Saxons. The languages they spoke are the basis of modern-day English. Battles were fought against these invaders but, by about AD 600, Anglo-Saxon kingdoms were established in Britain. These kingdoms were mainly in what is now England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "After the Black Death, there were labour shortages and peasants began to demand higher wages.",
        "The Black Death destroyed cereal crops, leading to a famine that killed many peasants.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Following the Black Death, the smaller population meant there was less need to grow cereal crops. There were labour shortages and peasants began to demand higher wages. New social classes appeared, including owners of large areas of land (later called the gentry), and people left the countryside to live in the towns. In the towns, growing wealth led to the development of a strong middle class.",
      point: "1",
    },
    {
      question: "Which TWO regions did the Vikings originate from?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Belgium", "Denmark", "France", "Norway"],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Vikings came from Denmark and Norway.",
      point: "1",
    },
    {
      question: "What sport is played in the UEFA Champions League?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Stories about battles and victories",
        "Stories about communities and farming",
        "Stories about kings and coronations",
        "Stories about the Bible and saints",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Several of the cathedrals had windows of stained glass, telling stories about the Bible and Christian saints. The glass in York Minister is a famous example.",
      point: "1",
    },
    {
      question: "What is the capital city of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Aberdeen", "Dundee", "Edinburgh", "Glasgow"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Scotland, The capital city of Scotland is Edinburgh",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "EastEnders and Coronation Street are historical landmarks.",
        "EastEnders and Coronation Street are popular television programmes.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Popular programmes include regular soap operas such as Coronation Street and EastEnders.",
      point: "1",
    },
    {
      question: "Which TWO are Protestant Christian groups in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Baptists", "Buddhist", "Methodists", "Roman Catholics"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Other Protestant Christian groups in the UK are Baptists, Methodists, Presbyterians and Quakers.",
      point: "1",
    },
    {
      question: "What is a bank holiday?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A holiday just for people working in banks.",
        "A public holiday when banks and other businesses close for the day.",
        "A week off for everyone in the UK.",
        "An extra holiday entitlement for working longer hours than usual.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "As well as those mentioned previously, there are other public holidays each year called bank holidays, when banks and many other businesses are closed for the day.",
      point: "1",
    },
    {
      question: "TRUE or FALSE? Catherine Howard was Henry VIII's sixth wife.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Cardiff, Swansea and Newport are cities in Wales.",
      point: "1",
    },
    {
      question:
        "The British equivalent of the Oscars is hosted by BAFTA. For what do these initials stand?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "British Academy of Film and Television Arts",
        "British Academy of Film and Theatre Awards",
        "British Association of Film and Technical Appliances",
        "British Awards for Film and Televisions Actors",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The annual British Academy Film Awards, hosted by the British Academy of Film and Television Arts (BAFTA), are the British equivalent of the Oscars.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Jane Austen and Charles Dickens were both famous sculptors.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Austen (1775-1817) was an English novelist. Her books include Pride and Prejudice and Sense and Sensibility. Her novels are concerned with marriage and family relationships. Many have been made into television programmes or films. ; Charles Dickens (1812-70) wrote a number of very famous novels, including Oliver Twist and Great Expectations. You will hear references in everyday talk to some of the characters in his books, such as Scrooge (a mean person) or Mr Micawber (always hopeful).",
      point: "1",
    },
    {
      question: "Which of these is not a Gilbert and Sullivan comic opera?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Midsummer Night's Dream",
        "HMS Pinafore",
        "The Mikado",
        "The Pirates o Penzance",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, Gilbert and Sullivan wrote comic operas, often making fun of popular culture and politics. These operas include HMS Pinafore, The Pirates of Penzance and The Mikado. Gilbert and Sullivan's work is still often staged by professional and amateur groups.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "People in the UK are living longer than ever before.",
        "The average lifespan for UK residents is steadily decreasing",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People in the UK are living longer than ever before. This is due to improved living standards and better health care. There are now a record number of people aged 85 and over. This has an impact on the cost of pensions and health care.",
      point: "1",
    },
    {
      question: "Who is mandated to pay National Insurance Contributions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All those aged 50 and below",
        "Everybody in the UK who is in paid work",
        "People who work full-time",
        "Single people with no dependents",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Almost everybody in the UK who is in paid work, including self-employed people, must pay National Insurance Contributions. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The candidate who wins the most votes is elected MP for the constituency.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected through a system called 'first past the post'. In each constituency, the candidate who gets the most votes is elected. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? If you are a commonwealth citizen living in the UK you can vote in all public elections.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Adult citizens of the UK, and citizens of the Commonwealth and the Irish Republic who are resident in the UK, can vote in all public elections. ",
      point: "1",
    },
    {
      question:
        "What age group does the National Citizen Service programme cover?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "16- and 17-year-old",
        "18-to 30-year-olds",
        "All children up to the age of 17",
        "Pensioners",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "These include the National Citizen Service programme, which gives 16- and 17-year-olds the opportunity to enjoy outdoor activities, develop their skills and take part in a community project. ",
      point: "1",
    },
    {
      question: "Can the leader of the opposition become Prime Minister?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Yes, if his or her party wins a General Election",
        "Yes, if the Prime Minister resigns",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The second-largest party in the House of Commons is called the opposition. The leader of the opposition usually becomes Prime Minister if his or her party wins the next General Election. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Channel Islands are a part of the UK.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several islands which are closely linked with the UK but are not part of it: the Channel Islands and the Isle of Man.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Mary, Queen of Scots was a cousin of Queen Elizabeth I.",
        "Mary, Queen of Scots was unrelated to Queen Elizabeth I.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was Elizabeth I's cousin and hoped that Elizabeth might help her, but Elizabeth suspected Mary of wanting to take over the English throne, and kept her a prisoner for 20 years.",
      point: "1",
    },
    {
      question: "Which language was spoken by people during the Iron Age?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Anglo-Saxon", "Celtic", "English", "Latin"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most people were farmers, craft workers or warriors. The language they spoke was part of the Celtic language family.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By the middle of the 15th century the last Welsh rebellions had been defeated.",
        "By the middle of the 17th century the last Welsh rebellion had been defeated.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By the middle of the 15th century the last Welsh rebellions had been defeated.",
      point: "1",
    },
    {
      question:
        "Who do the other TWO crosses on the Union Flag represent, besides St. George's?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["St Andrew", "St David", "St Patrick", "St Piran"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Union Flag consists of three crosses:The cross of St George, patron saint of England, is a red cross on a white ground.;The cross of St Andrew, patron saint of Scotland, is a diagonal white cross on a blue ground. ;The cross of St Patrick, patron saint of Ireland, is a diagonal red cross on a white ground.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The 1950s were a time of serious unrest in Northern Ireland",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The 1970s were also a time of serious unrest in Northern Ireland.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Puritans agreed with the religious reforms of the Church of England introduced by Charles I.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles I wanted the worship of the Church of England to include more ceremony and introduced a revised Prayer Book. He tried to impose this Prayer Book on the Presbyterian Church in Scotland and this led to serious unrest. A Scottish army was formed and Charles could not find the money he needed for his own army without the help of Parliament. In 1640, he recalled Parliament to ask it for funds. Many in Parliament were Puritans, a group of Protestants who advocated strict and simple religious doctrine and worship. They did not agree with the king's religious views and disliked his reforms of the Church of England.",
      point: "1",
    },
    {
      question:
        "How many people emigrated from the UK during Queen Victoria's reign between 1853 and 1913?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["100000", "13 million", "2 million", "500 million"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many people were encouraged to leave the UK to settle overseas. Between 1853 and 1913, as many as 13 million British citizens left the country.",
      point: "1",
    },
    {
      question: "When did Queen Victoria's reign come to an end?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1860", "1895", "1901", "1932"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1837, Queen Victoria became queen of the UK at the age of 18. She reigned until 1901, almost 64 years.",
      point: "1",
    },
    {
      question:
        "In which part of the UK was pioneering Doctor Alexander Fleming born in 1881?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cornwall", "East Anglia", "Scotland", "Wales"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Born in Scotland, Fleming moved to London as a teenager and later qualified as a doctor.",
      point: "1",
    },
    {
      question: "Who assumed the role of Prime Minister in May 2010?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["David Cameron", "Ed Miliband", " Nick Clegg", "Nigel Farage"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 2010, and for the first time in the UK since February 1974, no political party won an overall majority in the General Election. The Conservative and Liberal Democrat parties formed a coalition and the leader of the Conservative Party, David Cameron, became Prime Minister.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?In the UK, everybody has the right to choose their religion or choose not to practise a religion.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, everyone has the legal right to choose their religion, or to choose not to practice a religion.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The official Church of state of the UK is the Church of England.",
        "There is no official Church in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The official Church of the state is the Church of England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Gilbert and Sullivan were a comedy double act",
        "Gilbert and Sullivan wrote many comic operas",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, Gilbert and Sullivan wrote comic operas, often making fun of popular culture and politics.",
      point: "1",
    },
    {
      question: "What is Yorkshire Pudding?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cricket", "Football", "Gold", "Rugby"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Football is the UK's most popular sport. It has a long history in the UK and the first professional football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The daffodil is the national flower of Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The countries that make up the UK all have flowers which are particularly associated with them and which are sometimes worn on national saints days:England - the rose ;Scotland - the thistle ;Wales - the daffodil ;Northern Ireland - the shamrock",
      point: "1",
    },
    {
      question:
        "Which TWO of the following issues can the Northern Ireland Assembly address?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Agriculture", "Defence", "Foreign affairs", "Social services"],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Northern Ireland Assembly can make decisions on issues such as:education ;agriculture ;the environment ;health ;social services.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The court systems in England, Wales, Scotland and Northern Ireland are identical.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are some differences between the court systems in England and Wales, Scotland and Northern Ireland. ",
      point: "1",
    },
    {
      question:
        "Which TWO of the following groups of adults possess voting eligibility in all elections?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Citizens of the Commonwealth who are residents in the UK",
        "Citizens of the USA",
        "Only those born in the UK",
        "UK-born and naturalised adult citizens",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The present voting age of 18 was set in 1969 and (with a few exceptions) all UK-born and naturalised adult citizens have the right to vote. Adult citizens of the UK, and citizens of the Commonwealth and the Irish Republic who are resident in the UK, can vote in all public elections.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The House of Lord always acts as the government wishes.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The House of Lords is normally more independent of the government than the House of Commons. It can suggest amendments or propose new laws, which are then discussed by MPs. The House of Lords checks laws that have been passed by the House of Commons to ensure they are fit for purpose. It also holds the government to account to make sure that it is working in the best interests of the people. ",
      point: "1",
    },
    {
      question:
        "Since which year has the UK had fully democratic voting systems?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1889", "1918", "1928", "1969"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has had a fully democratic voting system since 1928.",
      point: "1",
    },
    {
      question:
        "Who determines the verdict of 'guilty' or 'not guilty' in a Crown Court?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["The Judge", "The Jury", "The barrister", "The defendant"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The jury has to listen to the evidence presented at the trial and then decide a verdict of 'guilty' or 'not guilty' based on what they have heard. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'plantation' settlement in Ireland during the 17th century led to Protestant farmers replacing Catholic landowners.",
        "The 'plantation' settlements in Ireland during the 17th century led to Catholic farmers replacing Protestant landowners.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The English government encouraged Scottish and English Protestants to settle in Ulster, the northern province of Ireland, taking over the land from Catholic landholders. These settlements were known as plantations.",
      point: "1",
    },
    {
      question: "What were The Canterbury Tales?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cavaliers", "Levellers", "Luddites", "Roundheads"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Civil war between the king and Parliament could not now be avoided and began in 1642. The country split into those who supported the king (the Cavaliers) and those who supported Parliament (the Roundheads).",
      point: "1",
    },
    {
      question:
        "Which TWO systems were introduced before the outbreak of the First World War (1914)?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Child Benefit payments",
        "Free school meals",
        "National Health Services (NHS)",
        "State retirement pension",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It was also a time of social progress. Financial help for the unemployed, old-age pensions and free school meals were just a few of the important measures introduced.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The First World War ended at 11.00am on 11 November 1918.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The First World War ended at 11.00 am on 11th November 1918 with victory for Britain and its allies.",
      point: "1",
    },
    {
      question: "Scotland changed in which TWO ways after Battle of Culloden?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Chieftains became landlords if they had the favour of the English king",
        "Chieftains took control of the land away from the English king",
        "Clansmen became tenants who had to pay for the land they used",
        "The clans were entirely destroyed",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The clans lost a lot of their power and influence after Culloden. Chieftains became landlords if they had the favour of the English king, and clansmen became tenants who had to pay for the Land they used.",
      point: "1",
    },
    {
      question:
        "Which period of British history witnessed the emergence of a national culture and identity?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Bronze Age",
        "The Middle Ages",
        "The Tudor period",
        "The Victorian period",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Middle Ages saw the development of a national culture and identity.",
      point: "1",
    },
    {
      question:
        "Why was Parliament still far from being a democracy after the Glorious Revolution?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Only bishops were able to vote",
        "Only men who owned property of a certain value could vote",
        "Only women were allowed to vote",
        "Parliament took control of who could be monarch",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After William III, the ministers gradually became more important than the monarch but this was not a democracy in the modern sense. The number of people who had the right to vote for members of Parliament was still very small. Only men who owned property of a certain value were able to vote.",
      point: "1",
    },
    {
      question: "Which is a line from one of Churchill's famous speeches?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "I have a dream",
        "I have nothing to offer but blood, toil, tears and sweat",
        "The lady's not for turning",
        "The only thing we have to fear is fear itself",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "I have nothing to offer but blood, toil, tears and sweat' ; Churchill's first speech to the House of Commons after he became Prime Minister, 1940",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Beveridge Report of 1942 outlined a plan to end the war in Europe.",
        "The Beveridge Report of 1942 provided the basis of the modern welfare state.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1945 the British people elected a Labour government. The new Prime Minister was Clement Attlee, who promised to introduce the welfare state outlined in the Beveridge Report.",
      point: "1",
    },
    {
      question: "St David is the patron saint of which country of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England, Scotland, Wales and Northern Ireland each have a national saint, called a patron saint. Each saint has a special day:1 March: St David's Day, Wales ; 17 March: St Patrick's Day, Northern Ireland ; 23 April. St George's Day, England ; 30 November: St Andrew's Day, Scotland.",
      point: "1",
    },
    {
      question:
        "What is one of the roles of school governors and school boards?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bonfire Night", "Hogmanay", "Remembrance Day", "Vaisakhi"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "New Year, 1 January, is a public holiday. People usually celebrate on the night of 31 December (called New Year's Eve). In Scotland, 31 December is called Hogmanay and 2 January is also a public holiday. For some Scottish people, Hogmanay is a bigger holiday than Christmas.",
      point: "1",
    },
    {
      question: "What must police officers do?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A caramel-flavoured dessert",
        "Apple and pears with custard",
        "Batter cooked in the oven",
        "Meat and potato in a pastry",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England: Roast beef, which is served with potatoes, vegetables, Yorkshire puddings (batter that is baked in the oven) and other accompaniments. Fish and chips are also popular.",
      point: "1",
    },
    {
      question: "St Andrew is the patron of which country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Northern Ireland", "Wales"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "30 November: St Andrew's Day, Scotland.",
      point: "1",
    },
    {
      question: "What is the name of a novel by Jane Austen?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "19", "20"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To buy alcohol in a pub or night club you must be 18 or over, but people under that age may be allowed in some pubs with an adult.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Andy Murray is the first British man to sail around the world.",
        "Andy Murray is the first British man to win a singles tennis title in a Grand Slam tournament since 1936.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He is the first British man to win a singles title in a Grand Slam tournament since 1936. In the same year, he won Olympic gold and silver medals and was runner-up in the men's singles at Wimbledon.",
      point: "1",
    },
    {
      question: "Who opens the new parliamentary session each year?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Archbishop of Canterbury",
        "The Prime Minister",
        "The Speaker of the House of Commons",
        "The monarch",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen has important ceremonial roles, such as the opening of the new parliamentary session each year. ",
      point: "1",
    },
    {
      question:
        "Which TWO of the following entities form part of the UK government?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "FIFA",
        "The National Trust",
        "The cabinet",
        "The civil service",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Civil servants support the government in developing and implementing its policies. They also deliver public services. Civil servants are accountable to ministers. ; These ministers form the cabinet, a committee which usually meets weekly and makes important decisions about government policy.",
      point: "1",
    },
    {
      question: "Is a verdict of 'not proven' possible?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Yes, but only in Scotland",
        "Yes, but only in a Magistrates' Court",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, a third verdict of 'not proven' is also possible.",
      point: "1",
    },
    {
      question:
        "Which one of the following is not listed as a benefit of volunteering?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Being able to add new experience to your CV",
        "Being paid",
        "Having a chance to practise English",
        "Meeting new people",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Volunteering is working for good causes without payment. ",
      point: "1",
    },
    {
      question: "What are TWO fundamental principles of British life?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Growing your own fruit and vegetables",
        "Only driving your car on weekdays",
        "Participation in community life",
        "Tolerance of those with different faiths and beliefs",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The fundamental principles of British life include:Democracy,The rule of law,Individual liberty,Tolerance of those with different faiths and beliefs,Participation in community life.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution introduced changes in the banking system in the 1970's.",
        "The Industrial Revolution was the rapid development of industry in the 18th and 19th centuries.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first person to use the title Prime Minister was Sir Christopher Wren.",
        "The first person to use the title Prime Minister was Sir Robert Walpole.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The most important minister in Parliament became known as the Prime Minister. The first man to be called this was Sir Robert Walpole, who was Prime Minister from 1721 to 1742.",
      point: "1",
    },
    {
      question:
        "Which TWO records provide insights into England during William I's era?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Bayeux Tapestry",
        "Diary of Samuel Pepys",
        "Domesday Book",
        "Magna Carta",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "William became king of England and is known as William the Conqueror. The battle is commemorated in a great piece of embroidery, known as the Bayeux Tapestry, which can still be seen in France today. William sent people all over England to draw up lists of all the towns and villages. The people who lived there, who owned the land and what animals they owned were also listed. This was called Domesday Book.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Isaac Newton is a famous musician from the 18th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "His most famous published work was Philosophiae Naturalis Principia Mathematica (''Mathematical Principles of Natural Philosophy'), which showed how gravity applied to the whole universe.",
      point: "1",
    },
    {
      question:
        "Which product did the UK dominate the world's supply of in the 19th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beer", "Cigarettes", "Cotton cloth", "Rubber"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British industry led the world in the 19th century. The UK produced more than half of the world's iron, coal and cotton cloth.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1707 the kingdoms of England and Scotland were united.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Act of Union, known as the Treaty of Union in Scotland, was therefore agreed in 1707, creating the Kingdom of Great Britain.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Margaret Thatcher was the longest-serving UK Prime Minister of the 20th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "She was the longest-serving Prime Minister of the 20th century, remaining in office until 1990.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The War of the Roses was between the houses of Lancaster and York.",
        "The Wars of the Roses were between the houses of Windsor and Tudor",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1455, a civil war was begun to decide who should be king of England. It was fought between the supporters of two families: the House of Lancaster and the House of York.",
      point: "1",
    },
    {
      question:
        "In 1948, Aneurin (Nye) Bevan spearheaded the establishment of the National Health Service (NHS). What was his role?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Deputy Prime Minister",
        "Home secretary",
        "Minister for Health",
        "Minister for Social Security",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " In 1948, Aneurin (Nye) Bevan, the Minister for Health, led the establishment of the National Health Service (NHS), which guaranteed a minimum standard of health care for all, free at the point of use. A national system of benefits was also introduced to provide 'social security', so that the population would be protected from the 'cradle to the grave'.",
      point: "1",
    },
    {
      question: "What was the Royal Society formed to promote?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Art", "Astronomy", "Music", "Natural knowledge"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Charles II was interested in science. During his reign, the Royal Society was formed to promote 'natural knowledge'. This is the oldest surviving scientific society in the world.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Education Act of 1944 is often called The Butler Act and introduced free secondary education in England and Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In this role, he oversaw the introduction of the Education Act 1944(often called 'The Butler Act'), which introduced free secondary education in England and Wales.",
      point: "1",
    },
    {
      question:
        "What do you accept and agree to when you become a permanent resident of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "She had one daughter with Henry VIII named Elizabeth",
        "She married Henry VIII late in his life, and survived him",
        "She never married",
        "She ruled with William",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary also died after a short reign and the next monarch was her half-sister, Elizabeth, the daughter of Henry VIII and Anne Boleyn.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following factors contributed to the Industrial Revolution in Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The development of steam power and machinery",
        "The foundation of the modern welfare state",
        "The outbreak of plague",
        "The repealing of the Corn Laws",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "One example of this was the repealing of the Corn Laws in 1846. These had prevented the import of cheap grain. The reforms helped the development of British industry, because raw materials could now be imported more cheaply.",
      point: "1",
    },
    {
      question: "Which is the most popular sport in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Football", "Golf", "Rugby", "Tennis"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Football is the UK's most popular sport. It has a long history in the UK and the first professional football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question: "What celebration takes place each year on 14 February?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bonfire Night", "Halloween", "Hogmanay", "Valentine's Day"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Valentine's Day, 14 February, is when lovers exchange cards and gifts. Sometimes people send anonymous cards to someone they secretly admire.",
      point: "1",
    },
    {
      question: "What is the name of the UK currency?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Grand Chase", "Grand Derby", "Grand National", "Steeplechase"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Famous horse-racing events include: Royal Ascot, a five-day race meeting in Berkshire attended by members of the Royal Family; the Grand National at Aintree near Liverpool; and the Scottish Grand National at Ayr.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1998 the Good Friday Agreement devolved powers to Wales",
        "In 1998 the Good Friday Agreement let to the establishment of the Northern Ireland Assembly",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Northern Ireland Assembly was established soon after the Belfast Agreement (or Good Friday Agreement) in 1998.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The home Secretary is the government minister responsible for managing relationships with foreign countries.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister appoints about 20 senior MPs to become ministers in charge of departments. These include:Chancellor of the Exchequer - responsible for the economy ;Home Secretary - responsible for crime, policing and immigration ;Foreign Secretary - responsible for managing relationships with foreign countries ;other ministers (called 'Secretaries of State') responsible for subjects such as education, health and defence.",
      point: "1",
    },
    {
      question: "Which is one of the King's important ceremonial roles?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To chair debates in Parliament",
        "To negotiate trade agreements with other countries",
        "To receive foreign ambassadors and high commissioners",
        "To write laws",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen represents the UK to the rest of the world. She receives foreign ambassadors and high commissioners, entertains visiting heads of state, and makes state visits overseas in support of diplomatic and economic relationships with other countries.",
      point: "1",
    },
    {
      question:
        "In the UK, what is considered an acceptable basis for discrimination?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Age",
        "None of the above",
        "Pregnancy or maternity",
        "Religion or beliefs",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "UK laws ensure that people are not treated unfairly in any area of life or work because of their age, disability, sex, pregnancy and maternity, race, religion or belief, sexuality or marital status. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "On becoming a UK citizen or permanent resident, you can choose which laws and responsibilities you want to accept.",
        "On becoming a UK citizen or permanent resident, you will be agreeing to respect the laws values and traditions of the UK.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You will be agreeing to accept the responsibilities which go with permanent residence and to respect the laws, values and traditions of the UK.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK does not allow citizens or permanent residents to voice options publicly.",
        "The UK offers its citizens and permanent residents freedom of speech.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In return, the UK offers:freedom of belief and religion,freedom of speech,freedom from unfair discrimination,a right to a fair trial,a right to join in the election of a government.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Reform Act of 1832 decreased the number of voters.",
        "The Reform Act of 1832 increased the number of voters.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote",
      point: "1",
    },
    {
      question:
        "During the reign of William and Mary, which statement best characterizes 'party politics'?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "There were two main groups, the Liberals and the Conservatives",
        "There were two main groups, the Tories and the Whigs",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were two main groups in Parliament, known as the Whigs and the Tories. (The modern Conservative Party is still sometimes referred to as the Tories.) This was the beginning of party politics.",
      point: "1",
    },
    {
      question:
        "Which TWO families fought in the Wars of the Roses to determine the King of England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "The House of Lancaster",
        "The House of Tudor",
        "The House of Windsor",
        "The House of York",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1455, a civil war was begun to decide who should be king of England. It was fought between the supporters of two families: the House of Lancaster and the House of York. This war was called the Wars of the Roses, because the symbol of Lancaster was a red rose and the symbol of York was a white rose.",
      point: "1",
    },
    {
      question: "When did the first farmers arrive in Britain?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "10,000 years ago",
        "3,000 years ago",
        "6,000 years ago",
        "8,000 years go",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The first farmers arrived in Britain 6,000 years ago.",
      point: "1",
    },
    {
      question:
        " In Scotland, the legal system developed differently from the one in England in that its laws were 'codified'. What is meant by codified?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Based on tradition",
        "Dictated by Parliament",
        "Less complicated",
        "Written down",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, the legal system developed slightly differently and laws were 'codified' (that is, written down).",
      point: "1",
    },
    {
      question:
        "What do Sir William Golding, Seamus Heaney, and Harold Pinter have in common?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "About Christianity",
        "About other cultures",
        "About other languages",
        "About trade",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Anglo-Saxons were not Christians when they first came to Britain but, during this period, missionaries came to Britain to preach about Christianity.",
      point: "1",
    },
    {
      question: "Which political party did Clement Attlee belong to?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Conservative", "Green", "Labour", "Liberal Democrats"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Clement Attlee was born in London in 1883. His father was a solicitor and, after studying at Oxford University, Attlee became a barrister. He gave this up to do social work in East London and eventually became a Labour MP.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Rugby originated in England in the early 19th century.",
        "Rugby was introduced to ancient Britain by Viking invaders.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Rugby originated in England in the early 19th century and is very popular in the UK today.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Baptists, Methodists and Quakers are all linked to the Roman Catholic Church.",
        "Baptists, Methodists and Quakers are protestant Christian groups.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Other Protestant Christian groups in the UK are Baptists, Methodists, Presbyterians and Quakers.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following represent traditional British cuisine?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Haggis", "Strudel", " Sushi", "Welsh cakes"],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Wales: Welsh cakes - a traditional Welsh snack made from flour, dried fruits and spices, and served either hot or cold. ;Scotland: Haggis - a sheep's stomach stuffed with offal, suet, onions and oatmeal.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Mo Farah was the first person to sail around the world single-handed.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mo Farah (1983-) is a British distance runner, born in Somalia. He won gold medals in the 2012 Olympics for the 5,000 and 10,000 metres and is the first Briton to win the Olympic gold medal in the 10,000 metres.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The Speaker of the House of Commons has to give up being an MP when elected as Speaker.",
        "The Speaker of the House of Commons remains a Member of Parliament (MPs) after election as Speaker.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Debates in the House of Commons are chaired by the Speaker. This person is the chief officer of the House of Commons. The Speaker is neutral and does not represent a political party, even though he or she is an MP, represents a constituency and deals with constituents' problems like any other MP. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Getting to know your neighbours can help you to become part of the community.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Getting to know your neighbours can help you to become part of the community and make friends. ",
      point: "1",
    },
    {
      question: "What happens when members of Parliament (MPs) hold surgeries?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Members of the public can meet their MP to discuss their issues.",
        "They discuss local health issues with doctors.",
        "They invite members of the press along to talk over national issues.",
        "They meet local councillors to discuss local issues.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In addition, many MPs, Assembly members, MSPs and MEPs hold regular local 'surgeries', where constituents can go in person to talk about issues that are of concern to them. These surgeries are often advertised in the local newspaper. ",
      point: "1",
    },
    {
      question: "Which parts of the United Kingdom have devolved governments?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "England and Wales",
        "Only Northern Ireland",
        "Wales, England and Northern Ireland",
        "Wales, Scotland and Northern Ireland",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In addition, there are devolved governments in Scotland, Wales and Northern Ireland that have the power to legislate on certain issues. ",
      point: "1",
    },
    {
      question:
        "MP's have a duty to serve and represent which of the following groups?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Everyone in their constituency",
        "Everyone in their constituency who voted for them",
        "The House of Lords",
        "Their fellow MPs",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Each MP represents a parliamentary constituency, which is a small area of the country. MPs have a number of different responsibilities. They:represent everyone in their constituency ;help to create new laws ;scrutinise and comment on what the government is doing ;debate important national issues.",
      point: "1",
    },
    {
      question: "Who presides over debates in the House of Commons?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Foreign Secretary",
        "The Prime Minister",
        "The Speaker",
        "The leader of the opposition",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Debates in the House of Commons are chaired by the Speaker. This person is the chief officer of the House of Commons.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Discrimination in the workplace is an example of criminal law.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of civil laws are:Employment law: these cases include disputes over wages and cases of unfair dismissal or discrimination in the workplace.",
      point: "1",
    },
    {
      question:
        "If unable to visit a polling station to vote, what can you register for?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A postal ballot",
        "A virtual ballot",
        "An absentee ballot",
        "An absentee vote",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If it is difficult for you to get to a polling station or polling place, you can register for a postal ballot. Your ballot paper will be sent to your home before the election.",
      point: "1",
    },
    {
      question:
        "King Charles III is the head of state for which TWO of the following?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Iceland",
        "Many Commonwealth countries",
        "The European Union",
        "The United Kingdom",
      ],
      correctAnswer: "B,D",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Queen Elizabeth II is the head of state of the UK. She is also the monarch or head of state for many countries in the Commonwealth.",
      point: "1",
    },
    {
      question:
        "What is a responsibility that you will have as a citizen or permanent resident of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "To avoid shopping on a Sunday",
        "To grow your own vegetables",
        "To keep your dog on a lead at all times",
        " To look after yourself and your family",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you wish to be a permanent resident or citizen of the UK, you should:respect and obey the law,respect the rights of others, including their right to their own opinions,treat others with fairness,look after yourself and your family,look after the area in which you live and the environment.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? There is no place in British society for extremism or intolerance.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is no place in British society for extremism or intolerance.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution is the name given to the rapid development of industry in Britain in the 20th century.",
        "The Industrial Revolution is the name given to the rapid development of industry that began in the 18th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question: "What system of government does the UK have?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Arched", "Mullioned", "Sash", "Stained glass"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Several of the cathedrals had windows of stained glass, telling stories about the Bible and Christian saints. The glass in York Minister is a famous example.",
      point: "1",
    },
    {
      question: "How did Jane Seymour meet her end?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Old age",
        "She was executed",
        "The plague",
        "following childbirth",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Seymour - Henry married Jane after Anne's execution. She gave Henry the son he wanted, Edward, but she died shortly after the birth.",
      point: "1",
    },
    {
      question: "Which TWO individuals were prominent Enlightenment thinkers?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Adam Smith",
        "David Hume",
        "Robert Burns",
        "Robert Louis Stevenson",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many of the great thinkers of the Enlightenment were Scottish. Adam Smith developed ideas about economics which are still referred to today. David Hume's ideas about human nature continue to influence philosophers.",
      point: "1",
    },
    {
      question: "Hadrian's Wall was constructed to deter whom?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["The Irish", "The Picts", "The Vikings", "The Welsh"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Areas of what is now Scotland were never conquered by the Romans, and the Emperor Hadrian built a wall in the north of England to keep out the Picts (ancestors of the Scottish people).",
      point: "1",
    },
    {
      question:
        "What happened to Margaret Thatcher in 1979 to make her famous in UK history?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "She became a High Court judge",
        "She became the first woman Prime Minister.",
        "She took part in the Olympics.",
        " She was made a general in the British army.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Following the Conservative victory in the General Election in 1979, Margaret Thatcher became the first woman Prime Minister of the UK.",
      point: "1",
    },
    {
      question:
        "Sake Dean Mahomet opened which establishment in George Street, London in 1810?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Hindustan Coffee House",
        "Mahomet Coffee House",
        "Mahomet Shampoo Parlour",
        "Pakistan Curry House",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mahomet was born in 1759 and grew up in the Bengal region of India. He served in the Bengal army and came to Britain in 1782. He then moved to Ireland and eloped with an Irish girl called Jane Daly in 1786, returning to England at the turn of the century. In 1810 he opened the Hindustan Coffee House in George Street, London. It was the first curry house to open in Britain.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In the Middle Ages, England's systems of 'common law' was established by referring to previous decisions and tradition.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This was also a time of development in the legal system. The principle that judges are independent of the government began to be established. In England, judges developed 'common law' by a process of precedence (that is, following previous decisions) and tradition.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Richard Arkwright developed horse-driven spinning mills that used only one machine, increasing efficiency and production.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Born in 1732, Arkwright originally trained and worked as a barber. He was able to dye hair and make wigs. When wigs became less popular, he started to work in textiles. He improved the original carding machine. Carding is the process of preparing fibres for spinning into yarn and fabric. He also developed horse-driven spinning mills that used only one machine. This increased the efficiency of production. Later, he used the steam engine to power machinery. Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question: "What are small Scottish farms also known as?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bothies", "Crofts", "Hamlets", "Homesteads"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A process began which became known as the 'Highland Clearances'. Many Scottish landlords destroyed individual small farms (known as 'crofts') to make space for large flocks of sheep and cattle.",
      point: "1",
    },
    {
      question: "What did Iron Age people sometimes defend?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Castles", "Hill forts", "Long walls", "None of the above"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Bronze Age was followed by the Iron Age, when people learned how to make weapons and tools out of iron. People still lived in roundhouses, grouped together into larger settlements, and sometimes defended sites called hill forts.",
      point: "1",
    },
    {
      question: "When did Parliament as we know it today begin to develop?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Democrettes", "Feminettes", "Suffragettes", "Votettes"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the late 19th and early 20th centuries, an increasing number of women campaigned and demonstrated for greater rights and, in particular, the right to vote. They formed the women's suffrage movement and became known as 'suffragettes'.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Sake Dean Mahomet was born in 1759 and grew up in the Bengal Religion of India.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mahomet was born in 1759 and grew up in the Bengal region of India.",
      point: "1",
    },
    {
      question: "Which event occurs annually on the third Sunday in June?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Boxing Day", "Father's Day", "Halloween", "Remembrance Day"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Father's Day is the third Sunday in June. Children send cards or buy gifts for their fathers.",
      point: "1",
    },
    {
      question: "In which language was The Canterbury Tales authored?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" English", "French", " Scottish Gaelic", "Welsh"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "British poetry is among the richest in the world. The Anglo-Saxon poem Beowulf tells of its hero's battles against monsters and is still translated into modern English. Poems which survive from the Middle Ages include Chaucer's Canterbury Tales and a poem called Sir Gawain and the Green Knight, about one of the knights at the court of King Arthur.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Murder, assault and theft are crimes.",
        "Murder, assault and theft are examples of civil disputes.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many that apply in most countries, such as murder, theft and assault. You can find out more about types of crime in the UK at www.gov.uk",
      point: "1",
    },
    {
      question: "Which TWO actions can you take to preserve the environment?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Drive your car as much as possible",
        "Never turn the lights off in your house",
        "Recycle you waste",
        "Walk and use public transport to get around",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It's important to recycle as much of your waste as you can. Using recycled materials to make new products uses less energy and means that we do not need to extract more raw materials from the earth. ; Walking and using public transport to get around when you can is also a good way to protect the environment. It means that you create less pollution than when you use a car.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the UK organ donation is a legal requirement.",
        "In the UK organ donation is voluntary.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you register to be an organ donor, it can make it easier for your family to decide whether to donate your organs when you die. You can register to be an organ donor at www.organdonation.nhs.uk. Living people can also donate a kidney. ",
      point: "1",
    },
    {
      question: "Who may some local councils appoint as a ceremonial leader?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A local business leader",
        "A local celebrity",
        " A mayor",
        "A member of the Royal Family",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many local authorities appoint a mayor, who is the ceremonial leader of the council. ",
      point: "1",
    },
    {
      question: "Who holds the position of the head of state?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "King Charles",
        "The President",
        "The Prime Minister",
        "The Queen",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen has an important role in providing stability and continuity. While governments and Prime Ministers change regularly, the Queen continues as head of state. She provides a focus for national identity and pride, which was demonstrated through the celebrations of her Jubilee.",
      point: "1",
    },
    {
      question:
        "In what aspects was Scotland still separate from the rest of Great Britain after the Act of Union?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All of the above",
        "It kept its own Presbyterian church",
        "It kept its own educational system",
        " It kept its own legal system",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Act of Union, known as the Treaty of Union in Scotland, was therefore agreed in 1707, creating the Kingdom of Great Britain. Although Scotland was no longer an Independent country, it kept its own legal and education systems and Presbyterian Church.",
      point: "1",
    },
    {
      question: "Which TWO statements about slavery are accurate?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Quakers set up the first anti-slavery groups.",
        "Slavery survived in the British Empire until the early 20th century.",
        "The Royal Navy refused to stop ships carrying slaves.",
        "William Wilberforce was a leading abolitionist.",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first formal anti-slavery groups were set up by the Quakers in the late 1700s, and they petitioned Parliament to ban the practice. William Wilberforce, an evangelical Christian and a member of Parliament, also played an important part in changing the law. Along with other abolitionists (people who supported the abolition of slavery), he succeeded in turning public opinion against the slave trade.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Under the system of feudalism, the kings gave land to lords, and the landowners had to send men to serve in the army in return.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Normans used a system of land ownership known as feudalism. The king gave land to his lords in return for help in war. Landowners had to send certain numbers of men to serve in the army.",
      point: "1",
    },
    {
      question: "Who was Kenneth MacAlpin?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Danish King",
        "A Viking",
        "An Anglo-Saxon king",
        "The Scottish king",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the north, the threat of attack by Vikings had encouraged the people to unite under one king, Kenneth MacAlpin. The term Scotland began to be used to describe that country.",
      point: "1",
    },
    {
      question: "What happened to Catherine Parr?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "She Died",
        "She had a daughter",
        "She had a son",
        "She was executed",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine Parr - Catherine was a widow who married Henry late in his life. She survived him and married again but died soon after.",
      point: "1",
    },
    {
      question:
        "Which sector of the Irish economy supported approximately two-thirds of its population during the 19th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Farming", "Manufacturing", "Shipbuilding", "Tourism"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Conditions in Ireland were not as good as in the rest of the UK. Two-thirds of the population still depended on farming to make their living, often on very small plots of land.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? People are able to buy National Lottery tickets in the UK if they are aged 14 or over.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People under 16 are not allowed to participate in the National Lottery.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Both Jane Austen and Charles Dickens are famous novelists",
        "Both Jane Austen and Charles Dickens are famous painters",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Austen (1775-1817) was an English novelist. Her books include Pride and Prejudice and Sense and Sensibility. Her novels are concerned with marriage and family relationships. Many have been made into television programmes or films. Charles Dickens (1812-70) wrote a number of very famous novels, including Oliver Twist and Great Expectations. You will hear references in everyday talk to some of the characters in his books, such as Scrooge (a mean person) or Mr Micawber (always hopeful).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Mousetrap is a play that has been running in London's West End since 1952.",
        "The Mousetrap is an environmental policy aiming to prevent mice from destroying crops.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Mousetrap, a murder-mystery play by Dame Agatha Christie, has been running in the West End since 1952 and has had the longest initial run of any show in history.",
      point: "1",
    },
    {
      question: "Which TWO are prestigious horse-racing events?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Royal Ascot",
        "The Cup Final",
        "The Grand National",
        "The Six Nations",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Famous horse-racing events include: Royal Ascot, a five-day race meeting in Berkshire attended by members of the Royal Family; the Grand National at Aintree near Liverpool; and the Scottish Grand National at Ayr.",
      point: "1",
    },
    {
      question: "What is a traditional pub game in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Poker", "Pool", "Rounders", "Scrabble"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Pool and darts are traditional pub games.",
      point: "1",
    },
    {
      question: "Which of these books did Graham Greene NOT write?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Brighton Rock",
        "Moby Dick",
        "The Heart of the Matter",
        "The Honorary Consul",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Graham Greene (1904-91) wrote novels often influenced by his religious beliefs, including The Heart of the Matter, The Honorary Consul, Brighton Rock and Our Man in Havana.",
      point: "1",
    },
    {
      question: "Which of these films was directed by David Lean?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Brief Encounter",
        "Four Weddings and a Funeral",
        "The Killing Fields",
        "Women in Love",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Brief Encounter119451, directed by David Lean.",
      point: "1",
    },
    {
      question: "What is the highest-value note issued as British currency?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Beefeaters", "Crown Guards", "Pikestaff", "Queens Men"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Tours are given by the Yeoman Warders, also known as Beefeaters, who tell visitors about the building's history. People can also see the Crown Jewels there.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1830s and 1840s a group called the Chartists campaigned for reform to the voting system.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 1830s and 1840s, a group called the Chartists campaigned for reform.",
      point: "1",
    },
    {
      question: "How are local councils financed?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "From central government and local taxes",
        "From local businesses",
        "Through donations from local people",
        "Through money raised from local fundraising events",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are funded by money from central government and by local taxes. ",
      point: "1",
    },
    {
      question:
        "Why is the year 1918 significant in the history of Women's rights?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Equal pay laws were passed",
        "The first divorce laws were introduced",
        "Women were given the right to vote",
        " Women were made legally responsible for their child.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, by 1918 most of these reforms had been adopted. The voting franchise was also extended to women over 30, and then in 1928 to men and women over 21. In 1969, the voting age was reduced to 18 for men and women. ",
      point: "1",
    },
    {
      question: "Who elects Police and Crime Commissioners (PCCs)?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Members of Parliament",
        "The Home Office",
        "The police",
        "The public",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In November 2012, the public elected Police and Crime Commissioners (PCCs) in England and Wales. ",
      point: "1",
    },
    {
      question: "Which TWO courts handle minor criminal cases in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Centre Court",
        "Crown Court",
        "Justice of the Peace Court",
        "Magistrates' Court",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland, most minor criminal cases are dealt with in a Magistrates' Court. In Scotland, minor criminal offences go to a Justice of the Peace Court. ",
      point: "1",
    },
    {
      question: "Why is Henry VIII remembered?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Broke away from the Church of Rome",
        "Had seven sons",
        " Horse racing",
        " Married six times",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry VIII was most famous for breaking away from the Church of Rome and marrying six times.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Reform Act of 1832 increased the number of electors.",
        "The Reform Act of 1832 increased the power of the House of Lords.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Shakespeare was a great English playwright",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Shakespeare was born in Stratford-upon-Avon, England. He was a playwright and actor and wrote many poems and plays.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'Divine Right of Kings' was the view held by many English monarchs that they could seize lands belonging to the nobility as they wished.",
        "The 'Divine Rights of Kings' was the view held by many English monarchs that they were directly appointed by God to rule.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I were less skilled politically. Both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule. They thought that the king should be able to act without having to seek approval from Parliament.",
      point: "1",
    },
    {
      question: "Who were Elizabeth I's parents?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Henry VII and Elizabeth of York",
        "Henry VIII and Anne Boleyn",
        "Henry VIII and Catherine of Aragon",
        "Henry VIII and Jane Seymour",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was a devout Catholic and persecuted Protestants (for this reason, she became known as 'Bloody Mary'). Mary also died after a short reign and the next monarch was her half-sister, Elizabeth, the daughter of Henry VIII and Anne Boleyn.",
      point: "1",
    },
    {
      question:
        "During the 16th century, what were the TWO primary factors that led to a bloody rebellion by the Irish chieftains?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Extreme poverty and famine",
        "High taxes on landowners",
        "The imposing of English laws on land inheritance",
        "The imposing of Protestantism",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Ireland, however, attempts by the English to impose Protestantism (alongside efforts to introduce the English system of laws about the inheritance of land) led to rebellion from the Irish chieftains, and much brutal fighting followed.",
      point: "1",
    },
    {
      question: "George and Robert Stephenson are famed for pioneering what?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The aeroplane",
        "The electric light bulb",
        "The railway engine",
        "The telephone",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Just before Victoria came to the throne, the father and son George and Robert Stephenson pioneered the railway engine and a major expansion of the railways took place in the Victorian period.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Richard Arkwright developed efficient and profitable factories.",
        "Richard Arkwright developed new farming methods in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A free press means newspapers are given out free of charge.",
        "A free press means that what is written in newspapers is free from government control.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This was also an important time for the development of a free press (newspapers and other publications which are not controlled by the government).",
      point: "1",
    },
    {
      question: "Which TWO of the following renowned British authors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Gustav Holst",
        "J K Rowling",
        "Sir Arthur Conan Doyle",
        "Sir Steve Redgrave",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Arthur Conan Doyle (1859-1930) was a Scottish doctor and writer. He was best known for his stories about Sherlock Holmes, who was one of the first fictional detectives. J K Rowling (1965-) wrote the Harry Potter series of children's books, which have enjoyed huge international success. She now writes fiction for adults as well.",
      point: "1",
    },
    {
      question: "What is the capital city of Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Belfast", "Dublin", "Edinburgh", " The Pale"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Northern Ireland, The capital city of Northern Ireland is Belfast",
      point: "1",
    },
    {
      question: "What task is associated with the National Trust?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Cricket", "Football", "Rugby", "Tennis"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many of the best players in the world play in the Premier League. Many UK teams also compete in competitions such as the UEFA (Union of European Football Associations) Champions League, against other teams from Europe.",
      point: "1",
    },
    {
      question: "Which TWO of the following iconic British landmarks?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Albert Square",
        "The Eden Project",
        "The London Eye",
        "The Smithsonian",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The London Eye is situated on the southern bank of the River Thames and is a Ferris wheel that is 443 feet (135 metres) tall. It was originally built as part of the UK's celebration of the new millennium and continues to be an important part of New Year celebrations. ; The Eden Project is located in Cornwall, in the south west of England. Its biomes, which are like giant greenhouses, house plants from all over the world. The Eden Project is also a charity which runs environmental and social projects internationally.",
      point: "1",
    },
    {
      question: "What age is required to legally ride a moped in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "21", "25"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You need to be at least 16 years old to ride a moped, and there are other age requirements and special tests for driving large vehicles. ",
      point: "1",
    },
    {
      question:
        "Which TWO of the following professionals would you consult for legal advice?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A local councillor",
        "A solicitor",
        " The Citizens Advice Bureau",
        "Your local member of Parliament (MP)",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Solicitors are trained lawyers who give advice on legal matters, take action for their clients and represent their clients in court. The Citizens Advice Bureau (www.citizensadvice.org.uk) can give you names of local solicitors and which areas of law they specialise in.",
      point: "1",
    },
    {
      question:
        "What type of government was formed after the General Election of 2010?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Communism", "Democracy", "Rule of law", "Violence"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is based on the core values of democracy, good government and the rule of law. ",
      point: "1",
    },
    {
      question: "What is Ridley Scott famous for?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Giving teacher ideas of lesson plan",
        "Marking students' homework",
        "Serving food and drink in the canteen",
        "Setting the strategic direction of the school",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Governors and school boards have an important part to play in raising school standards. They have three key roles:setting the strategic direction of the school ;ensuring accountability ;monitoring and evaluating school performance.",
      point: "1",
    },
    {
      question: "Which countries do not belong to the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Canada", "Germany", "Ghana", "Singapore"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen is the ceremonial head of the Commonwealth, which currently has 54 member states.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It costs £15 per person to visit the Houses of Parliament to watch debates.",
        " It is free to visit the House of Parliament to watch debates.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The public can listen to debates in the Palace of Westminster from public galleries in both the House of Commons and the House of Lords. ",
      point: "1",
    },
    {
      question: "Why was Magna Carta considered important?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It established a new system of free education.",
        "It gave all men the vote.",
        "It gave women legal rights.",
        "It limited the power of the monarch.",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were few formal limits to the king's power until 1215. In that year, King John was forced by his noblemen to agree to a number of demands. The result was a charter of rights called the Magna Carta (which means the Great Charter).",
      point: "1",
    },
    {
      question:
        "Which TWO of the following were substantial welfare reforms enacted between 1945 and 1950?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A social security system for all",
        "Employment exchanges",
        "National Health Services (NHS)",
        "State retirement pension",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1948, Aneurin (Nye) Bevan, the Minister for Health, led the establishment of the National Health Service (NHS), which guaranteed a minimum standard of health care for all, free at the point of use.",
      point: "1",
    },
    {
      question:
        "What rose color became emblematic of the Tudor dynasty established by Henry VII?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" Pink", " Red", "Red and white", "White"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry was the first king of the House of Tudor. The symbol of the House of Tudor was a red rose with a white rose inside it as a sign that the Houses of York and Lancaster were now allies.",
      point: "1",
    },
    {
      question: "Who was William Shakespeare?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Scottish patriot",
        "A naval commander",
        "A poet, actor and playwright",
        "An English parliamentarian",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Shakespeare was born in Stratford-upon-Avon, England. He was a playwright and actor and wrote many poems and plays.",
      point: "1",
    },
    {
      question:
        "During which monarch's reign in 1665 did London experience a significant plague outbreak?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Charles I",
        "Charles II",
        "James I and Charles I were very skilled at negotiating with Parliament",
        "James II",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During Charles II's reign, in 1665, there was a major outbreak of plague in London.",
      point: "1",
    },
    {
      question: "Which TWO among the following are British inventions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Golf cart", "Helicopter", "Hovercraft", "Jet engine"],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer.; Sir Christopher Cockerell (1910-99), a British inventor, invented the hovercraft in the 1950s.",
      point: "1",
    },
    {
      question:
        "When William of Orange and his wife Mary came to the throne, it was later known as the Glorious Revolution. Why?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It led to the Reformation and the formation of the Protestant Church",
        "It signalled the start of the Hundred Years War",
        "There was no fighting in England",
        "William of Orange defeated a much larger army",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1688, important Protestants in England asked William to invade England and proclaim himself king. When William reached England, there was no resistance.",
      point: "1",
    },
    {
      question:
        "Which British novelist created the fictional detective Sherlock Holmes?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Evelyn Waugh",
        "Graham Greene",
        "Robert Louis Stevenson",
        "Sir Arthur Conan Doyle",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Arthur Conan Doyle (1859-1930) was a Scottish doctor and writer. He was best known for his stories about Sherlock Holmes, who was one of the first fictional detectives.",
      point: "1",
    },
    {
      question: "What was inscribed on some Iron Age coins?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Methodist", "Presbyterian", "Quaker", "Roman Catholic"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, the national Church is the Church of Scotland, which is a Presbyterian Church.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Archbishop of Canterbury opens the parliamentary session each year.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The monarch is the head of the Church of England. The spiritual leader of the Church of England is the Archbishop of Canterbury. The monarch has the right to select the Archbishop and other senior church officials, but usually the choice is made by the Prime Minister and a committee appointed by the Church.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Women in Britain make up about a quarter of the workforce.",
        "Women in Britain make up about half of the workforce.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Women in Britain today make up about half of the workforce.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? UK population growth has been faster in recent years.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Population growth has been faster in more recent years.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? You have to be 16 or over to buy a drink in a public house (pub) or nightclub.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To buy alcohol in a pub or night club you must be 18 or over, but people under that age may be allowed in some pubs with an adult. When they are 16, people can drink wine or beer with a meal in a hotel or restaurant (including eating areas in pubs) as long as they are with someone over 18.",
      point: "1",
    },
    {
      question: "Which will help you get along with your neighbors?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Having an untidy garden",
        "Making lots of noise, especially late at night",
        "Only introducing yourself to them after a year",
        "Only putting rubbish and recycling out on collection days",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You can help prevent any problems and conflicts with your neighbours by respecting their privacy and limiting how much noise you make. Also try to keep your garden tidy, and only put your refuse bags and bins on the street or in communal areas if they are due to be collected.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "After the age of 70, drivers must renew their licence every five years.",
        "After the age of 70, drivers must renew their licence every three years.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Drivers can use their driving licence until they are 70 years old. After that, the licence is valid for three years at a time. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "An example of a criminal offence is being in debt.",
        "An example of a criminal offence is carrying a weapon.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of criminal laws are:Carrying a weapon: it is a criminal offence to carry a weapon of any kind, even if it is for self-defence. This includes a gun, a knife or anything that is made or adapted to cause injury.",
      point: "1",
    },
    {
      question:
        "What is the term for the right of every adult male and female to vote?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Universal balloting",
        "Universal balloting",
        "Universal suffrage",
        "Universal voting rights",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Politicians realised that the increased number of voters meant that they needed to persuade people to vote for them if they were to be sure of being elected to Parliament. The political parties began to create organisations to reach out to ordinary voters. Universal suffrage (the right of every adult, male or female, to vote) followed in the next century.",
      point: "1",
    },
    {
      question: "Which jubilee did Queen Elizabeth II celebrate in 2012?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " Diamond Jubilee",
        "Golden Jubilee",
        "Platinum Jubilee",
        "Silver Jubilee",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Queen has reigned since her father's death in 1952, and in 2012 she celebrated her Diamond Jubilee (60 years as queen).",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Britain and Germany collaborated on developing Concorde, a supersonic passenger aircraft.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A potential victim, or someone acting for them, can apply for an order. Anyone found to have breached an order can be jailed for up to two years for contempt of court. ",
      point: "1",
    },
    {
      question:
        "To apply to become a permanent resident or citizen of the UK. You will need to be able to do which TWO of the following?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Have a good understanding of life in the UK",
        "Speak and read English",
        "Speak and read Welsh",
        "Speak more than one language",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To apply to become a permanent resident or citizen of the UK, you will need to:speak and read, Englishhave a good understanding of life in the UK.",
      point: "1",
    },
    {
      question:
        "Which TWO figures were associated with King Charles I and Parliament during the English Civil War?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Cavaliers", " Luddites", "Roundheads", " Tories"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Civil war between the king and Parliament could not now be avoided and began in 1642. The country split into those who supported the king (the Cavaliers) and those who supported Parliament (the Roundheads).",
      point: "1",
    },
    {
      question: "Where did the Vikings originate from?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Belgium and Holland",
        "Denmark and Norway",
        "France and Luxembourg",
        "Germany and Austria",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The most important minister in Parliament became known as the Prime Minister. The first man to be called this was Sir Robert Walpole, who was Prime Minister from 1721 to 1742.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Sir Robert Walpole was Prime Minister from 1700-1730.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " The most important minister in Parliament became known as the Prime Minister. The first man to be called this was Sir Robert Walpole, who was Prime Minister from 1721 to 1742.",
      point: "1",
    },
    {
      question: "In relation to Ireland, what title did Henry VIII assume?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Commander", "Head", "King", "Lieutenant"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During this period, Ireland was an almost completely Catholic country. Henry VII and Henry VIII had extended English control outside the Pale and had established English authority over the whole country. Henry VIII took the title 'King of Ireland'.",
      point: "1",
    },
    {
      question:
        "What happened when Charles I tried to impose a revised Prayer Book onto the Presbyterian Church in Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "England and Wales rebelled",
        "It was accepted after some amendments",
        " It was very popular across the Union",
        "The Scottish rebelled and formed an army",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He tried to impose this Prayer Book on the Presbyterian Church in Scotland and this led to serious unrest. A Scottish army was formed and Charles could not find the money he needed for his own army without the help of Parliament.",
      point: "1",
    },
    {
      question: "Why did Henry VIII marry Anne of Cleves?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " For political reasons",
        "He loved her",
        "She was beautiful",
        "To have a male heir",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Anne of Cleves - Anne was a German princess. Henry married her for political reasons but divorced her soon after.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Henry VIII continued his father's work to centralise the administrative powers of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When he died, his son Henry VIII continued the policy of centralizing power.",
      point: "1",
    },
    {
      question:
        "What did St Augustine and St Columba do during the Anglo-Saxon period?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "They invented new farming techniques.",
        "They were courageous warriors.",
        "They were early Christian missionaries",
        " They were leaders of an uprising in Wales",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Missionaries from Ireland spread the religion in the north. The most famous of these were St Patrick, who would become the patron saint of Ireland, and St Columba, who founded a monastery on the island of Iona, off the coast of what is now Scotland. St Augustine led missionaries from Rome, who spread Christianity in the south.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question:
        " After Protestants, which is the biggest denomination of Christianity in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Christian Science",
        "Ethiopian Orthodox",
        "Jehovah's Witnesses",
        "Roman Catholics",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also other denominations of Christianity, the biggest of which is Roman Catholic.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first professional UK football clubs were formed in 1066",
        "The first professional UK football clubs were formed in the late 19th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Football is the UK's most popular sport. It has a long history in the UK and the first professional football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital cities of the nations of the UK are London, Cardiff, Edinburgh and Belfast.",
        "The capital cities of the nations of the UK are London, Swansea, Glasgow and Dublin.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The capital city of the UK is London ; Scotland: The capital city of Scotland is Edinburgh ; Wales: The capital city of Wales is Cardiff ; Northern Ireland: The capital city of Northern Ireland is Belfast",
      point: "1",
    },
    {
      question:
        "Which Hindu and Sikh festival is typically celebrated in October or November?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Diwali", "Eid ul Adha", "Hanukkah", "Vaisakhi"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Diwali normally falls in October or November and lasts for five days. It is often called the Festival of Lights. It is celebrated by Hindus and Sikhs. It celebrates the victory of good over evil and the gaining of knowledge. There are different stories about how the festival came about. There is a famous celebration of Diwali in Leicester.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?It is legal to carry a weapon if it is for self defence.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of criminal laws are:Carrying a weapon: it is a criminal offence to carry a weapon of any kind, even if it is for self-defence. This includes a gun, a knife or anything that is made or adapted to cause injury.",
      point: "1",
    },
    {
      question:
        "Since which year has the Prime Minister had the power to nominate life peers to the House of Lords?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1925", "1958", "1999", "2012"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Since 1958, the Prime Minister has had the power to nominate peers just for their own lifetime. These are called life peers. They have usually had an important career in politics, business, law or another profession. Life peers are appointed by the monarch on the advice of the Prime Minister. They also include people nominated by the leaders of the other main political parties or by an independent Appointments Commission for non-party peers.",
      point: "1",
    },
    {
      question: "Which TWO of these groups are ineligible for election?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Civil servants",
        "Health Service workers",
        "Members of the armed forces",
        "Members of the police force",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most citizens of the UK, the Irish republic or the Commonwealth aged 18 or over can stand for public office. There are some exceptions, including:Members of the armed forces,Civil servants,People found guilty of certain criminal offences.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "George and Robert Stephenson were famous pioneers of agricultural changes.",
        "George and Robert Stephenson were famous pioneers of railway engines.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "ust before Victoria came to the throne, the father and son George and Robert Stephenson pioneered the railway engine and a major expansion of the railways took place in the Victorian period.",
      point: "1",
    },
    {
      question: "With which group of people are suffragettes associated?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Children", "Men", "Migrants", "Women"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They formed the women's suffrage movement and became known as 'suffragettes'.",
      point: "1",
    },
    {
      question: "What happened in 1215 to change the powers of the king?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Magna Carta",
        "The Black Death",
        "The Domesday Book",
        "The Reform Act",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were few formal limits to the king's power until 1215. In that year, King John was forced by his noblemen to agree to a number of demands. The result was a charter of rights called the Magna Carta (which means the Great Charter).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Highland Clearances occurred in Ireland",
        "The Highland Clearances occurred in Scotland.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A process began which became known as the 'Highland Clearances'. Many Scottish landlords destroyed individual small farms (known as 'crofts') to make space for large flocks of sheep and cattle.",
      point: "1",
    },
    {
      question: "Who or what were the 'clans'?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "English lords",
        "Prominent families in England and Wales",
        "Prominent families in Scotland and Ireland",
        "Welsh landowners",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the north of Scotland and Ireland, land was owned by members of the 'clans' (prominent families).",
      point: "1",
    },
    {
      question:
        "In the early 1970s, Britain accepted 28,000 individuals of Indian origin who had been compelled to leave where?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["China", "South Africa", "The West Indies", "Uganda"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " Even so, during the early 1970s, Britain admitted 28,000 people of Indian origin who had been forced to leave Uganda.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1690. Following his unsuccessful attempt to regain his throne in Ireland, James II fled back to the Netherlands",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "William defeated James II at the Battle of the Boyne in Ireland in 1690, an event which is still celebrated by some in Northern Ireland today. William re-conquered Ireland and James fled back to France.",
      point: "1",
    },
    {
      question: "Who ascended to the throne following the demise of Edward VI?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "His cousin Mary",
        "His half-sister Mary",
        "His wife Mary",
        "Mary, Queen of Scots",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Edward died at the age of 15 after ruling for just over six years, and his half-sister Mary became queen.",
      point: "1",
    },
    {
      question:
        "Which of these sectors was not nationalized by the post-war Labour government?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Coal mining",
        "Farming",
        "Gas, electric and water supplies",
        "The railways",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The government took into public ownership (nationalised) the railways, coal mines and gas, water and electricity supplies.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Elizabethan period, English settlers began to colonise America",
        "During the Elizabethan period, English settlers began to colonise Australia",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Mary Peters was an author who was later made a Dame in recognition of her work promoting literacy.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Born in Manchester, Mary Peters moved to Northern Ireland as a child. She was a talented athlete who won an Olympic gold medal in the pentathlon in 1972. After this, she raised money for local athletics and became the team manager for the women's British Olympic team. She continues to promote sport and tourism in Northern Ireland and was made a Dame of the British Empire in 2000 in recognition of her work.",
      point: "1",
    },
    {
      question: "Who commissioned the construction of the Tower of London?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Oliver Cromwell",
        "Queen Elizabeth II",
        "William the Conqueror",
        "Winston Churchill",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The White Tower in the Tower of London is an example of a Norman castle keep, built on the orders of William the Conqueror",
      point: "1",
    },
    {
      question:
        "Which TWO festivals or traditions are observed annually in November?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Bonfire Night",
        "Father's Day",
        "Remembrance Day",
        "Valentine's Day",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Bonfire Night, 5 November, is an occasion when people in Great Britain set off fireworks at home or in special displays. ; Remembrance Day, 11 November, commemorates those who died fighting for the UK and its allies.",
      point: "1",
    },
    {
      question: "Which TWO of the following are recognized UK landmarks?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Edinburgh Castle",
        "National Trust",
        "The Eisteddfod",
        "The London Eye",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The London Eye is situated on the southern bank of the River Thames and is a Ferris wheel that is 443 feet (135 metres) tall. The Castle is a dominant feature of the skyline in Edinburgh, Scotland. It has a long history, dating back to the early Middle Ages.",
      point: "1",
    },
    {
      question: "Who penned the poem She Walks in Beauty?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Elizabeth Browning",
        "Lord Byron",
        "Sir John Betjeman",
        "Sir Walter de la Mare",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "She walks in beauty, like the night, Of cloudless climes and starry skies; And all that's best of dark and bright, Meet in her aspect and her eyes'(Lord Byron, 1788-1824 - She Walks in Beauty)",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The British constitute is contained in a single written document.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The British constitution is not written down in any single document, and therefore it is described as 'unwritten'.",
      point: "1",
    },
    {
      question: "Which TWO types of cases are adjudicated in County Courts?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Breaches of contract",
        "Divorce",
        "Minor criminal offences",
        "Murder",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "County Courts deal with a wide range of civil disputes. These include people trying to get back money that is owed to them, cases involving personal injury, family matters, breaches of contract, and divorce. ",
      point: "1",
    },
    {
      question: "What happens at a polling station or polling place?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "People take their driving",
        "People vote in elections",
        "Taxes are collected",
        " The census is collected",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People vote in elections at places called polling stations, or polling places in Scotland. Before the election you will be sent a poll card. This tells you where your polling station or polling place is and when the election will take place. ",
      point: "1",
    },
    {
      question:
        "What is the meaning of MOT, a test that vehicles more than three years old must pass annually?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Maintenance of Transportation",
        "Migrant Order for Transport",
        "Ministry of Transport",
        "Motor-vehicle Optimisation Test",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You must also have valid motor insurance. It is a serious criminal offence to drive without insurance. If your vehicle is over three years old, you must take it for a Ministry of Transport (MOT) test every year. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Adult citizens of other EU states may vote in General Elections.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Adult citizens of the UK, and citizens of the Commonwealth and the Irish Republic who are resident in the UK, can vote in all public elections. Adult citizens of other EU states who are resident in the UK can vote in all elections except General Elections",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Florence Nightingale is famous for her work on children's education in the 19th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1860 she established the Nightingale Training School for nurses at St Thomas' Hospital in London.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Hadrian's Wall was built by the Picts (ancestors of the Scottish people) to keep out the Romans.",
        "Hadrian's Wall was built on the orders of the Roman Emperor Hadrian.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Areas of what is now Scotland were never conquered by the Romans, and the Emperor Hadrian built a wall in the north of England to keep out the Picts (ancestors of the Scottish people).",
      point: "1",
    },
    {
      question: "Why is Henry VIII regarded as an important English monarch?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "He broke from the Church of Rome.",
        "He established the RAF",
        "He invaded Sweden",
        "He re-established the Catholic Church in England",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry VIII was most famous for breaking away from the Church of Rome and marrying six times.",
      point: "1",
    },
    {
      question:
        "Which country did Germany invade in 1939, leading to the UK declaring war?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Austria", "Finland", "France", " Poland"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The British government tried to avoid another war. However, when Hitler invaded Poland in 1939, Britain and France declared war in order to stop his aggression.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Which of these statements is correct?",
        "Shakespeare wrote 'We will fight them on the beaches.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lines from his plays and poems which are often still quoted include:Once more unto the breach (Henry V) ;Once more unto the breach (Henry V) ;A rose by any other name(Romeo and Juliet) ;All the world's a stage (As you like it) ;The darling buds of May (Sonnet 18- Shall I Compare Thee To A Summer's Day).",
      point: "1",
    },
    {
      question:
        "The enlightenment led to major development in which TWO areas?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["History", "Politics", "Science", " Theatre"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the 18th century, new ideas about politics, philosophy and science were developed. This is often called 'the Enlightenment'.",
      point: "1",
    },
    {
      question:
        "Who did William the Conqueror defeat at the Battle of Hastings in 1066?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Boudicca", "Kenneth MacAlpin", "Kin Cnut", "King Harold"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings. Harold was killed in the battle. William became king of England and is known as William the Conqueror.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Following the abolition of slavery, two million Chinese and Indian workers were employed to replace the freed slaves.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After 1833, 2 million Indian and Chinese workers were employed to replace the freed slaves.",
      point: "1",
    },
    {
      question:
        "James II invaded Ireland in an attempt to regain the throne with an army from which country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["France", "Germany", "Scotland", "Wales"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James II wanted to regain the throne and invaded Ireland with the help of a French army.",
      point: "1",
    },
    {
      question:
        "Following the Bill of Rights, monarchs had to seek Parliament's renewal of funding for which TWO purposes annually?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Army", "Building of castles", "Navy", " Palaces"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Every year the monarch had to ask Parliament to renew funding for the army and the navy.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Lancelot 'Capability' Brown and Gertrude Jekyll were famous characters in a Sherlock Holmes story.",
        " Lancelot 'Capability' Brown and Gertrude Jekyll were famous garden designers.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lancelot 'Capability' Brown designed the grounds around country houses so that the landscape appeared to be natural, with grass, trees and lakes. He often said that a place had 'capabilities'. Later, Gertrude Jekyll often worked with Edwin Lutyens to design colourful gardens around the houses he designed.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital city of Northern Ireland is Belfast",
        "The capital city of Northern Ireland is Swansea",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The capital city of Northern Ireland is Belfast",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Newport, Swansea and Cardiff are cities in Scotland",
        "Plymouth, Norwich and Leeds are cities in England.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Plymouth, Norwich and Leeds are cities in England.",
      point: "1",
    },
    {
      question: "Why is Sir Edwin Lutyens renowned?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "He invented the World Wide Web.",
        "He was a 20th-century architect",
        "He was the first UK prime Minister.",
        "He won a gold medal at the London 2012 Olympic Games.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 20th century, Sir Edwin Lutyens had an influence throughout the British Empire. He designed New Delhi to be the seat of government in India.",
      point: "1",
    },
    {
      question: "Police forces are headed by whom?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Chief Constables", "Generals", "Mayors", " Ministers"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The police are organised into a number of separate police forces headed by Chief Constables. ",
      point: "1",
    },
    {
      question:
        "Which musical composition was not created by Benjamin Britten?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Young Person's Guide to the Orchestra",
        "Billy Budd",
        " Peter Grimes",
        "The Planets",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "More recently, important composers include Gustav Hoist (1874¬-1934) whose work includes The Planets, a suite of pieces themed around the planets of the solar system. He adapted Jupiter, part of the Planets suite, as the tune for I vow to thee my country, a popular hymn in British churches.",
      point: "1",
    },
    {
      question: "How frequently is a General Election conducted?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "At least every five years",
        "At least every four years",
        "At least every two years",
        " Every year",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected at a General Election, which is held at least every five years.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Everyone pays National Insurance Contributions.",
        "Most working people pay National Insurance Contributions.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Almost everybody in the UK who is in paid work, including self-employed people, must pay National Insurance Contributions. The money raised from National Insurance Contributions is used to pay for state benefits and services such as the state retirement pension and the National Health Service (NHS).",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Old Bailey is the world's most famous Magistrates Court.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Old Bailey is probably the most famous criminal court in the world ",
      point: "1",
    },
    {
      question: "What type of Church is the Church of Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "A right to a fair trial",
        "Free groceries for everyone",
        "Freedom of speech",
        "Long lunch breaks on Friday",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In return, the UK offers:freedom of belief and religion,freedom of speech,freedom from unfair discrimination,a right to a fair trial",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Isle of Man is a Crown dependency",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several islands which are closely linked with the UK but are not part of it: the Channel Islands and the Isle of Man. These have their own governments and are called 'Crown dependencies'.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Elizabeth I had very good relations with Parliament.",
        "Elizabeth I handled parliament very badly during her reign.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth I was very skilled at managing Parliament. During her reign, she was successful in balancing her wishes and views against those of the House of Lords and those of the House of Commons, which was increasingly Protestant in its views.",
      point: "1",
    },
    {
      question: "Which TWO are English Civil War battles?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Hastings", "Marston Moor", " Naseby", "Waterloo"],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The king's army was defeated at the Battles of Marston Moore and Naseby.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Reformation failed in Scotland and the country remained Strongly Catholic.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Scotland had also been strongly influenced by Protestant ideas. In 1560, the predominantly Protestant Scottish Parliament abolished the authority of the Pope in Scotland and Roman Catholic religious services became illegal.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Norman French influenced the development of the English language as we know today.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Middle Ages saw the development of a national culture and identity. After the Norman Conquest, the king and his noblemen had spoken Norman French and the peasants had continued to speak Anglo-Saxon. Gradually these two languages combined to become one English language.",
      point: "1",
    },
    {
      question:
        "During the Victorian period, who led reforms to improve conditions for which section of society?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The aristocracy",
        " The clergy",
        "The middle classes",
        "The poor",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Within the UK, the middle classes became increasingly significant and a number of reformers led moves to improve conditions of life for the poor.",
      point: "1",
    },
    {
      question:
        "Throughout the 18th century, Britain engaged in numerous wars with which country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["France", " India", "South Africa", "Spain"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the 18th century, Britain fought a number of wars with France.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the reign of Charles I, Irish Catholics supported the views of the Puritans.",
        "During the reign of Charles I, Parliament attempted to take control of the English army following a rebellion in Ireland.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Another rebellion began in Ireland because the Roman Catholics in Ireland were afraid of the growing power of the Puritans. Parliament took this opportunity to demand control of the English army - a change that would have transferred substantial power from the king to Parliament.",
      point: "1",
    },
    {
      question: "Who were the Picts?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Ancestors of the English people",
        "Ancestors of the Irish people",
        "Ancestors of the Scottish people",
        "Ancestors of the Welsh people",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Areas of what is now Scotland were never conquered by the Romans, and the Emperor Hadrian built a wall in the north of England to keep out the Picts (ancestors of the Scottish people).",
      point: "1",
    },
    {
      question: "St George is the patron saint of which UK country?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["England", "Northern Ireland", "Scotland", "Wales"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England, Scotland, Wales and Northern Ireland each have a national saint, called a patron saint. Each saint has a special day:1 March: St David's Day, Wales ;17 March: St Patrick's Day, Northern Ireland ;23 April. St George's Day, England ;30 November: St Andrew's Day, Scotland.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Most people in the UK live in towns and cities",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most people live in towns and cities but much of Britain is still countryside",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Pantomimes are plays based on fairy stories.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many theatres produce a pantomime at Christmas time. They are based on fairy stories and are light-hearted plays with music and comedy, enjoyed by family audiences.",
      point: "1",
    },
    {
      question: "Traditionally, at which festival are mince pies consumed?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Christmas", "Diwali", "Easter", "Vaisakhi"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Christmas is celebrated in a traditional way. People usually spend the day at home and eat a special meal, which often includes roast turkey, Christmas pudding and mince pies.",
      point: "1",
    },
    {
      question: "What do we know about the life of Anne Boleyn?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "They are all famous British athletes",
        "They have all been Prime Minister",
        "They have all been awarded the Nobel Prize for literature.",
        "They were part of the first British expedition to the North Pole.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Several British writers, including the novelist Sir William Golding, the poet Seamus Heaney, and the playwright Harold Pinter, have won the Nobel Prize in Literature.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sir Steve Redgrave is a famous film actor who has won several BAFTAs.",
        "Sir Steve Redgrave is a famous rower who won gold medals in five consecutive Olympics Games.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Steve Redgrave (1962-) won gold medals in rowing in five consecutive Olympic Games and is one of Britain's greatest Olympians.",
      point: "1",
    },
    {
      question:
        "Since 1927 the BBC has organised which series of famous concerts?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Aldeburgh Festival",
        "Glastonbury",
        "The Eisteddfod",
        "The Proms",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Proms is an eight-week summer season of orchestral classical music that takes place in various venues, including the Royal Albert Hall in London. It has been organised by the British Broadcasting Corporation (BBC) since 1927.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All the national saints' days are celebrated but only in England and Wales are they official holidays.",
        "All the patron saints' days are celebrated but only in Scotland and Northern Ireland are they official holidays.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Only Scotland and Northern Ireland have their patron saint's day as an official holiday (although in Scotland not all businesses and offices will dose).",
      point: "1",
    },
    {
      question: "Which countries did not help establish the EEC?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Belgium", "Germany", " Ireland", " Luxembourg"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The European Union (EU), originally called the European Economic Community (EEC), was set up by six western European countries (Belgium, France, Germany, Italy, Luxembourg and the Netherlands) who signed the Treaty of Rome on 25 March 1957. The UK originally decided not to join this group but it became a member in 1973. There are now 27 EU member states (see table below). Croatia will also become a member state in 2013.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Members of the House of Lords are not elected by the people.",
        "Members of the House of Lords are voted in by members of the House of Commons.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members of the House of Lords, known as peers, are not elected by the people and do not represent a constituency. ",
      point: "1",
    },
    {
      question:
        "What sorts of stories were depicted in the stained glass windows of many cathedrals built in The Middle Ages?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Minor criminal cases",
        "Serious offences",
        "Small claims procedures",
        "Youth cases",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland, serious offences are tried in front of a judge and a jury in a Crown Court. In Scotland, serious cases are heard in a Sheriff Court with either a sheriff or a sheriff with a jury. ",
      point: "1",
    },
    {
      question: "Which is a core value of the civil service?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Bribery", "Integrity", "Laziness", "Party loyalty"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Civil servants are expected to carry out their role with dedication and a commitment to the civil service and its core values. These are: integrity, honesty, objectivity and impartiality (including being politically neutral). ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1588 the English fleet defeated a large French fleet of ships that intended to land an army in England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth became one of the most popular monarchs in English history, particularly after 1588, when the English defeated the Spanish Armada (a large fleet of ships), which had been sent by Spain to conquer England and restore Catholicism.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Boys in the UK typically achieve higher qualifications than girls upon leaving school.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were few formal limits to the king's power until 1215. In that year, King John was forced by his noblemen to agree to a number of demands. The result was a charter of rights called the Magna Carta (which means the Great Charter). The Magna Carta established the idea that even the king was subject to the law.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The threat of Viking attack caused the people in the north to unite, and the term Scotland was used to describe that country.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the north, the threat of attack by Vikings had encouraged the people to unite under one king, Kenneth MacAlpin. The term Scotland began to be used to describe that country.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The small claims procedure is an informal way for people to settle minor disputes.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the UK, employers can discriminate against different groups of people.",
        "UK laws ensure people are not treated unfairly in any area of work or life.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "UK laws ensure that people are not treated unfairly in any area of life or work because of their age, disability, sex, pregnancy and maternity, race, religion or belief, sexuality or marital status. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Magistrates must be specially trained legal experts who have been solicitors for three years.",
        "Magistrates usually work unpaid and do not need legal qualifications",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Magistrates and Justices of the Peace (JPs) are members of the local community. In England, Wales and Scotland they usually work unpaid and do not need legal qualifications. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Wales, Scotland and Northern Ireland each have devolved administrations which give them total control over all policies and laws.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Policy and laws governing defence, foreign affairs, immigration, taxation and social security all remain under central UK government control. However, many other public services, such as education, are controlled by the devolved administrations.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Life peers in the House of Lords can pass on their title to their first-born child",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They have usually had an important career in politics, business, law or another profession. Life peers are appointed by the monarch on the advice of the Prime Minister. They also include people nominated by the leaders of the other main political parties or by an independent Appointments Commission for non-party peers.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The MacDonald clan of Glencoe were massacred.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "All Scottish clans were required formally to accept William as king by taking an oath. The MacDonalds of Glencoe were late in taking the oath and were all killed.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? All MPs are affiliated with one of the main political parties.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Each MP represents a parliamentary constituency, which is a small area of the country. ",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Britain had colonies in southern Africa during the 19th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain and France developed Concorde, the world's only supersonic passenger aircraft.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? By around AD 600, Anglo-Saxon kingdoms were established in Britain.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The inventor of the World Wide Web, Sir Tin Berners-Lee (1955-), is British. Information was successfully transferred via the web for the first time",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "11 November commemorates soldiers who died in World War One as well as those who have died in all conflicts involving the UK since then.",
        "11 November only commemorates soldiers who died in World War One.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Remembrance Day, 11 November, commemorates those who died fighting for the UK and its allies. Originally it commemorated the dead of the First World War, which ended on 11 November 1918.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All shops in the UK must close on Sunday",
        "Many shops in the UK are open seven days a week",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most shops in the UK are open seven days a week, although trading hours on Sundays and public holidays are generally reduced.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Dundee and Aberdeen are cities in Northern Ireland.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Dundee and Aberdeen are cities of Scotland.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The British Broadcasting Corporation (BBC) is financed by selling adverting space during television programmes.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The money from TV licences is used to pay for the British Broadcasting Corporation (BBC).",
      point: "1",
    },
    {
      question: "Near which of these cities is Europe's longest dry ski slope?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Aberdeen", "Edinburgh", "Inverness", "Pitlochrie"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Skiing is increasingly popular in the UK. Many people go abroad to ski and there are also dry ski slopes throughout the UK. Skiing on snow may also be possible during the winter. There are five ski centres in Scotland, as well as Europe's longest dry ski slope near Edinburgh.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Chequers is the Prime Minister's country house.",
        "Chequers is the Prime Minister's house in London.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister (PM) is the leader of the political party in power. He or she appoints the members of the cabinet (see below) and has control over many important public appointments. The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. He or she also has a country house outside London called Chequers.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? A civil law case involves filing a legal complaint concerning a faulty purchase.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of civil laws are:Consumer rights: an example of this is a dispute about faulty goods or services.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?There are no differences between the court systems of England, Northern Ireland, Scotland and Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are some differences between the court systems in England and Wales, Scotland and Northern Ireland.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Council of Europe has the power to make laws, which are binding in member states.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Council of Europe is separate from the EU. It has 47 member countries, including the UK, and is responsible for the protection and promotion of human rights in those countries. It has no power to make laws but draws up conventions and charters, the most well-known of which is the European Convention on Human Rights and Fundamental Freedoms, usually called the European Convention on Human Rights.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In the UK you are expected to respect the rights of others to have their own opinions.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you wish to be a permanent resident or citizen of the UK, you should:respect and obey the law,respect the rights of others, including their right to their own opinions,treat others with fairness,look after yourself and your family,look after the area in which you live and the environment.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "On becoming a UK citizen or permanent resident, you can choose which laws and responsibilities you want to accept.",
        "On becoming a UK citizen or permanent resident, you will be agreeing to respect the laws values and traditions of the UK.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You will be agreeing to accept the responsibilities which go with permanent residence and to respect the laws, values and traditions of the UK.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK does not allow citizens or permanent residents to voice options publicly.",
        "The UK offers its citizens and permanent residents freedom of speech.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In return, the UK offers:freedom of belief and religion,freedom of speech,freedom from unfair discrimination,a right to a fair trial,a right to join in the election of a government.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The United Kingdom consists of England, Ireland, Scotland and Wales.",
        "The United Kingdom consists of England, Scotland, Wales, and Northern Ireland",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is made up of England, Scotland, Wales and Northern Ireland. The rest of Ireland is an independent county.",
      point: "1",
    },
    {
      question: " Which TWO are British overseas territories?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: ["Falkland Islands", "Hawaii", "St Helena"],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several British overseas territories in other parts of the world, such as St Helena and the Falkland Islands. They are also linked to the UK but are not a part of it.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Isle of Man is a Crown dependency",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are also several islands which are closely linked with the UK but are not part of it: the Channel Islands and the Isle of Man. These have their own governments and are called 'Crown dependencies'.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Elizabeth I had very good relations with Parliament.",
        "Elizabeth I handled parliament very badly during her reign.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth I was very skilled at managing Parliament. During her reign, she was successful in balancing her wishes and views against those of the House of Lords and those of the House of Commons, which was increasingly Protestant in its views.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Wales united with England during the reign of Henry VIII.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the reign of Henry VIII, Wales became formally united with England by the Act for the Government of Wales. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Bonnie Prince Charlie became King Charles II of England in 1660.",
        "Charles, king of Scotland, was restored as King Charles II of England in 1660.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Briton Sir Tim Berners-Lee invented the World Wide Web, which was successfully utilized on December 25, 1990.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1996, two British scientists, Sir Ian Wilmot (1944-) and Keith Campbell (1954-2012), led a team which was the first to succeed in cloning a mammal, Dolly the sheep.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1805 at the Battle of Trafalgar, Admiral Nelson defeated the German fleet.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain's navy fought against combined French and Spanish fleets, winning the Battle of Trafalgar in 1805.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1588 the English defeated a German invasion fleet of bomber planes.",
        "In 1588 the English defeated a Spanish invasion fleet of ships.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth became one of the most popular monarchs in English history, particularly after 1588, when the English defeated the Spanish Armada (a large fleet of ships), which had been sent by Spain to conquer England and restore Catholicism.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution introduced changes in the banking system in the 1970's.",
        "The Industrial Revolution was the rapid development of industry in the 18th and 19th centuries.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'Divine Rights of Kings' meant that the English king should rule France.",
        "The 'Divine Rights of Kings' meant that the king was appointed by God.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Mary, Queen of Scots was a cousin of Queen Elizabeth I.",
        "Mary, Queen of Scots was unrelated to Queen Elizabeth I.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was Elizabeth I's cousin and hoped that Elizabeth might help her, but Elizabeth suspected Mary of wanting to take over the English throne, and kept her a prisoner for 20 years.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the 18th century two political groups emerged, the Conservatives and the Liberals.",
        "In the 18th century two political groups emerged, the Whigs and the Tories.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There were two main groups in Parliament, known as the Whigs and the Tories.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The 'Restoration' refers to the re-establishment of Catholicism as the official Church in the 17th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland. Charles II made it clear that he had 'no wish to go on his travels again'. He understood that he could not always do as he wished but would sometimes need to reach agreement with Parliament. Generally, Parliament supported his policies. The Church of England again became the established official Church. Both Roman Catholics and Puritans were kept out of power.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? When Queen Anne died, a German, George of Hanover, became the next King of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When Queen Anne died in 1714, Parliament chose a German, George I, to be the next king, because he was Anne's nearest Protestant relative.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Richard Arkwright developed efficient and profitable factories.",
        "Richard Arkwright developed new farming methods in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first person to use the title Prime Minister was Sir Christopher Wren.",
        "The first person to use the title Prime Minister was Sir Robert Walpole.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The most important minister in Parliament became known as the Prime Minister. The first man to be called this was Sir Robert Walpole, who was Prime Minister from 1721 to 1742.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'plantation' settlement in Ireland during the 17th century led to Protestant farmers replacing Catholic landowners.",
        "The 'plantation' settlements in Ireland during the 17th century led to Catholic farmers replacing Protestant landowners.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The English government encouraged Scottish and English Protestants to settle in Ulster, the northern province of Ireland, taking over the land from Catholic landholders. These settlements were known as plantations.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sake Dean Mahomet introduced curry houses and shampooing to Britain from India.",
        "Sake Dean Mahomet is famous for introducing tea-drinking and bungalows to Britain from India.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1810 he opened the Hindoostanee Coffee House in George Street, London. It was the first curry house to open in Britain. Mahomet and his wife also introduced 'shampooing', the Indian art of head massage, to Britain.",
      point: "1",
    },
    {
      question: "When was the last successful invasion of England?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["1066", "1415", "1642", "1940"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings. Harold was killed in the battle. William became king of England and is known as William the Conqueror.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "American colonists were eventually defeated by the British.",
        "In 1776 some American colonies declared their independence from Britain.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the First World War Winston Churchill was the British Prime Minister.",
        "During the Second World War Winston Churchill was the British Prime Minister.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "At this time of national crisis, Winston Churchill became Prime Minister and Britain's war leader.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? We shall fight them on the beaches 'is a famous quote from a speech by Queen Elizabeth I about the Spanish Armada.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "W e shall fight on the beaches, we shall fight on the landing grounds, we shall fight in the fields and in the streets, we shall fight in the hills; we shall never surrender' Speech to the House of Commons after Dunkirk 1940",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution is the name given to the rapid development of industry in Britain in the 20th century.",
        "The Industrial Revolution is the name given to the rapid development of industry that began in the 18th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Florence Nightingale is associated with policing.",
        "Florence Nightingale is associated with the development of nursing.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1860 she established the Nightingale Training School for nurses at St Thomas' Hospital in London.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? British scientists were the first to successfully clone a mammal. The subject was a rabbit.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the 18th century, Britain fought a number of wars with France.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Reform Act of 1832 increased the number of electors.",
        "The Reform Act of 1832 increased the power of the House of Lords.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Reform Act of 1832 decreased the number of voters.",
        "The Reform Act of 1832 increased the number of voters.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Reform Act of 1832 had greatly increased the number of people with the right to vote",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Battle of Britain in 1940 was fought at sea.",
        "The Battle of Britain in 1940 was fought in the skies.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Germans waged an air campaign against Britain, but the British resisted with their fighter planes and eventually won the crucial aerial battle against the Germans, called 'the Battle of Britain', in the summer of 1940.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "George and Robert Stephenson were famous pioneers of agricultural changes.",
        "George and Robert Stephenson were famous pioneers of railway engines.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Just before Victoria came to the throne, the father and son George and Robert Stephenson pioneered the railway engine and a major expansion of the railways took place in the Victorian period.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Roman army left England after 150 years to defend other parts of their Empire.",
        "The Roman army left England after 400 years to defend other parts of their Empire.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Roman army left Britain in AD 410 to defend other parts of the Roman Empire and never returned.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Civil War between Charles I and Parliament in the mid-17th century led to Oliver Cromwell becoming king of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "England declared itself a republic, called the Commonwealth. It no longer had a monarch. For a time, it was not totally clear how the country would be governed. For now, the army was in control.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Swinging Sixties was a period of social change.",
        "The Swinging sixties was a period of religious change.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The decade of the 1960s was a period of significant social change. It was known as 'the Swinging Sixties'.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In 1921 a treaty gave independence to the south of Ireland.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1921 a peace treaty was signed and in 1922 Ireland became two countries.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By the middle of the 15th century the last Welsh rebellions had been defeated.",
        "By the middle of the 17th century the last Welsh rebellion had been defeated.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By the middle of the 15th century the last Welsh rebellions had been defeated.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By 1400 the preferred language of English court was English",
        "By 1400 the preferred language of English court was French",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, in England, official documents were being written in English, and English had become the preferred language of the royal court and Parliament.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Anglo-Saxon kingdoms in England were united under King Alfred the Great.",
        "The Anglo-Saxon kingdoms were united under King Kenneth MacAlpin.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Anglo-Saxon kingdoms in England united under King Alfred the Great, who defeated the Vikings.",
      point: "1",
    },
    {
      question:
        "In which part of the British Empire did the Boer War of 1899-1902 occur?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Australia", "Canada", "India", "South Africa"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Boer War of 1899 to 1902 made the discussions about the future of the Empire more urgent. The British went to war in South Africa with settlers from the Netherlands called the Boers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Black Death brought about major changes in English society due to the number of people who died.",
        "The Black Death was a plague that only had an impact in Ireland, where many people died.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1348, a disease, probably a form of plague, came to Britain. This was known as the Black Death. One third of the population of England died and a similar proportion in Scotland and Wales. This was one of the worst disasters ever to strike Britain.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Battle of Agincourt is commemorated in the Bayeux Tapestry",
        "The Battle of Hastings is commemorated in the Bayeux Tapestry",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The battle is commemorated in a great piece of embroidery, known as the Bayeux Tapestry, which can still be seen in France today.",
      point: "1",
    },
    {
      question:
        "Which TWO of the following inventors were significant in the 20th century?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Alan Turing",
        "George Stephenson",
        "Isambard Kingdom Brunel",
        "Tim Berners-Lee",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A Turing machine is a theoretical mathematical device invented by Alan Turing (1912-54), a British mathematician, in the 1930s; The inventor of the World Wide Web, Sir Tin Berners-Lee (1955-), is British",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Highland Clearances occurred in Ireland",
        "The Highland Clearances occurred in Scotland.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A process began which became known as the 'Highland Clearances'. Many Scottish landlords destroyed individual small farms (known as 'crofts') to make space for large flocks of sheep and cattle.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Hadrian's Wall was built by the Picts (ancestors of the Scottish people) to keep out the Romans.",
        "Hadrian's Wall was built on the orders of the Roman Emperor Hadrian.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Areas of what is now Scotland were never conquered by the Romans, and the Emperor Hadrian built a wall in the north of England to keep out the Picts (ancestors of the Scottish people).",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Council of Europe possesses legislative authority binding in member states.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Catherine Howard - Catherine was a cousin of Anne Boleyn. She was also accused of taking lovers and executed.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? During the Great Depression of the 1930s the UK had high levels of employment.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, in 1929, the world entered the 'Great Depression' and some parts of the UK suffered mass unemployment.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "James VI of Scotland was NOT related to Queen Elizabeth I of England.",
        "James VI of Scotland was related to Queen Elizabeth I of England.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Elizabeth I never married and so had no children of her own to inherit her throne. When she died in 1603 her heir was her cousin James VI of Scotland.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Shakespeare wrote 'To be or not to be'.",
        "Shakespeare wrote 'We will fight them on the beaches.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lines from his plays and poems which are often still quoted include:Once more unto the breach (Henry V) ; To be or not to be (Hamlet) ; A rose by any other name(Romeo and Juliet) ; All the world's a stage (As you like it) ; The darling buds of May (Sonnet 18- Shall I Compare Thee To A Summer's Day).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The War of the Roses was between the houses of Lancaster and York.",
        "The Wars of the Roses were between the houses of Windsor and Tudor",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1455, a civil war was begun to decide who should be king of England. It was fought between the supporters of two families: the House of Lancaster and the House of York.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Industrial Revolution, canals were built to link the factories to cities and ports.",
        "During the industrial Revolution, canals were built to link the mines in the mountains with factories in the cities.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Better transport links were needed to transport raw materials and manufactured goods. Canals were built to link the factories to towns and cities and to the ports, particularly in the new industrial areas in the middle and north of England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Elizabeth I came to the throne when, following a long reign, her cousin Mary died.",
        "Elizabeth I came to the throne when, following a short reign, her half-sister Mary died.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mary was a devout Catholic and persecuted Protestants (for this reason, she became known as 'Bloody Mary'). Mary also died after a short reign and the next monarch was her half-sister, Elizabeth, the daughter of Henry VIII and Anne Boleyn.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The 1950s were a time of serious unrest in Northern Ireland",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The 1970s were also a time of serious unrest in Northern Ireland.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Cardiff, Swansea, and Newport are cities in England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Roman army left Britain in AD 410 to defend other parts of the Roman Empire and never returned. Britain was again invaded by tribes from northern Europe: the Jute's, the Angles and the Saxons. The languages they spoke are the basis of modern-day English. Battles were fought against these invaders but, by about AD 600, Anglo-Saxon kingdoms were established in Britain. These kingdoms were mainly in what is now England.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The 'Divine Right of Kings' was the view held by many English monarchs that they could seize lands belonging to the nobility as they wished.",
        "The 'Divine Rights of Kings' was the view held by many English monarchs that they were directly appointed by God to rule.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I were less skilled politically. Both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule. They thought that the king should be able to act without having to seek approval from Parliament.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Commercial expansion and prosperity of the 19th century were sustained in part by the booming slave trade.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This commercial expansion and prosperity was sustained in part by the booming slave trade. While slavery was illegal within Britain itself, by the 18th century it was a fully established overseas industry, dominated by Britain and the American colonies.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "After the Black Death, there were labour shortages and peasants began to demand higher wages.",
        "The Black Death destroyed cereal crops, leading to a famine that killed many peasants.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Following the Black Death, the smaller population meant there was less need to grow cereal crops. There were labour shortages and peasants began to demand higher wages. New social classes appeared, including owners of large areas of land (later called the gentry), and people left the countryside to live in the towns. In the towns, growing wealth led to the development of a strong middle class.",
      point: "1",
    },
    {
      question:
        "What title was bestowed upon Oliver Cromwell after his campaign in Ireland and defeat of Charles II?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Chieftain", "King", "Lord Lieutenant", "Lord Protector"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After his campaign in Ireland and victory over Charles II at Worcester, Cromwell was recognised as the leader of the new republic. He was given the title of Lord Protector and ruled until his death in 1658. When Cromwell died, his son, Richard, became Lord Protector in his place but was not able to control the army or the government.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The jet engine and radar were developed in Britain in the 1930's",
        "The jet engine and radar were developed in Britain in the 1950's.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Radar was developed by Scotsman Sir Robert Watson-Watt (1892-1973), who proposed that enemy aircraft could be detected by radio waves. The first successful radar test took place in 1935. The jet engine was developed in Britain in the 1930s by Sir Frank Whittle (1907-96), a British Royal Air Force engineer officer.",
      point: "1",
    },
    {
      question:
        "During the 19th century, Britain clashed with which country due to overlapping expansion and trade interests?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["France", "Germany", "Spain", "The Netherlands"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Trading and settlements overseas sometimes brought Britain into conflict with other countries, particularly France, which was expanding and trading in a similar way in many of the same areas of the world.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "During the 16th century, the Scottish Parliament Abolished the authority of the Pope and Roman Catholic religious services became illegal.",
        "During the 16th century, the Scottish Parliament welcomed the authority of the Pope and Roman Catholics religious services became commonplace.",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Scotland had also been strongly influenced by Protestant ideas. In 1560, the predominantly Protestant Scottish Parliament abolished the authority of the Pope in Scotland and Roman Catholic religious services became illegal.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? France was the first country to industrialise on a large scale.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries. Britain was the first country to industrialise on a large scale.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Great Depression of the 1930s, parts of the UK experienced mass unemployment.",
        "The UK experienced high levels of employment during the Great Depression of the 1930s.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1929, the world entered the 'Great Depression' and some parts of the UK suffered mass unemployment.",
      point: "1",
    },
    {
      question:
        "The French Wars ended in 1815 when Napoleon was defeated by the Duke of Wellington at which battle?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Battle of Naseby",
        "Battle of Ostend",
        "Battle of Trafalgar",
        "Battle of Waterloo",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1815, the French Wars ended with the defeat of the Emperor Napoleon by the Duke of Wellington at the Battle of Waterloo. Wellington was known as the Iron Duke and later became Prime Minister.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Margaret Thatcher was the UK's first female Prime Minister.",
        "Margaret Thatcher was the UK's second female Prime Minister.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Margaret Thatcher, Britain's first woman Prime Minister, led the Conservative government from 1979 to 1990. The government made structural changes to the economy through the privatisation of nationalised industries and imposed legal controls on trade union powers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Robert Burns wrote in Scots language.",
        "Robert Burns wrote in Scots, English and a combination of both.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Known in Scotland as 'The Bard', Robert Burns was a Scottish poet. He wrote in the Scots language, English with some Scottish words, and standard English. He also revised a lot of traditional folk songs by changing or adding lyrics. Burns' best-known work is probably the song Auld Lang Syne, which is sung by people in the UK and other countries when they are celebrating the New Year (or Hogmanay as it is called in Scotland).",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Education Act of 1944 is often called The Butler Act and introduced free secondary education in England and Wales.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In this role, he oversaw the introduction of the Education Act 1944(often called 'The Butler Act'), which introduced free secondary education in England and Wales.",
      point: "1",
    },
    {
      question:
        "Which right was awarded to women by Acts of Parliament in 1870 and 1882?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The right to attend university",
        "The right to bear arms",
        "The right to keep their own earnings and property after marriage",
        "The right to work",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Acts of Parliament in 1870 and 1882 gave wives the right to keep their own earnings and property.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Colonists in North America were poorly educated and uninterested in politics.",
        "Colonists in North America were well educated and interested in ideas of liberty.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By the 1760s, there were substantial British colonies in North America. The colonies were wealthy and largely in control of their own affairs. Many of the colonist families had originally gone to North America in order to have religious freedom. They were well educated and interested in ideas of liberty.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The Domesday Book no longer exists. It was destroyed at the end of the Norman Conquest.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The people who lived there, who owned the land and what animals they owned were also listed. This was called Domesday Book. It still exists today and gives a picture of society in England just after the Norman Conquest.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? ISAF is building up the Afghan National Security Forces and helping to create a secure environment in which governance and development can be extended.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "ISAF is building up the Afghan National Security Forces and is helping to create a secure environment in which governance and development can be extended.",
      point: "1",
    },
    {
      question: "The first people lived in Britain during which period?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Ancient times",
        "The Bronze Age",
        "The Jurassic period",
        "The Stone Age",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first people to live in Britain were hunter-gatherers, in what we call the Stone Age.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The leaders of Easter uprising were transported to Australia.",
        "The leaders of the Easter uprising were executed under military law.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Irish Nationalists were not willing to wait and in 1916 there was an uprising (the Easter Rising) against the British in Dublin. The leaders of the uprising were executed under military law.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "James I and Charles I believed in the 'Divine Right of Kings' and that they could rule without the approval of Parliament",
        "James I and Charles I were very skilled at negotiating with Parliament",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James I and his son Charles I were less skilled politically. Both believed in the 'Divine Right of Kings': the idea that the king was directly appointed by God to rule. They thought that the king should be able to act without having to seek approval from Parliament.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? During the Industrial Revolution, canals were built to link factories to cities, towns and ports, particularly in the middle and north of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Canals were built to link the factories to towns and cities and to the ports, particularly in the new industrial areas in the middle and north of England.",
      point: "1",
    },
    {
      question:
        "In Scotland, what does it mean for its legal system to be 'codified'?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Based on tradition",
        "Dictated by Parliament",
        "Less complicated",
        "Written down",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, the legal system developed slightly differently and laws were 'codified' (that is, written down).",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The language of the Iron Age was part of the Celtic language family.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The language they spoke was part of the Celtic language family.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?Following the abolition of slavery, two million Chinese and Indian workers were employed to replace the freed slaves.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "After 1833, 2 million Indian and Chinese workers were employed to replace the freed slaves.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE?The symbol of the House of Lancaster was a red rose and the symbol of the House of York was a white rose.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This war was called the Wars of the Roses, because the symbol of Lancaster was a red rose and the symbol of York was a white rose.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? St Columba became the first Archbishop of Canterbury.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "St Augustine became the first Archbishop of Canterbury",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1789 there was a revolution in France and the new French government declared war on Britain.",
        "In 1789 there was an unpopular new government in France and Britain declared war.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1789, there was a revolution in France and the new French government soon declared war on Britain.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Protestant believed that a person's own relationship with God was more important that submitting to the authority of the Church.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They read the Bible in their own languages instead of in Latin; they did not pray to saints or at shrines; and they believed that a person's own relationship with God was more important than submitting to the authority of the Church.",
      point: "1",
    },
    {
      question: "TRUE or FALSE? Britain has never engaged in war with France.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain gained control over Canada, and the East India Company, originally set up to trade, gained control of large parts of India. Colonies began to be established in southern Africa.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Beveridge Report of 1942 outlined a plan to end the war in Europe.",
        "The Beveridge Report of 1942 provided the basis of the modern welfare state.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1945 the British people elected a Labour government. The new Prime Minister was Clement Attlee, who promised to introduce the welfare state outlined in the Beveridge Report.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Sake Dean Mahomet was born in 1759 and grew up in the Bengal Religion of India.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Mahomet was born in 1759 and grew up in the Bengal region of India.",
      point: "1",
    },
    {
      question:
        "By 1200, what area of Ireland around Dublin did the English rule?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["The Pale", "The Pate", "The Pict", "The plain"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1200, the English ruled an area of Ireland known as the Pale, around Dublin.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The Royal Society is the oldest surviving scientific society in the world.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During his reign, the Royal Society was formed to promote 'natural knowledge'. This is the oldest surviving scientific society in the world.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The American colonies rebelled against the British Parliament in the 18th century because they did not want to pay taxes without representation in Parliament.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In 1776, 13 American colonies declared their independence, stating that people had a right to establish their own governments. The colonists eventually defeated the British army and Britain recognised the colonies' independence in 1783.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the reign of Charles I, Irish Catholics supported the views of the Puritans.",
        "During the reign of Charles I, Parliament attempted to take control of the English army following a rebellion in Ireland.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Another rebellion began in Ireland because the Roman Catholics in Ireland were afraid of the growing power of the Puritans. Parliament took this opportunity to demand control of the English army - a change that would have transferred substantial power from the king to Parliament.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Charles II was crowned king of England, Wales, Scotland and Ireland in the Restoration after the English Civil War.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In May 1660, Parliament invited Charles II to come back from exile in the Netherlands. He was crowned King Charles II of England, Wales, Scotland and Ireland.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Margaret Thatcher is the second longest-serving Prime Minister of the UK in the 20th Century.",
        "Margaret Thatcher was the longest-serving Prime Minister of the UK in the 20th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Margaret Thatcher became the first woman Prime Minister of the UK. She was the longest-serving Prime Minister of the 20th century, remaining in office until 1990.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? In 1688, Protestant leaders in England asked William of Orange to invade England and proclaim himself king.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "James II's elder daughter, Mary, was married to her cousin William of Orange, the Protestant ruler of the Netherlands. In 1688, important Protestants in England asked William to invade England and proclaim himself king.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By 1400, a mixture of Norman French and Anglo-Saxon was being spoken and no unified language was emerging.",
        "By 1400, in England, official documents were being written in English and English was the preferred language of Parliament and the royal court.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, in England, official documents were being written in English, and English had become the preferred language of the royal court and Parliament.",
      point: "1",
    },
    {
      question:
        "A conflict in which of these areas in the 1990s involved British troops?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Lapland",
        "The Baltic states",
        "The Maldives",
        "The former Republic of Yugoslavia",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Throughout the 1990s, Britain played a leading role in coalition forces involved in the liberation of Kuwait, following the Iraqi invasion in 1990, and the conflict in the Former Republic of Yugoslavia.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Henry VIII married four times.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Henry VIII was most famous for breaking away from the Church of Rome and marrying six times.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Henry VIII continued his father's work to centralise the administrative powers of England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When he died, his son Henry VIII continued the policy of centralizing power.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The BBC began the world's first regular television service in 1936.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The BBC started radio broadcasts in 1922 and began the world's first regular television service in 1936.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During the Elizabethan period, English settlers began to colonise America",
        "During the Elizabethan period, English settlers began to colonise Australia",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The declaration of rights read at the coronation of William and Mary meant the monarch could no longer raise taxes or administer justice.",
        "The declaration of rights read at the coronation of William and Mary meant the monarch had absolute power to rule without the interference of Parliament.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "At the coronation of William and Mary, a Declaration of Rights was read. This confirmed that the king would no longer be able to raise taxes or administer justice without agreement from Parliament. The balance of power between monarch and Parliament had now permanently changed.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "During Queen Elizabeth I's reign, English settlers began to colonise Australia.",
        "During Queen Elizabeth I's reign, English settlers began to colonise the eastern coast of North America.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Elizabeth I's time, English settlers first began to colonise the eastern coast of America.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Mary Peters was an author who was later made a Dame in recognition of her work promoting literacy.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Born in Manchester, Mary Peters moved to Northern Ireland as a child. She was a talented athlete who won an Olympic gold medal in the pentathlon in 1972. After this, she raised money for local athletics and became the team manager for the women's British Olympic team. She continues to promote sport and tourism in Northern Ireland and was made a Dame of the British Empire in 2000 in recognition of her work.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? Isaac Newton is a famous musician from the 18th century.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "His most famous published work was Philosophiae Naturalis Principia Mathematica (''Mathematical Principles of Natural Philosophy'), which showed how gravity applied to the whole universe.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Richard Arkwright developed efficient and profitable factories.",
        "Richard Arkwright developed new farming methods in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Arkwright is particularly remembered for the efficient and profitable way that he ran his factories.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? A public vote in 2002 decided that Winston Churchill was the Greatest Briton of all time.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He remains a much-admired figure to this day, and in 2002 was voted the greatest Briton of all time by the public.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The threat of Viking attack caused the people in the north to unite, and the term Scotland was used to describe that country.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the north, the threat of attack by Vikings had encouraged the people to unite under one king, Kenneth MacAlpin. The term Scotland began to be used to describe that country.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A free press means newspapers are given out free of charge.",
        "A free press means that what is written in newspapers is free from government control.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "This was also an important time for the development of a free press (newspapers and other publications which are not controlled by the government).",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The Bill of Rights confirmed the rights of Parliament and limits of the king's power.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Bill of Rights, 1689, confirmed the rights of Parliament and the limits of the king's power.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Industrial Revolution is the name given to the rapid development of industry in Britain in the 20th century.",
        "The Industrial Revolution is the name given to the rapid development of industry that began in the 18th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Industrial Revolution was the rapid development of industry in Britain in the 18th and 19th centuries.",
      point: "1",
    },
    {
      question:
        "The four shortlisted works for the Turner Prize are shown at which London venue?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "British Museum",
        "National Gallery",
        "Tate Britain",
        "Victoria and Albert Museum",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Turner Prize was established in 1984 and celebrates contemporary art. It was named after Joseph Turner. Four works are shortlisted every year and shown at Tate Britain before the winner is announced.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? The Houses of Parliament are built in the medieval 'gothic' style of architecture.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, the medieval 'gothic' style became popular again. As cities expanded, many great public buildings were built in this style. The Houses of Parliament and St Pancras Station were built at this time, as were the town halls in cities such as Manchester and Sheffield.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? In the UK, everybody has the right to choose their religion or choose not to practise a religion.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "However, everyone has the legal right to choose their religion, or to choose not to practice a religion.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The official Church of state of the UK is the Church of England.",
        "There is no official Church in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The official Church of the state is the Church of England.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? In Wales and Northern Ireland, the established Churches are respectively the Church of Wales and the Church of Ulster.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is no established Church in Wales or Northern Ireland.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "11 November commemorates soldiers who died in World War One as well as those who have died in all conflicts involving the UK since then.",
        "11 November only commemorates soldiers who died in World War One.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Remembrance Day, 11 November, commemorates those who died fighting for the UK and its allies. Originally it commemorated the dead of the First World War, which ended on 11 November 1918.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Gilbert and Sullivan were a comedy double act",
        "Gilbert and Sullivan wrote many comic operas",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the 19th century, Gilbert and Sullivan wrote comic operas, often making fun of popular culture and politics.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "There is a yearly race on the River Thames between Oxford and Cambridge Universities.",
        "There is a yearly sailing race on the River Thames between Oxford and Cambridge Universities.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Rowing is also popular, both as a leisure activity and as a competitive sport. There is a popular yearly race on the Thames between Oxford and Cambridge Universities.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All shops in the UK must close on Sunday",
        "Many shops in the UK are open seven days a week",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Most shops in the UK are open seven days a week, although trading hours on Sundays and public holidays are generally reduced.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Both Jane Austen and Charles Dickens are famous novelists",
        "Both Jane Austen and Charles Dickens are famous painters",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Jane Austen (1775-1817) was an English novelist. Her books include Pride and Prejudice and Sense and Sensibility. Her novels are concerned with marriage and family relationships. Many have been made into television programmes or films. Charles Dickens (1812-70) wrote a number of very famous novels, including Oliver Twist and Great Expectations. You will hear references in everyday talk to some of the characters in his books, such as Scrooge (a mean person) or Mr Micawber (always hopeful).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Proms are a series of tennis matches held every June in London.",
        "The Proms is an eight-week summer season of orchestral music.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Proms is an eight-week summer season of orchestral classical music that takes place in various venues, including the Royal Albert Hall in London.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "EastEnders and Coronation Street are historical landmarks.",
        "EastEnders and Coronation Street are popular television programmes.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Popular programmes include regular soap operas such as Coronation Street and EastEnders.",
      point: "1",
    },
    {
      question: "What event is remembered on November 5th every year?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A plot to blow up the House of Parliament in 1605",
        "The End of the Second World War",
        "The Queen's Birthday",
        "The defeat of Spanish Armada in 1588",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Bonfire Night, 5 November, is an occasion when people in Great Britain set off fireworks at home or in special displays. The origin of this celebration was an event in 1605, when a group of Catholics led by Guy Fawkes failed in their plan to kill the Protestant king with a bomb in the Houses of Parliament.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Halloween has its roots in an ancient pagan festival marking the beginning of winter.",
        "Halloween is a modern American festival that has recently become popular in the UK.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Halloween, 31 October, is an ancient festival and has roots in the pagan festival to mark the beginning of winter.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Lancelot 'Capability' Brown and Gertrude Jekyll were famous characters in a Sherlock Holmes story.",
        " Lancelot 'Capability' Brown and Gertrude Jekyll were famous garden designers.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Lancelot 'Capability' Brown designed the grounds around country houses so that the landscape appeared to be natural, with grass, trees and lakes. He often said that a place had 'capabilities'. Later, Gertrude Jekyll often worked with Edwin Lutyens to design colourful gardens around the houses he designed.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Big Ben is the nickname of the great bell in the clock tower of the Houses of Parliament.",
        "Big ben is a popular children's television character.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the great bell of the clock at the Houses of Parliament in London. Many people call the clock Big Ben as well.",
      point: "1",
    },
    {
      question: " Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A famous sailing event is held at Crowes on the Isle of Wight.",
        "A famous sailing event is held in the city of Belfast.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Many sailing events are held throughout the UK, the most famous of which is at Cowes on the Isle of Wight.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Mousetrap is a play that has been running in London's West End since 1952.",
        "The Mousetrap is an environmental policy aiming to prevent mice from destroying crops.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Mousetrap, a murder-mystery play by Dame Agatha Christie, has been running in the West End since 1952 and has had the longest initial run of any show in history.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sir Steve Redgrave is a famous film actor who has won several BAFTAs.",
        "Sir Steve Redgrave is a famous rower who won gold medals in five consecutive Olympics Games.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sir Steve Redgrave (1962-) won gold medals in rowing in five consecutive Olympic Games and is one of Britain's greatest Olympians.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Andy Murray is the first British man to sail around the world.",
        "Andy Murray is the first British man to win a singles tennis title in a Grand Slam tournament since 1936.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He is the first British man to win a singles title in a Grand Slam tournament since 1936. In the same year, he won Olympic gold and silver medals and was runner-up in the men's singles at Wimbledon.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Rugby originated in England in the early 19th century.",
        "Rugby was introduced to ancient Britain by Viking invaders.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Rugby originated in England in the early 19th century and is very popular in the UK today.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "National parks are areas of protected countryside that everyone can visit.",
        "National parks are national sports grounds for people to hold sporting events.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are areas of protected countryside that everyone can visit, and where people live, work and look after the landscape.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first professional UK football clubs were formed in 1066",
        "The first professional UK football clubs were formed in the late 19th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first professional UK football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No British writer has won the Nobel prize in Literature.",
        "Several British writers have won the Nobel prize in Literature.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has a prestigious literary history and tradition. Several British writers, including the novelist Sir William Golding, the poet Seamus Heaney, and the playwright Harold Pinter, have won the Nobel Prize in Literature.",
      point: "1",
    },
    {
      question:
        "In which sport is Bobby Moore renowned for his accomplishments?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" Football", "Horse racing", "Motor racing", "Rugby union"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Bobby Moore (1941-1993) captained the English football team that won the World Cup in 1966.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? William Blake, Lord Byron, and Robert Browning were all celebrated golfers.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", " TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Poetry was very popular in the 19th century, with poets such as William Blake, John Keats, Lord Byron, Percy Shelley, Alfred Lord Tennyson, and Robert and Elizabeth Browning.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Cricket matches can last up to five days.",
        "Cricket matches can last up to two weeks.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Cricket originated in England and is now played in many countries. Games can last up to five days but still result in a draw!",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Baptists, Methodists and Quakers are all linked to the Roman Catholic Church.",
        "Baptists, Methodists and Quakers are protestant Christian groups.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Other Protestant Christian groups in the UK are Baptists, Methodists, Presbyterians and Quakers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "There is no established Church in Wales or Northern Ireland.",
        "Wales and Northern Ireland each have their own Church of state.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is no established Church in Wales or Northern Ireland.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital cities of the nations of the UK are London, Cardiff, Edinburgh and Belfast.",
        "The capital cities of the nations of the UK are London, Swansea, Glasgow and Dublin.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The capital city of the UK is London ; Scotland: The capital city of Scotland is Edinburgh ; Wales: The capital city of Wales is Cardiff ; Northern Ireland: The capital city of Northern Ireland is Belfast",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital city of Northern Ireland is Belfast",
        "The capital city of Northern Ireland is Swansea",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The capital city of Northern Ireland is Belfast",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Newport, Swansea and Cardiff are cities in Scotland",
        "Plymouth, Norwich and Leeds are cities in England.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Plymouth, Norwich and Leeds are cities in England.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? The Crown Jewels are stored at the Tower of London.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Tower of London was first built by William the Conqueror after he became king in 1066. Tours are given by the Yeoman Warders, also known as Beefeaters, who tell visitors about the building's history. People can also see the Crown Jewels there.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The BBC is funded through advertisements and subscriptions",
        "The BBC is the only wholly state-funded media organisation, independent of government",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The BBC is the largest broadcaster in the world. It is the only wholly state-funded media organisation that is independent of government.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The capital city of Scotland is Edinburgh",
        "The capital city of Scotland is Glasgow",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Scotland ; The capital city of Scotland is Edinburgh",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You have to be aged 16 or over to buy a National Lottery ticket",
        "You have to be aged 18 or over to buy a National Lottery ticket",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There is a National Lottery for which draws are made every week. You can enter by buying a ticket or a scratch card. People under 18 are not allowed to participate in the National Lottery. The minimum age to buy a lottery ticket was raised from 16 to 18 in 2021.",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? In the UK betting and gambling are illegal.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You have to be 18 to go into betting shops or gambling clubs.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Women in Britain make up about a quarter of the workforce.",
        "Women in Britain make up about half of the workforce.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Women in Britain today make up about half of the workforce.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Wimbledon Championships are associated with athletics.",
        "The Wimbledon Championships are associated with tennis.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Modern tennis evolved in England in the late 19th century. The first tennis club was founded in Leamington Spa in 1872. The most famous tournament hosted in Britain is The Wimbledon Championships, which takes place each year at the All England Lawn Tennis and Croquet Club. It is the oldest tennis tournament in the world and the only 'Grand Slam' event played on grass.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A dog's owner is not responsible for keeping the animal under control and cleaning up after it in public places.",
        "A dog's owner is responsible for keeping the animal under control and cleaning up after it in public places.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A lot of people in the UK have pets such as cats or dogs. They might have them for company or because they enjoy looking after them. It is against the law to treat a pet cruelly or to neglect it. All dogs in public places must wear a collar showing the name and address of the owner. The owner is responsible for keeping the dog under control and for cleaning up after the animal in a public place.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All the national saints' days are celebrated but only in England and Wales are they official holidays.",
        "All the patron saints' days are celebrated but only in Scotland and Northern Ireland are they official holidays.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Only Scotland and Northern Ireland have their patron saint's day as an official holiday (although in Scotland not all businesses and offices will dose).",
      point: "1",
    },
    {
      question:
        "Which category does a snack made from flour, dried fruits, and spices, and served either hot or cold fall into?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Scottish bun",
        "A Welsh cake",
        "An English muffin",
        "An Irish pie",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Wales: Welsh cakes - a traditional Welsh snack made from flour, dried fruits and spices, and served either hot or cold.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Big Ben is a novel written by Sir Kingsley Amis.",
        "Big Ben refers to the great bell of the clock at the Houses of Parliament.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the great bell of the clock at the Houses of Parliament in London. Many people call the clock Big Ben as well. The clock is over 150 years old and is a popular tourist attraction.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All dog owners must have licence to keep their pets.",
        "Dogs in public places must wear a collar showing the owner's name and address.",
        "Dogs in public places must wear a collar.",
        "Dogs in public places must wear a muzzle.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "All dogs in public places must wear a collar showing the name and address of the owner.",
      point: "1",
    },
    {
      question:
        "What are areas of protected countryside where people live, work, and maintain the landscape called?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Country estates",
        "Greenfield sites",
        "Moorland",
        "National parks",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK has a large network of public footpaths in the countryside. There are also many opportunities for mountain biking, mountaineering and hill walking. There are 15 national parks in England, Wales and Scotland. They are areas of protected countryside that everyone can visit, and where people live, work and look after the landscape.",
      point: "1",
    },
    {
      question: "Where is the Remembrance Day service usually held in London?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Nelson's Column in Trafalgar Square",
        "The Cenotaph in Whitehall",
        "The London Eye",
        "The statue of Eros in Piccadilly Circus",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People wear poppies (the red flower found on the battlefields of the First World War). At 11.00 am there is a two-minute silence and wreaths are laid at the Cenotaph in Whitehall, London.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sir Terrence Conran was an 18th-century designer of furniture.",
        "Thomas Chippendale was an 18th- century designer of furniture.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Britain has produced many great designers, from Thomas Chippendale (who designed furniture in the 18th century) to Clarice cliff (who designed Art Deco ceramics) to Sir Terence Conran (a 20th-century interior designer). Leading fashion designers of recent years include Mary Quant, Alexander McQueen and Vivienne Westwood.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The first professional UK football clubs were formed in 1066",
        "The first professional UK football clubs were formed in the late 19th century.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first professional UK football clubs were formed in the late 19th century.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "People in the UK are living longer than ever before.",
        "The average lifespan for UK residents is steadily decreasing",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People in the UK are living longer than ever before. This is due to improved living standards and better health care. There are now a record number of people aged 85 and over. This has an impact on the cost of pensions and health care.",
      point: "1",
    },
    {
      question: "Where would you find biomes at which famous UK landmark?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Edinburgh Castle",
        "Giant Causeway",
        "Snowdonia",
        "The Eden Project",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Eden Project is located in Cornwall, in the south west of England. Its biomes, which are like giant greenhouses, house plants from all over the world.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Andy Murray is the first British man to sail around the world.",
        "Andy Murray is the first British man to win a singles tennis title in a Grand Slam tournament since 1936.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "He is the first British man to win a singles title in a Grand Slam tournament since 1936. In the same year, he won Olympic gold and silver medals and was runner-up in the men's singles at Wimbledon.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In 1998 the Good Friday Agreement devolved powers to Wales",
        "In 1998 the Good Friday Agreement let to the establishment of the Northern Ireland Assembly",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Northern Ireland Assembly was established soon after the Belfast Agreement (or Good Friday Agreement) in 1998.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The House of Commons may never overrule the House of Lords",
        "The House of Commons may overrule the Houser of Lords",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The House of Commons has powers to overrule the House of Lords, but these are not used often.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Murder, assault and theft are crimes.",
        "Murder, assault and theft are examples of civil disputes.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many that apply in most countries, such as murder, theft and assault. You can find out more about types of crime in the UK at www.gov.uk",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The United Nations was set up after the Second World War.",
        "The United Nations was set up between the First and Second World Wars.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UN was set up after the Second World War and aims to prevent war and promote international peace and security.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Chequers is the Prime Minister's country house.",
        "Chequers is the Prime Minister's house in London.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister (PM) is the leader of the political party in power. He or she appoints the members of the cabinet (see below) and has control over many important public appointments. The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. He or she also has a country house outside London called Chequers.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK's constitution is a single document that includes laws and conventions",
        "The UK's constitution is unwritten but includes laws and conventions",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "A constitution is a set of principles by which a country is governed. It includes all of the institutions that are responsible for running the country and how their power is kept in check. The constitution also includes laws and conventions.",
      point: "1",
    },
    {
      question:
        "At what age are individuals eligible to vote in a General Election in the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["16", "18", "21", "23"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The present voting age of 18 was set in 1969 and (with a few exceptions) all UK-born and naturalised adult citizens have the right to vote. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Volunteering is a good way to earn additional money.",
        "Volunteering is a way of helping others without receiving payment.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Volunteering is working for good causes without payment.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Self-employed people can ask a friend to pay their National Insurance Contribution on their behalf.",
        "Self-employed people need to pay National Insurance Contributions themselves.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People who are self-employed need to pay National Insurance Contributions themselves.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The small claims procedure helps people to make small home insurance claims.",
        "The small claims procedure is an informal way of helping people to settle minor disputes.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "If your driving licence is from a country in the European Union you can drive in the UK for as long as your license is valid.",
        "If your driving licence is from a country in the European Union you have to apply for a UK licence in order to drive.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If your driving licence is from a country in the European Union (EU), Iceland, Liechtenstein or Norway, you can drive in the UK for as long as your licence is valid. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the UK, employers can discriminate against different groups of people.",
        "UK laws ensure people are not treated unfairly in any area of work or life.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "UK laws ensure that people are not treated unfairly in any area of life or work because of their age, disability, sex, pregnancy and maternity, race, religion or belief, sexuality or marital status. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Countries are obliged to belong to the Commonwealth.",
        "Countries join the Commonwealth voluntarily.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development. Most member states were once part of the British Empire, although a few countries which were not have also joined. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "County Courts deal with civil disputes.",
        "County Courts deal with criminal cases.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "County Courts deal with a wide range of civil disputes. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The Speaker of the House of Commons has to give up being an MP when elected as Speaker.",
        "The Speaker of the House of Commons remains a Member of Parliament (MPs) after election as Speaker.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Debates in the House of Commons are chaired by the Speaker. This person is the chief officer of the House of Commons. The Speaker is neutral and does not represent a political party, even though he or she is an MP, represents a constituency and deals with constituents' problems like any other MP. ",
      point: "1",
    },
    {
      question: " Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Magistrates must be specially trained legal experts who have been solicitors for three years.",
        "Magistrates usually work unpaid and do not need legal qualifications",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Magistrates and Justices of the Peace (JPs) are members of the local community. In England, Wales and Scotland they usually work unpaid and do not need legal qualifications. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No member of the public is allowed to attend debates in the House of Commons.",
        "The public can attend debates in the House of Commons.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The public can listen to debates in the Palace of Westminster from public galleries in both the House of Commons and the House of Lords. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The UK has never been a member of NATO.",
        "The UK is a member of NATO",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The UK is also a member of NATO. ",
      point: "1",
    },
    {
      question:
        "What activities might one engage in by joining a political party?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Handling out leaflets in the street",
        "Joining your MP for sessions in the House of Commons",
        "Knocking on people's doors and asking for support",
        "Violent clashes with other political parties",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members work hard to persuade people to vote for their candidates - for instance, by handing out leaflets in the street or by knocking on people's doors and asking for their support. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Civil servants are politically neutral",
        "Civil servants have to be politically aligned to the elected government",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are chosen on merit and are politically neutral - they are not political appointees. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Members of the House of Lords are not elected by the people.",
        "Members of the House of Lords are voted in by members of the House of Commons.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Members of the House of Lords, known as peers, are not elected by the people and do not represent a constituency. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "After the age of 70, drivers must renew their licence every five years.",
        "After the age of 70, drivers must renew their licence every three years.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Drivers can use their driving licence until they are 70 years old. After that, the licence is valid for three years at a time. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "MPs were elected through a system called 'the winner takes it all'.",
        "Members of Parliament (MPs) are elected through a system called 'first past the post'.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "MPs are elected through a system called 'first past the post'. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The official home of the Prime Minister is 10 Downing Street.",
        "The official home of the Prime Minister is Buckingham Palace",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "An example of a criminal offence is being in debt.",
        "An example of a criminal offence is carrying a weapon.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Examples of criminal laws are:Carrying a weapon: it is a criminal offence to carry a weapon of any kind, even if it is for self-defence. This includes a gun, a knife or anything that is made or adapted to cause injury.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In the UK organ donation is a legal requirement.",
        "In the UK organ donation is voluntary.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If you register to be an organ donor, it can make it easier for your family to decide whether to donate your organs when you die. You can register to be an organ donor at www.organdonation.nhs.uk. Living people can also donate a kidney. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Queen is ceremonial head of the Commonwealth",
        "The Queen is ceremonial head of the North Atlantic Treaty Organization (NATO).",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Queen is ceremonial head of the Commonwealth.",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? Serving as a school governor or school board member can support your local community.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", " TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "School governors, or members of the school board in Scotland, are people from the local community who wish to make a positive contribution to children's education. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All acts of Parliament are made in the Prime Minister's name.",
        "All acts of Parliament are made in the monarch's name.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "All Acts of Parliament are made in her name. ",
      point: "1",
    },
    {
      question:
        "What is the process for selecting the Speaker of the House of Commons?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "By the Prime Minister",
        "By the monarch",
        "In a secret ballot",
        "Through a public election",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "The Speaker is chosen by other MPs in a secret ballot. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Police and Crime Commissioners (PCCs) are appointed by the local council.",
        "Police and Crime Commissioners (PCCs) are appointed through a public election.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In November 2012, the public elected Police and Crime Commissioners (PCCs) in England and Wales. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "All members of Parliament have to belong to a political party.",
        "There are few members of Parliament who do not represent any of the main political parties.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are a few MPs who do not represent any of the main political parties. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Proceeding in Parliament cannot be reported in the press",
        "Proceedings in Parliament are broadcast on television",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Proceedings in Parliament are broadcast on television and published in official reports called Hansard.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Decisions on government policies are made by the Prime Minister and cabinet.",
        "Decisions on government policies are made by the monarch.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister appoints about 20 senior MPs to become ministers in charge of departments. These include:Chancellor of the Exchequer - responsible for the economy ;Home Secretary - responsible for crime, policing and immigration ;Foreign Secretary - responsible for managing relationships with foreign countries ;",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? Pressure and lobby groups try to influence British government policy.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Pressure and lobby groups are organisations which try to influence government policy. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The civil service is politically neutral.",
        "The civil service largely consists of political appointees.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "They are chosen on merit and are politically neutral - they are not political appointees. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The Chancellor of the Exchequer is responsible for crime, policing and immigration.",
        "The Chancellor of the Exchequer is responsible for the economy.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister appoints about 20 senior MPs to become ministers in charge of departments. These include:Chancellor of the Exchequer - responsible for the economy ;Home Secretary - responsible for crime, policing and immigration ;Foreign Secretary - responsible for managing relationships with foreignother ministers (called 'Secretaries of State') responsible for subjects such as education, health and defence. countries ;",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "It costs £15 per person to visit the Houses of Parliament to watch debates.",
        " It is free to visit the House of Parliament to watch debates.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The public can listen to debates in the Palace of Westminster from public galleries in both the House of Commons and the House of Lords. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In Northern Ireland a member of your family must complete a voting registration form on your behalf.",
        "In Northern Ireland all those entitled to vote must complete their own registration form.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Northern Ireland a different system operates. This is called 'individual registration' and all those entitled to vote must complete their own registration form. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In a Crown Court case the judge decides the sentence when someone is found guilty.",
        "In a Crown Court case the jury decides the sentence when someone is found guilty.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "If the jury finds a defendant guilty, the judge decides on the penalty. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Everyone pays National Insurance Contributions.",
        "Most working people pay National Insurance Contributions.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Almost everybody in the UK who is in paid work, including self-employed people, must pay National Insurance Contributions. The money raised from National Insurance Contributions is used to pay for state benefits and services such as the state retirement pension and the National Health Service (NHS).",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Local election are normally held in March",
        " Local elections are normally held in May",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "For most local authorities, local elections for councillors are held in May every year. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "A Magistrates' Court deals with minor criminal offences.",
        "A Magistrates' Court deals with only the most serious criminal cases.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In England, Wales and Northern Ireland, most minor criminal cases are dealt with in a Magistrates' Court.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Refuges and shelters offer a place to stay for victims of domestic violence.",
        " The Citizens Advice Bureau offers a safe place to stay for victims' domestic violence.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "It is important for anyone facing domestic violence to get help as soon as possible. A solicitor or the Citizens Advice Bureau can explain the available options. In some areas there are safe places to go and stay in, called refuges or shelters. There are emergency telephone numbers in the helpline section at the front of Yellow Pages, including, for women, the number of the nearest women's centre. You can also phone the 24-hour National Domestic Violence Freephone Helpline on 0808 2000 247 at any time, or the police can help you find a safe place to stay.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The small claims procedure is an informal way of helping people settle minor disputes without needing a lawyer.",
        "The small claims procedure is an informal way of helping people who have been victims of identity theft or fraud.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "In Scotland, serious offences are tried in a Crown Court",
        "In Scotland, serious offences are tried in a Sheriff Court",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Scotland, serious cases are heard in a Sheriff Court with either a sheriff or a sheriff with a jury. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The cabinet's decisions must always be debated or approved by Parliament.",
        "The cabinet's decisions often have to be debated or approved by Parliament.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "These ministers form the cabinet, a committee which usually meets weekly and makes important decisions about government policy. Many of these decisions have to be debated or approved by Parliament. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You may introduce yourself to your neighbours when you move into a new house or flat, if circumstances permit",
        "You should ignore your new neighbours if they want to introduce themselves to you",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "When you move into a new house or apartment, try to introduce yourself to the people who live near you. Getting to know your neighbours can help you to become part of the community and make friends. ",
      point: "1",
    },
    {
      question:
        "Is the statement TRUE or FALSE? The Welsh Assembly has no power to make laws, but considerable control over public services.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Assembly has the power to make laws for Wales in 20 areas, including:education and training ; health and social services ; economic development ; housing.",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "More than 190 countries belong to NATO",
        "More than 190 countries belong to the United Nations.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The UK is part of the United Nations (UN), an international organisation with more than 190 countries as members. ",
      point: "1",
    },
    {
      question:
        "What are the TWO pieces of information typically included in a poll card?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "How much tax you should pay",
        "The date of the election",
        "Where the polling station or polling place is located",
        "Who you should vote for",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "People vote in elections at places called polling stations, or polling places in Scotland. Before the election you will be sent a poll card. This tells you where your polling station or polling place is and when the election will take place. ",
      point: "1",
    },
    {
      question:
        "Apart from providing legal advice, solicitors are capable of performing which TWO functions?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "multiple",
      answers: [
        "Arrest suspects",
        "Change the law",
        "Represent clients in court",
        "Take action for a client",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Solicitors are trained lawyers who give advice on legal matters, take action for their clients and represent their clients in court.",
      point: "1",
    },
    {
      question:
        "Is participation in the National Citizen Service available to young people in all parts of the UK?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No, only young people resident in England can volunteer",
        "Yes but each part of the UK has a different website for information about the service",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "There are many opportunities for younger people to volunteer and receive accreditation which will help them to develop their skills. These include the National Citizen Service programme, which gives 16- and 17-year-olds the opportunity to enjoy outdoor activities, develop their skills and take part in a community project. You can find out more about these opportunities as follows:National Citizen Service: at ;England: at www.vinspired.com ;Wales: at www.gwirvol.org ;Scotland: at www.vds.org.uk ;Northern Ireland: at www.volunteernow.co.uk",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "You do not need the help of a lawyer to issue a small claim",
        "You will need the help of a lawyer to issue a small claim.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is an informal way of helping people to settle minor disputes without spending a lot of time and money using a lawyer. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Commonwealth is a group of countries that support each other and work together.",
        "The Commonwealth is a group of religions that together make up a federal republic.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is an association of countries that support each other and work together towards shared goals in democracy and development. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The National Assembly for Wales and the Welsh government are based in Cardiff.",
        "The National Assembly for Wales and the Welsh government are based in Edinburgh.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Welsh government and National Assembly for Wales are based in Cardiff, the capital city of Wales. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Completed ballots must be handed to an election official.",
        "Completed ballots must be placed in a ballot box.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "You should follow the instructions on the ballot paper. Once you have completed it, put it in the ballot box. ",
      point: "1",
    },
    {
      question: "Which of these statements is correct?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Chequers is the Prime Minister's country house.",
        "Chequers is the Prime Minister's house in London.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Prime Minister (PM) is the leader of the political party in power. He or she appoints the members of the cabinet (see below) and has control over many important public appointments. The official home of the Prime Minister is 10 Downing Street, in central London, near the Houses of Parliament. He or she also has a country house outside London called Chequers.",
      point: "1",
    },
    {
      question:
        "What is the minimum age requirement for purchasing tobacco or tobacco products?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["14", "16", "18", "21"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Selling tobacco: it is illegal to sell tobacco products (for example, cigarettes, cigars, roll-up tobacco) to anyone under the age of 18.",
      point: "1",
    },
    {
      question: "By 1400, the preferred language of the English court was:",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["English", "French", "Latin", "German"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "By 1400, English had become the preferred language of the English court, marking a significant transition from the previous dominance of French in official proceedings.",
      point: "1",
    },
    {
      question:
        "In Northern Ireland, who must complete a voting registration form on your behalf?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Your Family member",
        "Your Friend",
        "Your employer",
        "Any Irish citizen",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In Northern Ireland, if you're unable to complete a voting registration form yourself, someone else, such as a family member, can do it on your behalf.",
      point: "1",
    },
    {
      question: "Who appoints Police and Crime Commissioners (PCCs)?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The local council",
        " The Prime Minister",
        " The King",
        " The police force",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Police and Crime Commissioners (PCCs) in England and Wales are elected officials. They are appointed by the local electorate through democratic elections.",
      point: "1",
    },
    {
      question: "What is the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Ex colonies of the UK",
        " A group of countries that support each other",
        " A military alliance",
        " A political party",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Commonwealth is a voluntary association of 54 member states, most of which are former territories of the British Empire. It promotes cooperation on political, economic, and social issues among its member countries.",
      point: "1",
    },
    {
      question:
        "Who introduced curry houses and shampooing to Britain from India?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "Sake Dean Mahomet",
        " Sir Christopher Wren",
        " Robert Burns",
        " Richard Arkwright",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Sake Dean Mahomed was an entrepreneur and entrepreneur who introduced Indian cuisine and the concept of shampooing to Britain in the late 18th and early 19th centuries.",
      point: "1",
    },
    {
      question: "What is Big Ben?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The nickname of the great bell in the clock tower of the Houses of Parliament",
        " A famous landmark in Manchester",
        " A novel written by Sir Kingsley Amis",
        " A popular British dish",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Big Ben is the nickname for the Great Bell of the clock at the north end of the Palace of Westminster in London. It is often used to refer to the clock tower itself.",
      point: "1",
    },
    {
      question: "How is the BBC funded?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " Through advertisements and subscriptions",
        " Through taxes",
        " Through donations",
        " Through government grants",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The BBC (British Broadcasting Corporation) is primarily funded by the television license fee paid by households in the UK that watch live television broadcasts.",
      point: "1",
    },
    {
      question: "What is the small claims procedure?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "An informal way of helping people settle minor disputes without needing a lawyer",
        "A process for large legal cases",
        "A government initiative to reduce taxes",
        "A method for resolving international disputes",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The small claims procedure is a simplified and cost-effective legal process for resolving minor disputes involving relatively small amounts of money. It is designed to be accessible to individuals without the need for expensive legal representation.",
      point: "1",
    },
    {
      question: "Where are serious offences tried in Scotland?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " In a Crown Court",
        " In a Magistrates' Court",
        " In a County Court",
        " In a High Court",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Serious offences in Scotland are tried in the High Court of Justiciary, which is the highest criminal court in the country.",
      point: "1",
    },
    {
      question: "What happened to American colonists eventually?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " They were defeated by the British",
        " They won independence from Britain",
        " They formed an alliance with Britain",
        " They remained under British rule",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "American colonists eventually gained independence from Britain after the American Revolutionary War, which concluded with the signing of the Treaty of Paris in 1783.",
      point: "1",
    },
    {
      question:
        "Who is responsible for keeping a dog under control and cleaning up after it in public places?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The dog's owner",
        " The local council",
        " The police",
        " Anyone who sees the dog",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In public places, the owner or person in charge of a dog is responsible for keeping it under control and cleaning up after it to ensure public safety and cleanliness.",
      point: "1",
    },
    {
      question: "When were the first professional UK football clubs formed?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " In the 19th century",
        " In 1066",
        " In the 18th century",
        " In the 20th century",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The first professional football clubs in the UK emerged in the late 19th century, around the 1860s, marking the transition of football from an amateur pastime to a professional sport.",
      point: "1",
    },
    {
      question:
        "When did two political groups, the Conservatives and the Liberals, emerge?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " In the 18th century",
        " In the 16th century",
        " In the 17th century",
        " In the 19th century",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Conservative and Liberal parties emerged as distinct political groups in the UK during the 19th century, around the early 1800s, reflecting differing ideologies and interests.",
      point: "1",
    },
    {
      question:
        "Who decides the sentence in a Crown Court case when someone is found guilty?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" The judge", " The jury", " The police", " The defendant"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In a Crown Court case in the UK, the judge is responsible for deciding the sentence when someone is found guilty. The judge considers various factors, including the severity of the crime and the offender's circumstances, before determining the appropriate punishment.",
      point: "1",
    },
    {
      question: "What do refuges and shelters offer?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " A place to stay for victims of domestic violence",
        " Temporary housing for tourists",
        " Emergency accommodation for the homeless",
        " Low-cost housing for students",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Refuges and shelters provide temporary housing, safety, and support services for individuals and families fleeing domestic violence, homelessness, or other forms of abuse. They offer a safe haven and access to resources to help survivors rebuild their lives.",
      point: "1",
    },
    {
      question:
        "Must the cabinet's decisions always be debated or approved by Parliament?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "No",
        "Yes",
        " Only in certain circumstances",
        " It depends on the decision",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Cabinet in the UK is the main decision-making body of the government, consisting of senior ministers chosen by the Prime Minister. While Cabinet decisions inform government policy, they do not always require formal debate or approval by Parliament.",
      point: "1",
    },
    {
      question: "Where was the Battle of Britain fought in 1940?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" In the air", " At sea", " On land", " In space"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The Battle of Britain, a pivotal air campaign during World War II, was fought primarily in the skies over Britain in 1940. It was a crucial victory for the Allies in defending against German air attacks and preventing a potential invasion of Britain.",
      point: "1",
    },
    {
      question: "Who developed efficient and profitable factories?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " Richard Arkwright",
        " Sir Christopher Wren",
        " Sake Dean Mahomet",
        " Robert Burns",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "Richard Arkwright is the individual who developed efficient and profitable factories. He was an English inventor and entrepreneur who played a key role in the development of the spinning frame, which revolutionized textile manufacturing and contributed to the growth of the factory system during the Industrial Revolution.",
      point: "1",
    },
    {
      question: "Who is the ceremonial head of the Commonwealth?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        " The King",
        " The Speaker of the House of Commons",
        " The Queen",
        " The President of Hosting Country",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The ceremonial head of the Commonwealth is the British monarch, who serves as the symbolic figurehead of the organization. While the position holds little formal authority, it symbolizes the historical ties between member states and the British Crown.",
      point: "1",
    },
    {
      question:
        "Is it True or False? John Constable (1776 - 1837) founded the modern police force in England.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "John Constable (1776 - 1837) was a renowned English landscape painter, not a figure associated with the founding of the modern police force in England. The modern police force in England is commonly attributed to Sir Robert Peel, who established the Metropolitan Police Service in London in 1829",
      point: "1",
    },
    {
      question:
        "Is it True or False? Sir Robert Peel, founded the modern police force in England. ",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "The modern police force in England is commonly attributed to Sir Robert Peel, who established the Metropolitan Police Service in London in 1829",
      point: "1",
    },
    {
      question:
        "TRUE or FALSE? King Henry VIII established the Church of England when the pope declined to grant him a divorce.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [" FALSE", "TRUE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "To divorce his first wife, Henry needed the approval of the Pope. When the Pope refused, Henry established the Church of England.",
      point: "1",
    },
    {
      question: "Who ruled England during the Norman invasion of 1066?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Harold", "Henry VIII", "Herbert", "Hubert"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        " In 1066, an invasion led by William, the Duke of Normandy (in what is now northern France), defeated Harold, the Saxon king of England, at the Battle of Hastings. Harold was killed in the battle.",
      point: "1",
    },
    {
      question:
        "Is this statement TRUE or FALSE? During Queen Victoria's reign, the French Empire became the largest Empire the world has ever seen.",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["FALSE", "TRUE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "During the Victorian period, the British Empire grew to cover all of India, Australia and large parts of Africa. It became the largest empire the world has ever seen, with an estimated population of more than 400 million people.",
      point: "1",
    },
    {
      question: "Which stories are attributed to Geoffrey Chaucer?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: [
        "The Ambridge Tales",
        "The Canterbury Tales",
        "The London Tales",
        "The Westbury Tales",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation:
        "In the years leading up to 1400, Geoffrey Chaucer wrote a series of poems in English about a group of people going to Canterbury on a pilgrimage. The people decided to tell each other stories on the journey, and the poems describe the travellers and some of the stories they told. This collection of poems is called The Canterbury Tales.",
      point: "1",
    },
    {
      question: "What is the capital of France?",
      questionType: "text",
      questionPic: "",
      answerSelectionType: "single",
      answers: ["Paris", "London", "Berlin", "Rome"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Correct answer. Good job.",
      messageForIncorrectAnswer: "Incorrect answer. Please try again.",
      explanation: "Paris is the capital of France.",
      point: "1",
    },
  ],
};
