import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import Lottie from "react-lottie-player";
import lottieJson from "./UKFlag.json";
import UK1 from "./UK1.json";
import UK2 from "./UK2.json";
import UK3 from "./UK3.json";

export default function CustomizedTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          align="right"
          variant="body2"
          color="text.secondary"
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot>
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: 150, height: 150 }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Empower yourself with the key to British citizenship.
          </Typography>
          <Typography>
            Prepare for success with our comprehensive Life in the UK Test
            preparation course
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: "auto 0" }}
          variant="body2"
          color="text.secondary"
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <Lottie
              loop
              animationData={UK1}
              play
              style={{ width: 150, height: 150 }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Don't let uncertainty hold you back.
          </Typography>
          <Typography>
            Take charge of your journey towards British citizenship by mastering
            the Life in the UK Test with our expert guidance.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <Lottie
              loop
              animationData={UK2}
              play
              style={{ width: 150, height: 150 }}
            />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Transform your aspirations into reality.
          </Typography>
          <Typography>
            Build your confidence and knowledge to ace the Life in the UK Test,
            paving the way to your British citizenship.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
          <TimelineDot color="secondary">
            <Lottie
              loop
              animationData={UK3}
              play
              style={{ width: 150, height: 150 }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: "12px", px: 2 }}>
          <Typography variant="h6" component="span">
            Step into your future with assurance.
          </Typography>
          <Typography>
            Equip yourself with the skills and understanding needed to excel in
            the Life in the UK Test and unlock your path to British citizenship.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
