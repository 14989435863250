import React from "react";
import { Box, Container, Grid, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "primary.main",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography color="black" variant="h5">
              Life In The Uk
            </Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="center" spacing={2}>
            <Grid item>
              <IconButton
                color="black"
                aria-label="Facebook"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/LifeInTheUKTestMadeEasy/",
                    "_blank"
                  )
                }
              >
                <FacebookIcon />
              </IconButton>
            </Grid>
            {/* <Grid item>
              <IconButton
                color="black"
                aria-label="Twitter"
                onClick={() =>
                  window.open(
                    "https://twitter.com/your-twitter-account",
                    "_blank"
                  )
                }
              >
                <TwitterIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="black"
                aria-label="LinkedIn"
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/your-linkedin-account",
                    "_blank"
                  )
                }
              >
                <LinkedInIcon />
              </IconButton>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary" variant="subtitle1">
              {`${new Date().getFullYear()} | All Rights Reserved By Aswein Traing`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
