import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home/Home";
import Exam from "../src/Pages/Exams/exam";
import About from "../src/Pages/About/about";
import Contact from "../src/Pages/Contact/contact";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/Test" element={<Exam />} />
        <Route exact path="/About" element={<About />} />
        <Route exact path="/Contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
