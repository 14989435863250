import React from "react";
import Header from "../../Components/Header/Header";
import HeaderImage from "../../Components/Image/HeaderImage";
import Timeline from "../../Components/Timeline/Timeline";
import Footer from "../../Components/Footer/Footer";
const Home = () => {
  return (
    <div>
      <Header />
      <HeaderImage />
      <Timeline />
      <Footer />
    </div>
  );
};

export default Home;
