import React from "react";
import HeaderImage1 from "../../Images/HeaderImage.jpg";
import "./HeaderImage.css";

const HeaderImage = () => {
  return (
    <div className="header-image-container">
      <div className="header-content">
        <img src={HeaderImage1} alt="Header" className="header-image" />
        <div className="header-text">
          "Unlock Your Path to British Citizenship with Confidence! Prepare for
          the Life in the UK Test Today."
          <br />
          <button className="header-button">Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default HeaderImage;
