import React from "react";
import Quiz from "react-quiz-component";
import { quiz } from "./quiz";
import "./exam.css";
import Header from "../../Components/Header/Header";
const Exam = () => {
  return (
    <div>
      <Header />
      <div className="mainDiv">
        <div className="Div1">1</div>
        <Quiz quiz={quiz} shuffle={true} timer={2700} />
        <div className="Div3">3</div>
      </div>
    </div>
  );
};

export default Exam;
